define("a24-ember-staffshift-core/components/conversation-preview-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/conversation-preview-popup"], function (exports, _didRenderChangesMixin, _conversationPreviewPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _conversationPreviewPopup.default,
        bShowPopup: null,
        bPopupLoading: null,
        onPopupCloseAction: null,
        sSubjectId: null,
        sConversationId: null,
        objActivityData: null,
        sLinkedEntityId: null,
        objRestActivityConversation: null,
        objRestChatConversation: null,
        _objConversationPromise: null,

        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objConversationPreviewGeneratorService: Ember.inject.service("conversation-preview-generator"),
        _objSpecialPopupAction: null,
        _arrActions: null,

        _objConversationPreviewCache: null,
        _objConversationPreview: null,
        init: function () {
            this._super(...arguments);

            this.set("_objConversationPreviewCache", {});
        },
        _onActivityDataChange: Ember.observer("objActivityData", function () {
            let objActivityData = this.get("objActivityData");
            this.conversationFromActivity(objActivityData.sSubjectId, objActivityData.sActivityId, objActivityData.sLinkedEntityId, objActivityData.sSubjectType);
        }),
        _onConversationIdChange: Ember.observer("sConversationId", function () {
            this._conversationFromConversationId(this.get("sConversationId"));
        }),
        conversationFromActivity: function (sSubjectId, sActivityId, sLinkedEntityId, sSubjectType) {
            let funcSuccess = objData => {
                this.set("_objConversationPreviewCache." + sActivityId, objData);

                // On success
                this.set("_objConversationPreview", objData);
                // Create an action that will open the conversation preview in a new tab
                this.set("_objSpecialPopupAction", {
                    sTitle: this.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: () => {
                        this.get("onPopupCloseAction")();
                    }
                });

                if (objData.status !== "open") {
                    this.set("_arrActions", [{
                        sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: () => {
                            let _objConversationPreview = this.get("_objConversationPreview");
                            // Generate the conversation html from details
                            let sHtml = this.get("_objConversationPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromConversation(_objConversationPreview);
                            this.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                }

                // Take the popup out of loading
                this.set("bPopupLoading", false);
            };

            this.set("bPopupLoading", true);

            // Use the stored response if it is set
            let objStoredConversationResponse = this.get("_objConversationPreviewCache." + sActivityId);
            if (!a24Core.isEmpty(objStoredConversationResponse)) {
                funcSuccess(objStoredConversationResponse);
                return;
            }

            this.get("objRestActivityConversation").getActivityConversation(this, "_objActivityConversationPreviewPromise", sSubjectId, sSubjectType, sActivityId, sLinkedEntityId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.get("onPopupCloseAction")();
                this.set("bPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStringsService").getString("couldNotLoadConversationEmailPreview"));
            }));
        },

        _conversationFromConversationId: function (sConversationId) {
            let funcSuccess = objData => {
                this.set("_objConversationPreviewCache." + sConversationId, objData);

                // On success
                this.set("_objConversationPreview", objData);
                // Create an action that will open the conversation preview in a new tab
                this.set("_objSpecialPopupAction", {
                    sTitle: this.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: () => {
                        this.get("onPopupCloseAction")();
                    }
                });

                if (objData.status !== "open") {
                    this.set("_arrActions", [{
                        sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: () => {
                            let _objConversationPreview = this.get("_objConversationPreview");
                            // Generate the conversation html from details
                            let sHtml = this.get("_objConversationPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromConversation(_objConversationPreview);
                            this.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                }

                // Take the popup out of loading
                this.set("bPopupLoading", false);
            };

            this.set("bPopupLoading", true);

            // Use the stored response if it is set
            let objStoredConversationResponse = this.get("_objConversationPreviewCache." + sConversationId);
            if (!a24Core.isEmpty(objStoredConversationResponse)) {
                funcSuccess(objStoredConversationResponse);
                return;
            }

            this.get("objRestChatConversation").getConversation(this, "_objConversationPromise", sConversationId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.get("onPopupCloseAction")();
                this.set("bPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStringsService").getString("couldNotLoadConversationEmailPreview"));
            }));
        },
        openHtmlInNewWindow: function (sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        actions: {
            onPopupClose: function () {
                this.get("onPopupCloseAction")();
            }
        }
    });
});
define("a24-ember-lib/components/line-item-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/line-item-component"], function (exports, _didRenderChangesMixin, _lineItemComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _lineItemComponent.default,
        tagName: "li",
        classNameBindings: ["sCustomClassName"],
        sCustomClassName: null,
        sOptionsLabel: null,
        sOptionsLink: null,
        arrActions: null,
        // the tooltip text
        sToolTipText: null,
        // the tooltip position
        sToolTipPosition: "bottom",
        sToolTipClass: "a24-tooltip",
        funcGetTooltipElementCallback: null,
        _objToolTipService: Ember.inject.service("tooltip"),
        init: function () {
            this._super(...arguments);

            Ember.run.schedule("afterRender", () => {
                let objElement = this.$();
                let sToolTipText = this.get("sToolTipText");
                let sToolTipPosition = this.get("sToolTipPosition");
                let sToolTipClass = this.get("sToolTipClass");
                let funcGetTooltipElementCallback = this.get("funcGetTooltipElementCallback");
                this.get("_objToolTipService").addTooltip(objElement, sToolTipText, sToolTipPosition, sToolTipClass, funcGetTooltipElementCallback);

                this.$().on("mousedown", () => {
                    if (!a24Core.isEmpty(this.get("setSelectedItemAction"))) {
                        return this.get("setSelectedItemAction")();
                    }
                });
            });
        },
        observeTooltip: Ember.observer("sToolTipText", function () {
            let objElement = this.$();
            this.get("_objToolTipService").disposeOfTooltip(objElement);

            let sToolTipText = this.get("sToolTipText");
            let sToolTipPosition = this.get("sToolTipPosition");
            let sToolTipClass = this.get("sToolTipClass");
            let funcGetTooltipElementCallback = this.get("funcGetTooltipElementCallback");
            this.get("_objToolTipService").addTooltip(objElement, sToolTipText, sToolTipPosition, sToolTipClass, funcGetTooltipElementCallback);
        }),
        willDestroyElement: function () {
            let objElement = this.$();
            this.get("_objToolTipService").disposeOfTooltip(objElement);
        },
        actions: {
            onActionClick: function () {
                if (!a24Core.isEmpty(this.get("onActionClick"))) {
                    return this.get("onActionClick")();
                }
            },
            onLinkAction: function (objEvent) {
                if (!a24Core.isEmpty(this.get("onLinkAction"))) {
                    return this.get("onLinkAction")(this.get("sOptionsLink"), objEvent);
                }
            }
        }
    });
});
define("a24-ember-staffshift-core/components/conversation-list", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/conversation-list"], function (exports, _didRenderChangesMixin, _conversationList) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _conversationList.default,
        classNames: ["a24-conversation-list"],
        bIsArchived: false,
        objChatConversationRestService: null,
        sConversationUserId: null,
        _bLoading: false,
        _iPage: 1,
        _iPerPage: 3,
        _iResultCount: 0,
        _objQueryParams: null,
        _objConversationPreview: null,
        _objRestGetConversationProperty: null,
        _arrDisplayConversations: null,
        _objRestGetConversationsProperty: null,
        _arrConversationPreviewPopupActions: null,
        _objSpecialConversationPreviewPopupAction: null,
        _bShowConversationPreviewPopup: false,
        _bConversationPreviewPopupLoading: false,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objConversationPreviewGeneratorService: Ember.inject.service("conversation-preview-generator"),

        init: function () {
            this._super(...arguments);

            this.set("_objSpecialConversationPreviewPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this.set("_bShowConversationPreviewPopup", false);
                }
            });

            this.set("_arrConversationPreviewPopupActions", [{
                sTitle: this.get("_objSSCoreStrings").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: () => {
                    let objConversationPreview = this.get("_objConversationPreview");
                    // Generate the Conversation html
                    let sHtml = this.get("_objConversationPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromConversation(objConversationPreview);
                    this._openHtmlInNewWindow(sHtml);
                }
            }]);

            this._getConversations();
        },

        _getConversations: function (objQueryParams) {
            // Put the card in loading
            this.set("_bLoading", true);

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            if (a24Core.isEmpty(objQueryParams.created_at)) {
                objQueryParams.created_at = {
                    mValue: "desc",
                    bSortBy: true
                };
            }

            objQueryParams.status = {
                mValue: this.get("bIsArchived") ? "closed" : "open"
            };

            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            // Get Conversations
            this.get("objChatConversationRestService").getConversations(this, "_objRestGetConversationsProperty", this.get("sConversationUserId"), (arrData, sStatus, objXHR) => {
                if (!a24Core.isEmpty(arrData)) {
                    this.set("_arrDisplayConversations", this._prepareForDisplay(arrData));
                } else {
                    this.set("_arrDisplayConversations", []);
                }
                this.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));

                // Put the card out of loading
                this.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    if (!a24Core.isEmpty(this.get("onServerError"))) {
                        this.get("onServerError")(sCode);
                    }
                } else {
                    // Clear data
                    this.set("_arrDisplayConversations", []);
                }

                // Put the card out of loading
                this.set("_bLoading", false);
            }), objQueryParams);
        },

        _goToConversation: function (objConversation) {
            if (!a24Core.isEmpty(this.get("onGoToConversation"))) {
                this.get("onGoToConversation")(objConversation);
            }
        },

        _openConversationPreview: function (objConversation) {
            let funcSuccess = objData => {
                Ember.set(objConversation, "objStoredResponse", objData);

                this.set("_objConversationPreview", objData);

                // Take the popup out of loading
                this.set("_bConversationPreviewPopupLoading", false);
            };

            this.set("_bShowConversationPreviewPopup", true);
            this.set("_bConversationPreviewPopupLoading", true);

            // Use the stored response if it is set
            let objStoredResponse = Ember.get(objConversation, "objStoredResponse");
            if (!a24Core.isEmpty(objStoredResponse)) {
                funcSuccess(objStoredResponse);
                return;
            }

            this.get("objChatConversationRestService").getConversation(this, "_objRestGetConversationProperty", this.get("sConversationUserId"), objConversation._id, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.set("_bShowConversationPreviewPopup", false);
                this.set("_bConversationPreviewPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStrings").getString("failedToLoadConversationPreview"));
            }));
        },

        _openHtmlInNewWindow: function (sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },

        _prepareForDisplay: function (arrConversations) {
            let funcGotoConversation = objConversation => {
                return () => {
                    this._goToConversation(objConversation);
                };
            };

            let funcOpenConversationPreview = objConversation => {
                return () => {
                    this._openConversationPreview(objConversation);
                };
            };

            return arrConversations.map(objConversation => {
                let sTime = momentHelper.getTimeFromNow(objConversation.created_at, a24Constants.DATE_FORMAT_REST_FORMAT);

                let arrActions = [];

                if (!this.get("bIsArchived") && this.get("objChatConversationRestService.sContext") === objConversation.owner.resource_type) {
                    arrActions.push({
                        sTitle: this.get("_objSSCoreStrings").getString("goToConversation"),
                        sIcon: "view_list",
                        executeCardAction: funcGotoConversation(objConversation)
                    });
                }

                if (this.get("bIsArchived")) {
                    arrActions.push({
                        sTitle: this.get("_objSSCoreStrings").getString("openConversationPreview"),
                        sIcon: "open_in_browser",
                        executeCardAction: funcOpenConversationPreview(objConversation)
                    });
                }

                return {
                    sTime: sTime,
                    sMessage: objConversation.first_message,
                    arrActions: arrActions,
                    objData: objConversation
                };
            });
        },

        actions: {
            onClickConversation: function (objConversation) {
                if (!a24Core.isEmpty(objConversation.arrActions[0])) {
                    objConversation.arrActions[0].executeCardAction(objConversation.objData);
                }
            },

            cardDatagridPagerEvent: function (objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                let objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this._getConversations(objQueryParams);
            },

            onConversationPreviewPopupClose: function () {
                this.set("_bShowConversationPreviewPopup", false);
            }
        }
    });
});
define("a24-ember-staffshift-core/services/card-datagrid-custom-filter-ward", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _arrRegistrationBodies: null,

        funcFilterCustomPrimaryQuery: function (objHeaderItem /*, objHeaderSecondaries */) {
            var arrItems = Ember.get(objHeaderItem, "_arrFilterWard");
            var arrIds = [];
            var arrNames = [];
            if (!a24Core.isEmpty(arrItems)) {
                for (var j = 0; j < arrItems.length; j++) {
                    arrIds.push(arrItems[j].ward_id);
                    arrNames.push(arrItems[j].ward_name);
                }
            }
            var sQuery = arrIds.join("|");
            var sFilterValue = arrNames.join(", ");
            if (a24Core.isEmpty(sQuery.trim())) {
                sQuery = null;
            }
            var objQuery = {
                bEncode: true,
                bDelimited: true,
                sFilterValue: Ember.get(objHeaderItem, "_objFilterSite.sSiteName") + ": " + sFilterValue,
                mValue: sQuery,
                arSec: [{
                    sProp: "site_id",
                    bEncode: true,
                    mValue: Ember.get(objHeaderItem, "_objFilterSite.sSiteId"),
                    sFilterValue: Ember.get(objHeaderItem, "_objFilterSite.sSiteName"),
                    sOrg: Ember.get(objHeaderItem, "_objFilterSite.sOrganisationId"),
                    sOrgName: Ember.get(objHeaderItem, "_objFilterSite.sOrganisationName")
                }]
            };

            return objQuery;
        },

        funcFilterCustomTag: function (objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            let sIs = this.get("_objStringsService").getString("is");
            if (bSkipTitle) {
                return {
                    sTag: sIs + " " + objQuery.sFilterValue
                };
            } else {
                return {
                    sTag: Ember.get(objHeaderItem, "sTitle") + " " + sIs.toLowerCase() + " " + objQuery.sFilterValue
                };
            }
        },

        funcFilterCustomPrimaryReset: function (objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_objFilterSite", null);
            Ember.set(objHeaderItem, "_arrFilterWard", null);
            Ember.set(objHeaderItem, "_objFilterWardsSelected", null);
            Ember.set(objHeaderItem, "_objFilterWardsDropdown", []);
        },

        funcFilterCustomFixupQuery: function () /*objHeader, objHeaderSecondaries */{
            // N/A
        },

        funcFilterCustomSetEditState: function (objHeaderItem, iIndex) {
            var objTag = Ember.get(objHeaderItem, "mFilterValue." + iIndex);

            var arrItems = objTag.mValue.split("|");
            var arrWards = [];
            for (var j = 0; j < arrItems.length; j++) {
                arrWards.push({
                    ward_id: arrItems[j]
                });
            }

            Ember.set(objHeaderItem, "_arrFilterWard", arrWards);
            Ember.set(objHeaderItem, "_objFilterSite", {
                sSiteId: objTag.arSec[0].mValue,
                sSiteName: objTag.arSec[0].sFilterValue,
                sDisplayName: objTag.arSec[0].sOrgName + ": " + objTag.arSec[0].sFilterValue,
                sOrganisationId: objTag.arSec[0].sOrg,
                sOrganisationName: objTag.arSec[0].sOrgName
            });
        }
    });
});
define("a24-ember-lib/components/icon-label-value-row-component", ["exports", "a24-ember-lib/templates/components/icon-label-value-row-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _iconLabelValueRowComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _iconLabelValueRowComponent.default,
        tagName: "tr",
        bEmail: false,
        bPhone: false,
        bLink: false,
        bExternalLink: false,
        bAddress: false,
        sIcon: null,
        sIconFa: null,
        sIconSvg: null,
        sLetter: null,
        sImageUrl: null,
        sValue: null,
        sLabel: null,
        sRightLabel: null,
        sIconPadding: "default-icon-padding",
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function () {
            this._super(...arguments);

            var sHref = "";
            if (this.get("bEmail")) {
                sHref = "email";
            } else if (this.get("bPhone")) {
                sHref = "phone";
            } else if (this.get("bLink")) {
                sHref = "link";
            } else if (this.get("bExternalLink")) {
                sHref = "externalLink";
            } else if (this.get("bAddress")) {
                sHref = "address";
            }

            var sValue = a24Core.getSafeStringLink(Ember, this.get("sValue"), this.get("sValue"), sHref);
            this.set("sValue", sValue);
        }
    });
});
define("a24-ember-staffshift-core/services/rest-system", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        arrPositionTypes: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("objEnv.sRestApiBaseUrl"),

        objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Route for getting position types
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  09 April 2019
         */
        getPositionTypes: function (objCaller, sProperty, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/position-types");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of job families
         *
         * @param objCaller - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sPositionType - The position type
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  11 April 2018
         */
        getJobFamilies: function (objCaller, sProperty, sPositionType, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/job-family");

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                position_types: {
                    mValue: [sPositionType]
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Returns a list of job titles
         *
         * @param objCaller - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sPositionType - The position type
         * @param sJobFamilyId - The job family id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  11 April 2018
         */
        getJobTitles: function (objCaller, sProperty, sPositionType, sJobFamilyId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/job-title");

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                job_family_id: {
                    mValue: sJobFamilyId
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                position_types: {
                    mValue: [sPositionType]
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system skills
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  02 May 2019
         */
        getSkills: function (objCaller, sProperty, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/skills");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system qualifications
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  15 May 2019
         */
        getQualifications: function (objCaller, sProperty, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/qualifications");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system registration-bodies
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  6 June 2019
         */
        getRegistrationBody: function (objCaller, sProperty, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system registration body entries
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registration body id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  13 June 2019
         */
        getRegistrationBodyEntries: function (objCaller, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system registration body statuses
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registration body id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  13 June 2019
         */
        getRegistrationBodyEntryStatuses: function (objCaller, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/entry-status");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for getting system registration body statuses
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sRegistrationBodyId - The registration body id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  13 June 2019
         */
        getRegistrationBodyStatuses: function (objCaller, sProperty, sRegistrationBodyId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/system/registration-body/" + sRegistrationBodyId + "/status");
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});
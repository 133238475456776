define("a24-ember-staffshift-core/services/candidate-type-tabs", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * Will check if there is more than one unique country code
         * then it will append the country code to the name.
         *
         * @param array arrTabNames - The Tabs Names
         *
         * @author Langton Mudyiwa <langton.mudyiwa@a24group.com>
         * @since  06 June 2019
         *
         * @return array of names changed
         */
        generateCandidatesTypes: function (arrTabNames) {

            var arrTabNamesChanged = Ember.copy(arrTabNames, true);
            var bFound = false;
            if (!a24Core.isEmpty(arrTabNamesChanged)) {
                var sCountryCode = arrTabNamesChanged[0].country_code;
                for (var i = 1; i < arrTabNamesChanged.length; i++) {
                    if (sCountryCode !== arrTabNamesChanged[i].country_code) {
                        bFound = true;
                        break;
                    }
                }

                if (bFound) {
                    for (var l = 0; l < arrTabNamesChanged.length; l++) {
                        arrTabNamesChanged[l].name = arrTabNamesChanged[l].name + " (" + arrTabNamesChanged[l].country_code.toUpperCase() + ")";
                    }
                }
            }

            arrTabNamesChanged.sort(function (objA, objB) {
                if (objA.name.toLowerCase() < objB.name.toLowerCase()) {
                    return -1;
                }
                if (objA.name.toLowerCase() > objB.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            return arrTabNamesChanged;
        },
        /**
         * Will remove duplicates from the array of candidate types, then build an
         * array of tabs, sorted by name, using the candidate type and position type data
         *
         * @param array arrCandidateTypes - The candidate types
         * @param array arrPositionTypes - The position types
         *
         * @author Langton Mudyiwa <langton.mudyiwa@a24group.com>
         * @since  06 June 2019
         *
         * @return array of tabs
         */
        generateCandidateTypesTabs: function (arrCandidateTypes, arrPositionTypes) {
            var arrTabNames = [];
            var arrTabs = [];
            var objTabName = null;

            if (a24Core.isEmpty(arrCandidateTypes)) {
                return arrTabs;
            }

            var arrDistinctCandidateTypes = arrCandidateTypes.filter(function (sValue, sIndex, sSelf) {
                return sSelf.indexOf(sValue) === sIndex;
            });

            // Build up the string name for the tab
            for (var i = 0; i < arrDistinctCandidateTypes.length; i++) {
                for (var j = 0; j < arrPositionTypes.length; j++) {
                    objTabName = null;
                    if (arrDistinctCandidateTypes[i] === arrPositionTypes[j].code) {
                        objTabName = {
                            name: arrPositionTypes[j].name,
                            code: arrPositionTypes[j].code,
                            country_code: arrPositionTypes[j].country_code
                        };
                        break;
                    }
                }
                if (!a24Core.isEmpty(objTabName)) {
                    arrTabNames.push(objTabName);
                }
            }

            var arrTabNamesChanged = this.generateCandidatesTypes(arrTabNames);

            // Build up the tab object for the tabs
            for (var l = 0; l < arrTabNamesChanged.length; l++) {
                arrTabs.push({
                    iIndex: l,
                    bActive: l === 0 ? true : false,
                    sText: arrTabNamesChanged[l].name,
                    sCode: arrTabNamesChanged[l].code
                });
            }

            return arrTabs;
        }

    });
});
define("a24-ember-staffshift-core/components/conversation-preview", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/conversation-preview"], function (exports, _didRenderChangesMixin, _conversationPreview) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _conversationPreview.default,
        classNames: ["conversation-preview"],
        objConversation: null,

        _sPreviewStyleString: null,
        _sPreviewString: null,
        _objConversationPreviewGeneratorService: Ember.inject.service("conversation-preview-generator"),

        init: function () {
            this._super(...arguments);

            this.set("_sPreviewStyleString", this.get("_objConversationPreviewGeneratorService").generateFullHtmlForStyleSheet());
        },

        didReceiveAttrs: function () {
            this._super(...arguments);
            // Re-generate the value when it changes
            if (!a24Core.isEmpty(this.get("objConversation"))) {
                this.set("_sPreviewString", this.get("_objConversationPreviewGeneratorService").generateFullHtmlFromConversation(this.get("objConversation")));
            }
        }
    });
});
define("a24-ember-lib/components/timeline-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/timeline-component"], function (exports, _didRenderChangesMixin, _timelineComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _timelineComponent.default,
        classNames: ["timeline-component"],
        sHeader: "",
        sEndText: "",
        sNoDataText: "",
        bHasMore: false,
        bDisplayLoadMore: true,
        bMoreLoading: false,
        arrData: null,

        _bHasNoData: Ember.computed.empty("arrData").readOnly(),

        _objStringsEmberLibService: Ember.inject.service("a24-strings-ember-lib"),

        actions: {
            onSeeMore: function () {
                if (!a24Core.isEmpty(this.get("onSeeMore"))) {
                    this.get("onSeeMore")();
                }
            }
        }
    });
});
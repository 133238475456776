define("a24-ember-staffshift-core/components/right-panel-client-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/right-panel-client-view"], function (exports, _didRenderChangesMixin, _rightPanelClientView) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _rightPanelClientView.default,
        classNames: ["right-panel-client-view"],
        mData: null, //This is send in through the objMenuService.mRightPanelComponentData service
        _sIFrameUrl: "",
        _objReactIframeData: null,
        _objReactPopupPostData: null,
        _objEmailActivity: null,
        _objConversationActivity: null,
        _objNotificationActivity: null,

        _bReactPopupOpen: false,
        _bReactPopupLoading: true,

        _bShowEmailMessagePopup: false,
        _bShowNotifcationsSentPopup: false,

        _objRestActivityEmailComm: Ember.inject.service("rest-agency-activity-email-communication-message"),
        _objRestActivityNotification: Ember.inject.service("rest-agency-activity-notification"),
        init: function () {
            this._super(...arguments);
            var objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);
            }

            this.observeData();
        },
        observeData: Ember.observer("mData", function () {
            let objData = {
                orgId: this.get("mData.sOrgId")
            };
            if (!a24Core.isEmpty(this.get("mData.sSiteId"))) {
                objData.siteId = this.get("mData.sSiteId");
            }
            if (!a24Core.isEmpty(this.get("mData.sWardId"))) {
                objData.wardId = this.get("mData.sWardId");
            }
            this.set("_objReactIframeData", {
                type: "trigger_route",
                data: {
                    route: "agency/client/clientInfoPanel",
                    data: objData
                }
            });
        }),
        actions: {
            onRecieveData: function (objData) {
                if (objData.type === "trigger_route") {
                    this.set("_objReactPopupPostData", objData);
                    this.set("_bReactPopupOpen", true);
                } else if (objData.type === "open_preview_popup") {
                    let objEventData = objData.data.data;
                    if (objEventData.entity_type === "EmailCommunicationMessage") {
                        this.set("_objEmailActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowEmailMessagePopup", true);
                    } else if (objEventData.entity_type === "ChatConversation") {
                        this.set("_bReactPopupOpen", true);
                        this.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/conversation/previewPopup",
                                data: { conversationId: objEventData.entity_id }
                            }
                        });
                    } else if (objEventData.entity_type === "CandidateNotifications") {
                        this.set("_objNotificationActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowNotifcationsSentPopup", true);
                    }
                }
            },
            onRecievePopupData: function (objData) {
                if (objData.type === "app_ready" || objData.type === "router_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                } else if (objData.type === "popup_response") {
                    this.set("_objReactIframeData", objData);
                }
            },
            onEmailMessagePopupClose: function () {
                this.set("_bShowEmailMessagePopup", false);
            },
            onNotificationSentPopupClose: function () {
                this.set("_bShowNotifcationsSentPopup", false);
            }
        }
    });
});
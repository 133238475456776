define("a24-ember-lib/components/card-datagrid-pager", ["exports", "a24-ember-lib/templates/components/card-datagrid-pager", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridPager, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridPager.default,
        iResultCount: 0, // The total result count
        iPage: 1, // The page
        iPerPage: 25, // The amount of items for each page
        iResultCountLimit: null,
        iResultsForCurrentPage: 0,
        sOfSeparator: "", // The "of" separator in "x - y of z"
        sToSeparator: "", // The "-" separator in "x - y of z"
        bPagerLoading: false,
        bResultCountReturned: false,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _iPageMin: 0,
        _iPageMax: 0,
        _bIsFirstPage: false,
        _bIsLastPage: false,
        _bPagerClickable: false,
        _bPagerClicked: false,
        _iFromPageDisplay: 0,
        _iToPageDisplay: 0,
        _sResultCount: "",
        _bPreviousPage: false,
        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("sOfSeparator"))) {
                this.set("sOfSeparator", this.get("_objStringsService").getString("of").toLowerCase());
            }
            if (a24Core.isEmpty(this.get("sToSeparator"))) {
                this.set("sToSeparator", this.get("_objStringsService").getString("to").toLowerCase());
            }

            this.calculatePageLimits();

            // Notify parent of the new page
            var objPageQuery = {
                page: {
                    mValue: this.get("iPage")
                },
                per_page: {
                    mValue: this.get("iPerPage")
                }
            };
            if (!a24Core.isEmpty(this.get("cardDatagridPagerInitEvent"))) {
                this.get("cardDatagridPagerInitEvent")(objPageQuery);
            }
        },
        calculatePageLimits: Ember.observer("iResultCount", "iPage", "bResultCountReturned", function () {
            var iPageMin = 1;
            var iPageMax = 1;

            var iResultCount = this.get("iResultCount");
            var iResultsForCurrentPage = this.get("iResultsForCurrentPage");
            var iPage = this.get("iPage");

            if (iResultCount > 0) {
                // If there are more than 0 results
                var iPerPage = this.get("iPerPage");
                var iResultsOnLastPage = iResultCount % iPerPage;
                var iPages = (iResultCount - iResultsOnLastPage) / iPerPage;

                // This means we only have one page
                if (iResultsOnLastPage !== 0) {
                    iPages++; // Add single page to the iPages count
                }

                iPageMin = 1;
                iPageMax = iPages;

                if (iPage > 1 && iResultsForCurrentPage == 0) {
                    // gone page last page
                    iResultCount = (iPage - 1) * iPerPage + 1;
                }
            }

            if (!a24Core.isEmpty(this.get("iResultCountLimit")) && this.get("iResultCountLimit") === iResultCount && !this.get("bResultCountReturned") // check if the actual result count was returned,
            // this is used in case the actual result count = result count limit
            ) {
                    this.set("_sResultCount", this.get("_objEmberLibString").getString("many"));
                    this.set("_bPagerClickable", true);
                } else {
                this.set("_sResultCount", iResultCount);
                this.set("_bPagerClickable", false);
            }
            // Resets the clicked flag since the result count was changed
            this.set("_bPagerClicked", false);

            this.set("_iPageMin", iPageMin);
            this.set("_iPageMax", iPageMax);

            // Update the page display
            this.updatePage(iPage, false);
        }),
        recalculateRange: Ember.observer("iResultsForCurrentPage", "bResultCountReturned", function () {
            var iResultCount = this.get("iResultCount");
            var iResultsForCurrentPage = this.get("iResultsForCurrentPage");
            if (!a24Core.isEmpty(this.get("iResultCountLimit")) && iResultsForCurrentPage >= 0 && iResultCount >= this.get("iResultCountLimit")) {
                var iPage = this.get("iPage");
                var iPerPage = this.get("iPerPage");
                var iFromResult = (iPage - 1) * iPerPage + 1;
                var iToResult = iFromResult + iResultsForCurrentPage - 1;

                if (iResultsForCurrentPage > 0 && iToResult >= iResultCount) {
                    this.set("_iToPageDisplay", iToResult);
                }

                if (iResultsForCurrentPage === 0 || iResultsForCurrentPage < iPerPage) {

                    if (iResultsForCurrentPage === 0) {
                        this.set("_sResultCount", iFromResult);
                        this.set("_iToPageDisplay", iFromResult);
                    } else {

                        this.set("_sResultCount", iToResult);
                    }
                    this.set("_bPagerClickable", false);
                    this.set("_bIsLastPage", true);
                } else {
                    if (this.get("iResultCountLimit") === iResultCount && !this.get("bResultCountReturned")) {
                        this.set("_sResultCount", this.get("_objEmberLibString").getString("many"));
                        this.set("_bPagerClickable", true);
                    }
                    this.set("_bIsLastPage", false);
                }
            }
        }),
        updatePage: function (iPage, bFireEvent) {
            var iPageMin = this.get("_iPageMin");
            var iPageMax = this.get("_iPageMax");
            var iPerPage = this.get("iPerPage");
            var iFromResult = (iPage - 1) * iPerPage + 1;
            var iToResult = iFromResult + iPerPage - 1;
            var iResultCount = this.get("iResultCount");

            // Calculate whether the page is first and store
            this.set("_bIsFirstPage", iPage === iPageMin);
            // Calculate whether the page is last and store
            if (a24Core.isEmpty(this.get("iResultCountLimit")) || !a24Core.isEmpty(this.get("iResultCountLimit")) && iResultCount > 0 && iToResult <= this.get("iResultCountLimit") || iResultCount === 0 // do the last page check if current page is less than the limit
            ) {
                    this.set("_bIsLastPage", iPage === iPageMax);
                }

            // Set the page
            this.set("iPage", iPage);

            // Call function to update the display
            this.updateDisplay(bFireEvent);
        },
        updateDisplay: function (bFireEvent) {

            // Get variables
            var iResultCount = this.get("iResultCount");
            var iPage = this.get("iPage");
            var iPerPage = this.get("iPerPage");
            var iResultsForCurrentPage = this.get("iResultsForCurrentPage");

            // Get starting count
            var iFromResult = (iPage - 1) * iPerPage + 1;

            // Get ending count
            var iToResult = iFromResult + iPerPage - 1;

            // If the result count is 0
            if (iResultCount === 0) {
                iFromResult = iResultCount;
            }

            // If the ending count is larger than the last item or going to next page,
            // when going to a previous page we don't need to rely on the number of
            // results returned to work out the page range
            if (!this.get("_bPreviousPage") && iToResult > iResultCount) {
                this.set("_iToPageDisplay", "");
                if (a24Core.isEmpty(this.get("iResultCountLimit")) || iResultCount !== this.get("iResultCountLimit")) {
                    iToResult = iResultCount; // Limit the ending count
                    this.set("_iToPageDisplay", iToResult);
                } else if (iResultsForCurrentPage === 0) {
                    this.set("_iToPageDisplay", iFromResult);
                }
            } else {
                this.set("_iToPageDisplay", iToResult);
            }

            this.set("_iFromPageDisplay", iFromResult);
            this.set("_bPreviousPage", false);

            // If we want to fire the event
            if (bFireEvent) {
                // Notify parent of the new page
                var objPageQuery = {
                    page: {
                        mValue: iPage
                    },
                    per_page: {
                        mValue: iPerPage
                    }
                };
                if (!a24Core.isEmpty(this.get("cardDatagridPagerEvent"))) {
                    this.get("cardDatagridPagerEvent")(objPageQuery);
                }
            }
        },
        actions: {
            pagerClicked: function () {
                if (this.get("_bPagerClickable")) {
                    this.set("_bPagerClicked", true);
                    if (!a24Core.isEmpty(this.get("cardDatagridPagerManyClicked"))) {
                        this.get("cardDatagridPagerManyClicked")();
                    }
                }
            },
            firstPageClick: function () {
                // This is for the future when we want "back to first page" logic
                //this.updatePage(this.get("_iPageMin"), true);
            },
            previousPageClick: function () {
                this.set("iResultsForCurrentPage", -1);
                this.set("_bPreviousPage", true);
                var iPage = this.get("iPage");
                iPage--;
                this.updatePage(iPage, true);
            },
            nextPageClick: function () {
                this.set("iResultsForCurrentPage", -1);
                this.set("_bPreviousPage", false);
                var iPage = this.get("iPage");
                iPage++;
                this.updatePage(iPage, true);
            },
            lastPageClick: function () {
                // This is for the future when we want "go to last page" logic
                //this.updatePage(this.get("_iPageMax"), true);
            }
        }
    });
});
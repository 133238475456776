define("a24-ember-lib/components/iframe-sourceless", ["exports", "a24-ember-lib/templates/components/iframe-sourceless", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _iframeSourceless, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _iframeSourceless.default,
        classNames: ["iframe-sourceless"],
        sContent: "",
        sStyles: "",
        _sUniqueId: "",
        _objEventListener: null,
        init: function () {
            this._super(...arguments);
            // Generate a new Id 
            this.set("_sUniqueId", Ember.guidFor({}));

            // Perform this after render to allow the iframe to load
            Ember.run.schedule("afterRender", () => {

                // Get the unique id that was generated for the component
                let sUniqueId = this.get("_sUniqueId");

                // Get the iframe reference
                var objIFrame = this.$("iframe");

                // Set the height to an original size to allow other components to
                // be aware of the minimum size it will take up.
                objIFrame.css("height", "250px");
                // Add a message listener to the window
                let objEventListener = window.addEventListener("message", objEvent => {
                    // If the event starts with the same unique identifier as the iframe
                    if (objEvent.data.startsWith(`sourceless-${sUniqueId}`)) {
                        // Split the data and use the height property
                        let iHeight = objEvent.data.split(":")[1];
                        // Set the iframe height
                        objIFrame.css("height", iHeight + "px");
                    }
                }, false);

                this.set("_objEventListener", objEventListener);

                // This will be called on initialisation
                this.onContentOrStyleChange();
            });
        },
        willDestroyElement: function () {
            if (!a24Core.isEmpty(this.get("_objEventListener"))) {
                window.removeEventListener("message", this.get("_objEventListener"), false);
            }
            this._super(...arguments);
        },
        /**
         * This function will get the html body as an encoded data url
         * 
         * This will combine sContent and sStyles as well as a custom js block
         */
        getIFrameDataUrl: function () {
            // Get the content
            let sContent = this.get("sContent");
            if (a24Core.isEmpty(sContent)) {
                sContent = "";
            }
            // Get the styles
            let sStyle = this.get("sStyles");
            if (a24Core.isEmpty(sStyle)) {
                sStyle = "";
            }
            // Get the Unique id for this frame
            let sUniqueId = this.get("_sUniqueId");
            /** 
             * Below Builds up an HTML document with all relvant parts
             */
            var sHtml = `
            <!DOCTYPE html>
            <html>
                <head>
                    <style>
                        ${sStyle}
                    </style>
                </head>
                <body>
                    ${sContent}
                    <script>
                        var funcRecalculate = function() {
                            setTimeout(
                                function() {
                                    var objBody = document.body,
                                        objHtml = document.documentElement;
        
                                    var iHeight = Math.max(
                                        objBody.scrollHeight,
                                        objBody.offsetHeight, 
                                        objHtml.clientHeight,
                                        objHtml.scrollHeight,
                                        objHtml.offsetHeight
                                    );
                                    parent.postMessage("sourceless-${sUniqueId}:" + iHeight, "*");
                                },
                                0
                            );
                        };
                        window.onload = function(){
                        
                            var arrImages = document.getElementsByTagName("img");
                            for (var i = 0; i < arrImages.length; i++) {
                                var objImage = arrImages[i];
                                if (!objImage.complete) {
                                    objImage.addEventListener('load', funcRecalculate);
                                    objImage.addEventListener('error', funcRecalculate);
                                }
                            }
                            var arrLinks = document.getElementsByTagName("a");
                            for (var j = 0; j < arrLinks.length; j++) {
                                var objLink = arrLinks[j];
                                objLink.setAttribute("target", "_blank");
                            }

                            funcRecalculate();
                        }
                    </script>
                </body>
            </html>
        `;
            // Return an encoded data url for the HTML
            return "data:text/html;charset=UTF-8," + encodeURI(sHtml);
        },
        onContentOrStyleChange: Ember.observer("sStyles", "sContent", function () {
            // Get the iframe reference
            var objIFrame = this.$("iframe");
            // Set the source to the encoded html
            objIFrame.attr("src", this.getIFrameDataUrl());
        })
    });
});
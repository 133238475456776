define("a24-ember-form-inputs/services/input-form-tel-input-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        sCountryCode: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sDefaultCountry: "gb",
        _bIsBusy: false,
        _arrCountryCallbacks: null,

        _objAjax: $.ajax,
        _objWhen: $.when,
        _objRun: null,
        _bScriptBusy: false,
        _bScriptLoaded: false,
        _sCss: null,
        _arrSuccessCallbacks: null,
        _arrFailCallbacks: null,

        _sResourceLocation: "/a24-ember-form-inputs/intl-tel-v2",

        loadScript: function (funcSuccessCallback, funcFailCallback) {
            var objThis = this;
            var sResourceBase = objThis.get("_sResourceLocation");
            objThis.setCallbacks(funcSuccessCallback, funcFailCallback);

            if (!this.get("_bScriptBusy") && !this.get("_bScriptLoaded")) {
                this.set("_bScriptBusy", true);
                this.get("_objWhen")(objThis.get("_objAjax")({
                    url: sResourceBase + "/js/utils.js",
                    dataType: "script",
                    cache: true
                }), objThis.get("_objAjax")({
                    url: sResourceBase + "/js/intlTelInput.min.js",
                    dataType: "script",
                    cache: true
                }), objThis.get("_objAjax")({
                    url: sResourceBase + "/css/intlTelInput.min.css",
                    cache: true
                }).done(function (sCss) {
                    $("<style type='text/css'></style>").html(sCss).appendTo("head");
                })).then(function () {
                    objThis.executeSuccessCallbacks();
                    objThis.set("_bScriptBusy", false);
                    objThis.set("_bScriptLoaded", true);
                }, function () {
                    objThis.executeFailCallbacks();
                    objThis.set("_bScriptBusy", false);
                    objThis.set("_bScriptLoaded", false);
                });
            } else if (objThis.get("_bScriptLoaded")) {
                Ember.run.next(() => {
                    objThis.executeSuccessCallbacks();
                });
            }
        },
        setCallbacks: function (funcSuccessCallback, funcFailCallback) {
            var arrSuccessCallbacks = [];
            if (!a24Core.isEmpty(this.get("_arrSuccessCallbacks"))) {
                arrSuccessCallbacks = this.get("_arrSuccessCallbacks");
            }
            arrSuccessCallbacks.push(funcSuccessCallback);

            var arrFailCallbacks = [];
            if (!a24Core.isEmpty(this.get("_arrFailCallbacks"))) {
                arrFailCallbacks = this.get("_arrFailCallbacks");
            }
            arrFailCallbacks.push(funcFailCallback);

            this.set("_arrSuccessCallbacks", arrSuccessCallbacks);
            this.set("_arrFailCallbacks", arrFailCallbacks);
        },
        executeSuccessCallbacks: function () {
            var arrSuccessCallbacks = this.get("_arrSuccessCallbacks");
            for (var i = 0; i < arrSuccessCallbacks.length; i++) {
                arrSuccessCallbacks[i]();
            }
            this.set("_arrSuccessCallbacks", []);
            this.set("_arrFailCallbacks", []);
        },
        executeFailCallbacks: function () {
            var arrFailCallbacks = this.get("_arrFailCallbacks");
            for (var i = 0; i < arrFailCallbacks.length; i++) {
                arrFailCallbacks[i]();
            }
            this.set("_arrFailCallbacks", []);
            this.set("_arrSuccessCallbacks", []);
        },
        getCountryCodeFromIp: function (funcCallback) {
            var objThis = this;
            this.setCountryCallbacks(funcCallback);
            if (a24Core.isEmpty(this.get("sCountryCode"))) {
                if (!this.get("_bIsBusy")) {
                    this.set("_bIsBusy", true);
                    objThis.get("_objAjax")({
                        url: "https://json.geoiplookup.io/",
                        dataType: "jsonp"
                    }).always(function (objResponse) {
                        if (!a24Core.isEmpty(objThis)) {
                            Ember.run(() => {
                                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.country_code)) {
                                    objThis.set("sCountryCode", objResponse.country_code);
                                } else {
                                    //Could not determine country so just default to gb
                                    objThis.set("sCountryCode", objThis.get("_sDefaultCountry"));
                                }
                                objThis.set("_bIsBusy", false);
                                objThis.executeCountryCodeCallbacks();
                            });
                        }
                    });
                }
            } else {
                this.executeCountryCodeCallbacks();
            }
        },
        setCountryCallbacks: function (funcCallback) {
            var arrCallbacks = [];
            if (!a24Core.isEmpty(this.get("_arrCountryCallbacks"))) {
                arrCallbacks = this.get("_arrCountryCallbacks");
            }
            arrCallbacks.push(funcCallback);

            this.set("_arrCountryCallbacks", arrCallbacks);
        },
        executeCountryCodeCallbacks: function () {
            var arrCallbacks = this.get("_arrCountryCallbacks");
            for (var i = 0; i < arrCallbacks.length; i++) {
                arrCallbacks[i](this.get("sCountryCode"));
            }
            this.set("_arrCountryCallbacks", []);
        }
    });
});
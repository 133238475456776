define("a24-ember-lib/components/input-form-dropdown", ["exports", "a24-ember-lib/templates/components/input-form-dropdown", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-window/mixins/a24responsive"], function (exports, _inputFormDropdown, _inputFormElement, _didRenderChangesMixin, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _a24responsive.default, _didRenderChangesMixin.default, {
        layout: _inputFormDropdown.default,
        classNames: ["input-form-dropdown", "md-form", "a24ErrorInputParent"],
        classNameBindings: ["bLoading:loading"],
        bLoading: false,
        bDisabled: false,
        sOptionsValueKey: "sValue",
        sOptionsLabelKey: "sLabel",
        sOptionsPrompt: null,
        bMultiSelect: false,
        arrOptions: null,
        objValue: null,
        arrValues: null,
        sInputType: "",
        _bInitializing: false,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objConstantsWindowService: Ember.inject.service("a24-constants-window"),
        _objDomService: Ember.inject.service("dom-event"),
        _objDivInstance: null,
        _objCloseLater: null,
        init: function () {
            this._super(...arguments);

            if (this.get("bMultiSelect")) {
                this.set("sInputType", "dropdown-multi");
            } else {
                this.set("sInputType", "dropdown-single");
            }

            this.set("_bInitializing", true);
            this.get("_objDomService");

            // Create a new body level div
            this.set("_objDivInstance", $("<div class='displayBlock select-wrapper mdb-select dropdown-blocker'></div>"));
            $("body").append(this.get("_objDivInstance"));

            // We are blocking scrolling on the blocker div once its in view. This will help when the dropdown shows
            // that the whole page does not scroll that will place the dropdown on the wrong position.
            this.get("_objDivInstance").on("scroll wheel DOMMouseScroll mousewheel touchmove", function (objEvent) {
                objEvent.stopPropagation();
            });

            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("sOptionsPrompt"))) {
                this.set("sOptionsPrompt", this.get("_objStringsService").getString("selectOption"));
            }

            // If array options is any empty type
            if (a24Core.isEmpty(this.get("arrOptions"))) {
                // Default to an empty list
                this.set("arrOptions", []);
            }

            // Schedule for after render
            Ember.run.scheduleOnce("afterRender", () => {

                // Set values on dropdown
                this.setValuesOnDropdownInput();

                // Get the dropdown item
                var objDropdown = $("#" + this.get("_sInputId"));
                // Init the dropdown as a material select
                this.createAndAttachListeners(objDropdown);

                // Add a change listener on this dropdown
                objDropdown.change(() => {
                    // Get values on dropdown
                    this.getValuesOnDropdownInput(objDropdown);
                });

                this.onHandleEscEvent();

                if (this.get("bDisabled")) {
                    this._toggleDropdownInputState();
                }

                this.set("_bInitializing", false);
            });
        },
        /**
         * This function will handle the esc event for dropdown as we removed the default handling of esc from the
         * material select
         */
        onHandleEscEvent: function () {
            $(this.$()).off("keyup");
            this.$().off("keyup");

            $(this.$()).on("keyup", objEvent => {
                if (objEvent.which === 27) {
                    this.closePopup(objEvent);
                }
            });
            this.$().on("keyup", objEvent => {
                if (objEvent.which === 27) {
                    this.closePopup(objEvent);
                }
            });
        },
        /**
         * This function, when called, will set the mValue the selected item or items
         *
         * @param objDropdown - The dropdown from which to retrieve the data
         */
        getValuesOnDropdownInput: function (objDropdown) {

            if (this.get("bMultiSelect")) {
                // Set mValue to array of objects
                this.setMValueWithInternalFlag(this.getSelectedObjects(objDropdown), this.get("_bInitializing"));
            } else {
                // Set mValue to a single objects
                this.setMValueWithInternalFlag(this.getSelectedObject(objDropdown), this.get("_bInitializing"));
            }
        },
        /**
         * Get single selected object
         *
         * @param objDropdown - The dropdown from which to retrieve the data
         */
        getSelectedObject: function (objDropdown) {
            // Get the value from the dropdown
            var sValue = objDropdown.val();
            // Set the value to null if it is empty
            if (a24Core.isEmpty(sValue)) {
                sValue = null;
            }

            // Set a default selected item
            var objSelectedItem = null;

            // Get the options
            var arrOptions = this.get("arrOptions");
            // Loop over the selected items
            for (let i = 0; i < arrOptions.length; i++) {
                // If the sValue matches the sOptionValueKey on the current option
                if (sValue === arrOptions[i][this.get("sOptionsValueKey")]) {
                    // Set the selected item as the current item
                    objSelectedItem = arrOptions[i];
                    break;
                }
            }
            // return the selected object
            return objSelectedItem;
        },
        sizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            let sSize = this.get("sScreenSize");
            if (sSize !== this.get("_objConstantsWindowService").MEDIA_SIZE_TINY && sSize !== this.get("_objConstantsWindowService").MEDIA_SIZE_MOBILE) {
                Ember.run.scheduleOnce("afterRender", this, this.closePopup);
            }
        }),
        closePopup: function (objEvent) {
            if (this.get("_objDivInstance").hasClass("dropdown-open")) {
                if (!a24Core.isEmpty(objEvent)) {
                    objEvent.stopPropagation();
                }
                //Do not use "close" since that assumes the input has already been blured.
                //Triggering "close" will make that you cant refocus the input after we manually closed it, thus
                //dropdown wont appear
                //Bluring the input will trigger the "close"
                //$(this.$("input.select-dropdown")).trigger("close");
                $(this.$("input.select-dropdown")).trigger("blur");
                if (this.get("bMultiSelect")) {
                    // Will have to trigger the close on multi select since the blur does not triggering the close on mdb
                    // if the dropdown is a multiselect
                    $(this.$("input.select-dropdown")).trigger("close");
                }
            }
        },
        /**
         * Get multiple selected objects
         *
         * @param objDropdown - The dropdown from which to retrieve the data
         */
        getSelectedObjects: function (objDropdown) {
            // Get the values from the dropdown
            var arrValues = objDropdown.val();
            // Set the value to empty array if it is empty
            if (a24Core.isEmpty(arrValues)) {
                arrValues = [];
            }

            // Set a default selected items
            var arrSelectedItems = [];

            // Get the options
            var arrOptions = this.get("arrOptions");
            // Loop over the selected items
            for (let i = 0; i < arrOptions.length; i++) {
                // Loop over the array of selected values
                for (var j = 0; j < arrValues.length; j++) {
                    // If the value item matches the sOptionValueKey on the current option
                    if (arrValues[j] === arrOptions[i][this.get("sOptionsValueKey")]) {
                        // Add the selected item as a current item
                        arrSelectedItems.push(arrOptions[i]);
                    }
                }
            }

            if (arrSelectedItems.length === 0) {
                arrSelectedItems = null;
            }

            // return the selected arrays
            return arrSelectedItems;
        },
        /**
         * This function, when called, will set dropdown values equal to the objValues or arrValues
         */
        setValuesOnDropdownInput: function () {
            // Get the dropdown
            var objDropdown = $("#" + this.get("_sInputId"));

            // Set the default bSetValue flag
            var bSetValues = true;

            if (this.get("bMultiSelect")) {
                // Set a match boolean to true by default
                var bMatch = true;
                // Get the list of dropdowns
                var arrDropdown = objDropdown.val();

                // Get the current list of stored items
                var arrChange = this.get("arrValues");

                // Set empty to arrDropdown if it is not set
                if (a24Core.isEmpty(arrDropdown)) {
                    arrDropdown = [];
                }
                // Set empty to arrChange if it is not set
                if (a24Core.isEmpty(arrChange)) {
                    arrChange = [];
                }
                // If the lengths are the same
                if (arrDropdown.length === arrChange.length) {
                    for (var i = 0; i < arrDropdown.length; i++) {
                        // Check if each item matches
                        if (arrDropdown[i] !== arrChange[i][this.get("sOptionsValueKey")]) {
                            // As soon as an item does not match, flag it and break
                            bMatch = false;
                            break;
                        }
                    }
                } else {
                    // Note that the arrays are different
                    bMatch = false;
                }

                bSetValues = !bMatch;
            } else {
                // Get the current selected object
                var objCurrent = this.getSelectedObject(objDropdown);
                // Get the current selected value
                var objNew = this.get("objValue");
                // If both items are empty OR both items have the same key
                if (a24Core.isEmpty(objCurrent) && a24Core.isEmpty(objNew) || !a24Core.isEmpty(objCurrent) && !a24Core.isEmpty(objNew) && objCurrent[this.get("sOptionsValueKey")] === objNew[this.get("sOptionsValueKey")]) {
                    // Mark that the values should not set
                    bSetValues = false;
                }
            }

            // If bSetValues is still true
            if (bSetValues) {
                let mValues = this._getSingleMultiSelectedObjects();
                // Set values on the Dropdown
                objDropdown.material_select("destroy");
                objDropdown.val(mValues);
                this.createAndAttachListeners(objDropdown);

                this.onHandleEscEvent();
            }

            // Get the dropdown item
            this.getValuesOnDropdownInput(objDropdown);
        },
        createAndAttachListeners: function (objDropdown) {
            // Init the dropdown
            objDropdown.material_select();

            Ember.run.next(() => {
                // Move the list to the dropdown
                var objList = $(this.$()).find("ul.dropdown-content");
                this.get("_objDivInstance").append(objList);
                objList.addClass("view-select-box");

                this.get("_objDivInstance").on("click", objEvent => {
                    objEvent.stopPropagation();

                    if (objEvent.type === "click") {
                        this.closePopup();
                    }
                });
            });

            // ON CLOSE
            $(this.$()).find("input.select-dropdown").on("close", () => {
                if (this.get("_objDivInstance").hasClass("dropdown-open")) {
                    Ember.run(() => {
                        //filty hacks here because we need to allow the dropdown to stay on screen while fading so that the
                        //keyup can fire on the correct item. If we hide the dropdown immediatly then the keyup wont fire
                        //for the clicked item and non gets selected. MDB closes the dropdown with keydown and selects the
                        //value with keyup -_-
                        if (!a24Core.isEmpty(this.get("_objCloseLater"))) {
                            Ember.run.cancel(this.get("_objCloseLater"));
                            this.set("_objCloseLater", null);
                        }
                        this.set("_objCloseLater", Ember.run.later(() => {
                            this.get("_objDivInstance").removeClass("dropdown-open");
                            $("body").removeClass("stop-scrolling");
                            this.get("_objDivInstance").css({
                                "padding-top": "0",
                                "padding-left": "0",
                                "width": "0", //Width and height set here to preserve animations for open later
                                "heigt": "0"
                            });
                            //Defocus the input so the it can regain focus when clicked(this is dmb bug where after
                            //selecting a value using the arrows and enter it does not blur the field)
                            $(this.$("input.select-dropdown")).trigger("blur");
                        }, 200));
                    });
                }
            });

            // ON OPEN
            $(this.$()).find("input.select-dropdown").on("open", () => {
                Ember.run(() => {
                    if (!a24Core.isEmpty(this.get("_objCloseLater"))) {
                        Ember.run.cancel(this.get("_objCloseLater"));
                        this.set("_objCloseLater", null);
                    }
                    this.get("_objDivInstance").addClass("dropdown-open");
                    $("body").addClass("stop-scrolling");

                    // Set the width equal to that of the list
                    this.get("_objDivInstance").find("ul.dropdown-content").width($(this.$()).find("input.select-dropdown").width());

                    var objInputOffset = $(this.$()).find("input.select-dropdown").offset();
                    var objBlockerDivOffset = this.get("_objDivInstance").offset();

                    // Set the padding of the blocker div to match the position of the input so that it lines up with the input
                    this.get("_objDivInstance").css({
                        "padding-top": objInputOffset.top - objBlockerDivOffset.top + "px",
                        "padding-left": objInputOffset.left + "px"
                    });
                });
            });
        },
        willDestroyElement: function () {
            // Destroy the div if it still exists
            if (!a24Core.isEmpty(this.get("_objDivInstance"))) {
                var objDropdown = $("#" + this.get("_sInputId"));
                objDropdown.material_select("destroy");
                this.get("_objDivInstance").remove();
            }
            this._super(...arguments);
        },
        clear: function () {
            var objDropdown = $("#" + this.get("_sInputId"));
            objDropdown.material_select("destroy");
            objDropdown.val(null);
            this.createAndAttachListeners(objDropdown);
            this.getValuesOnDropdownInput(objDropdown);

            this.onHandleEscEvent();
        },
        observeValueChange: Ember.observer("objValue", "arrValues.@each", function () {
            // Set values based on input change
            Ember.run.scheduleOnce("afterRender", this, this.setValuesOnDropdownInput);
        }),
        observeOptionsChange: Ember.observer("arrOptions.@each", function () {
            Ember.run.scheduleOnce("afterRender", this, this.setValuesAndDoAfterChange);
        }),
        observeDisabledChange: Ember.observer("bDisabled", function () {
            // Set component to enabled/disabled
            Ember.run.scheduleOnce("afterRender", this, this._toggleDropdownInputState);
        }),
        setValuesAndDoAfterChange: function () {
            this.clear();
        },
        functionAfterOptionChange: function () {
            var objDropdown = $("#" + this.get("_sInputId"));
            objDropdown.material_select("destroy");
            this.createAndAttachListeners(objDropdown);

            this.onHandleEscEvent();
        },
        // gets object(s) currently selected in the dropdown
        _getSingleMultiSelectedObjects: function () {
            if (this.get("bMultiSelect")) {
                // Create empty array
                var arrNewValues = [];

                // Get the array of values
                var arrValues = this.get("arrValues");
                // Set arrValues as empty array if it is not set
                if (a24Core.isEmpty(arrValues)) {
                    arrValues = [];
                }

                for (var j = 0; j < arrValues.length; j++) {
                    // Add each mValue key to the array
                    arrNewValues.push(arrValues[j][this.get("sOptionsValueKey")]);
                }

                return arrNewValues;
            } else {
                // Create empty object
                var objNewValue = null;
                // If the mValue is not null
                if (!a24Core.isEmpty(this.get("objValue"))) {
                    // Set objNewValue to the mValue key
                    objNewValue = this.get("objValue")[this.get("sOptionsValueKey")];
                }

                return objNewValue;
            }
        },
        _toggleDropdownInputState: function () {
            // Set values on the Dropdown
            let objDropdown = $("#" + this.get("_sInputId"));
            objDropdown.material_select("destroy");
            let mValues = this._getSingleMultiSelectedObjects();
            objDropdown.val(mValues);
            if (this.get("bDisabled")) {
                objDropdown.attr("disabled", true);
            } else {
                objDropdown.removeAttr("disabled");
            }
            this.createAndAttachListeners(objDropdown);
        },

        observeErrorChanges: Ember.observer("sErrorMessage", function () {
            // Get the dropdown
            // This is done since MBD replaces the <select> with their own html which adds the input in the dom
            var objSelectDropdown = this.$("input.select-dropdown");
            // Check if the error message is set
            if (a24Core.isEmpty(this.get("sErrorMessage"))) {
                // Remove class since the error is not set
                objSelectDropdown.removeClass("invalid");
            } else {
                // Add class since the error is set
                objSelectDropdown.addClass("invalid");
            }
        })
    });
});
define("a24-ember-staffshift-core/components/react-ember-utils", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/react-ember-utils"], function (exports, _didRenderChangesMixin, _reactEmberUtils) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _reactEmberUtils.default,
        objPostIframeData: null,

        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com-base"),
        _objUserSession: Ember.inject.service("user-session"),
        _objReactEmberNavigation: Ember.inject.service("react-ember-navigation"),
        _objReactEmberUtilsService: Ember.inject.service("react-ember-utils"),

        _funcUnregister: null,

        _bReadyFired: false,

        _sIFrameId: null,

        _sReactUtilsUrl: Ember.computed.readOnly("_objEnv.sReactUtilsGatewayUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function () {
            this._super(...arguments);

            if (this.get("_objEnv").environment === "test") {
                this.set("_sIFrameId", "testFrameIdhere1234");
            } else if (a24Core.isEmpty(this.get("_sIFrameId"))) {
                this.set("_sIFrameId", Ember.guidFor({}));
            }
            this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(objMessage => {
                let objData = {};
                if (!a24Core.isEmpty(objMessage.data)) {
                    objData = JSON.parse(objMessage.data);
                }
                if (a24Core.isEmpty(objData.frameId) || objData.frameId === this.get("_sIFrameId")) {
                    this._handleMessages(objData);
                }
            }, this.get("_sReactUtilsUrl")));
        },

        willDestroyElement: function () {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            this._super(...arguments);
        },

        _postData: Ember.observer("_objReactEmberUtilsService.objRequest", function () {
            if (this.get("_bReadyFired") && !a24Core.isEmpty(this.get("_objReactEmberUtilsService.objRequest"))) {
                let objData = {
                    type: "trigger_api_request",
                    data: { request: this.get("_objReactEmberUtilsService.objRequest") },
                    requestId: this.get("_objReactEmberUtilsService.objRequest.requestId")
                };
                let objDataCopy = Ember.copy(objData, true);
                objDataCopy.data.authResponse = Ember.copy(this.get("_objUserSession.objAuthData"), true);
                objDataCopy.data.contextResponse = Ember.copy(this.get("_objUserSession.objContextDetails"), true);

                this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("_sReactUtilsUrl"), this.$("iframe")[0].contentWindow);
            }
        }),

        _handleMessages: function (objData) {
            if (objData.type === "app_ready") {
                this.set("_bReadyFired", true);
                this.get("_objReactEmberUtilsService").triggerRequestQueue();
            } else if (objData.type === "trigger_api_request_response") {
                this.get("_objReactEmberUtilsService").reactApiRequestResponse(objData.data, objData.requestId);
            }
        }
    });
});
define("a24-ember-lookup/services/a24-strings", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * A service that contains the base strings
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  8 January 2018
     */
    let objStrings = {
        _objStrings: {

            //===== STRINGS START =====

            home: "Home",
            work: "Work",

            dayMonLetter: "M",
            dayTueLetter: "T",
            dayWedLetter: "W",
            dayThuLetter: "T",
            dayFriLetter: "F",
            daySatLetter: "S",
            daySunLetter: "S",

            dayMonShort: "Mon",
            dayTueShort: "Tue",
            dayWedShort: "Wed",
            dayThuShort: "Thu",
            dayFriShort: "Fri",
            daySatShort: "Sat",
            daySunShort: "Sun",

            dayMonLong: "Monday",
            dayTueLong: "Tuesday",
            dayWedLong: "Wednesday",
            dayThuLong: "Thursday",
            dayFriLong: "Friday",
            daySatLong: "Saturday",
            daySunLong: "Sunday",

            monthJanShort: "Jan",
            monthFebShort: "Feb",
            monthMarShort: "Mar",
            monthAprShort: "Apr",
            monthMayShort: "May",
            monthJunShort: "Jun",
            monthJulShort: "Jul",
            monthAugShort: "Aug",
            monthSepShort: "Sep",
            monthOctShort: "Oct",
            monthNovShort: "Nov",
            monthDecShort: "Dec",

            monthJanLong: "January",
            monthFebLong: "February",
            monthMarLong: "March",
            monthAprLong: "April",
            monthMayLong: "May",
            monthJunLong: "June",
            monthJulLong: "July",
            monthAugLong: "August",
            monthSepLong: "September",
            monthOctLong: "October",
            monthNovLong: "November",
            monthDecLong: "December",

            ok: "Ok",
            clear: "Clear",

            yes: "Yes",
            no: "No",

            noData: "No Data",

            close: "Close",

            nextMonth: "Next month",
            previousMonth: "Previous month",
            monthSelect: "Select a month",
            yearSelect: "Select a year",

            selectOption: "Select an option...",

            dateFilterToday: "Today",
            dateFilterYesterday: "Yesterday",
            dateFilterThisWeekToday: "This week (Mon - Today)",
            dateFilterThisWeekToFri: "This week (Mon - Fri)",
            dateFilterThisWeekToSun: "This week (Mon - Sun)",
            dateFilterLast7Days: "Last 7 days",
            dateFilterLastWeekToFri: "Last week (Mon - Fri)",
            dateFilterLastWeekToSun: "Last week (Mon - Sun)",
            dateFilterLast14Days: "Last 14 days",
            dateFilterThisMonthToToday: "This month (1 - Today)",
            dateFilterThisMonthToEnd: "This month (1 - Last Day)",
            dateFilterLast30Days: "Last 30 days",
            dateFilterLastMonth: "Last month",
            dateFilterCustomRange: "Custom range",
            dateFilterWithinTheLast: "Within the last",
            dateFilterWithinTheNext: "Within the next",
            dateFilterNotWithinTheLast: "Not within the last",
            dateFilterNotWithinTheNext: "Not within the next",
            dateDays: "days",
            dateFilterNotEmpty: "Not Empty",

            dateFilterFutureDatesToday: "During the Rest of Today",
            dateFilterFutureDatesRestOfWeek: "During the Rest of this Week",
            dateFilterFutureDatesRestOfMonth: "During the Rest of this Month",
            dateFilterFutureDatesWithinTheNext: "Within the Next",
            dateFilterFutureDatesCustomRange: "During Custom",

            dateFilterExpiryNotExpired: "Not Expired",
            dateFilterExpiryNotExpiredGrace: "Not Expired with grace of",
            dateFilterExpiryWillNotExpire: "Not Expired and will not expire in",
            dateFilterExpiryAfter: "Expiry is greater than",

            dateFilterObtainedWithinLast: "no longer than",
            dateFilterObtainedAtLeast: "at least",

            dateFilterBetween: "Between",
            ago: "Ago",
            fromToday: "From Today",

            filterResults: "Filter Results",
            showAllResultsWhere: "Show all results where",
            selectFilter: "Select a filter",
            emptyEntries: "Empty Entries",
            addFilter: "Add Filter",
            from: "From",
            to: "To",
            of: "Of",
            enterValue: "Enter a value",
            selectValue: "Select a value",
            isEmpty: "Is empty",
            contains: "Contains",
            is: "Is",
            isNot: "Is not",
            startsWith: "Starts with",
            endsWith: "Ends with",
            multiple: "Multiple",
            in: "In",
            notIn: "Not in",
            isIn: "Is in",
            equalTo: "Equal to",
            biggerThan: "Bigger than",
            smallerThan: "Smaller than",
            and: "And",
            or: "Or",
            any: "Any",

            noResultsFound: "No results found",

            itemsSelected: "Item/s Selected",

            viewMore: "View more",
            viewLess: "View less",
            viewAllEntries: "View all entries",

            cancel: "Cancel",
            apply: "Apply",
            save: "Save",
            back: "Back",
            okay: "Okay",
            confirm: "Confirm",
            edit: "Edit",
            remove: "Remove",
            next: "Next",
            manage: "Manage",
            username: "Username",
            login: "Login",
            signOut: "Sign out",
            mobileNumber: "Mobile Number",
            usernameUnavailable: "The Username you have chosen is unavailable",

            clickHereToEdit: "Click here to edit",
            dataEnteredSuccessfullyClickToPreview: "data entered successfully, click here to preview or edit",

            unsavedData: "You have unsaved data. Do you want to continue?",

            typeHere: "Type Here",
            other: "Other",
            profile: "Profile",

            firstName: "First Name",
            lastName: "Last Name",
            otherNames: "Other names",
            phoneOptOut: "Phone Opt Out",
            emailOptOut: "Email Opt Out",

            sourceType: "Source Type",
            sourceDetails: "Source Details",
            sourceDate: "Source Date",

            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            cityTown: "City/Town",
            postCode: "Postcode",
            country: "Country",
            countryCode: "Country Code",
            county: "County",
            dateFrom: "Date From",
            dateTo: "Date To",
            currentAddress: "The above address is my current address",
            province: "Province",
            poBox: "Po Box",
            building: "Building",
            street: "Street",
            region: "Region",
            postal: "postal",
            provinceCountry: "Province/County",
            postalCode: "Postal Code",

            linkSharingOn: "Link sharing on",

            industry: "Industry",

            a24RequiredFieldsMarked: "Required fields are marked with ",
            emptyEntriesMessage: "Empty entries are not allowed",

            serverError: "The server encountered an error!",
            a24group: "A24 Group",
            expires: "Expires",
            comingSoon: "Coming soon",
            availableSoon: "We are working on some new features that will be available to you soon",
            update: "Update",
            dateOfBirth: "Date of Birth",
            continue: "Continue",
            gender: "Gender",
            nationality: "Nationality",
            contactNumber: "Contact Number",
            extension: "Extension",
            male: "Male",
            female: "Female",

            language: "Language",
            attention: "Attention",
            address: "Address",
            latitude: "Latitude",
            longitude: "Longitude",
            name: "Name",

            email: "Email",
            password: "Password",
            rememberMe: "Remember Me",
            signIn: "Sign in",
            submit: "Submit",

            welcome: "Welcome",

            selectHeaderText: "Select Columns to display",

            copySuccess: "Copied Successfully",
            copyFailed: "Copy Failed! Manually copy the highlighted text",
            showHideColumns: "Show/hide columns",
            clearSelection: "Clear selection",
            filtersCanbeApplied: "Filter results",
            shareLink: "Shareable link",
            previousPage: "Previous page",
            nextPage: "Next page",
            moreActions: "More actions",
            navigateToSection: "Navigate to section",

            website: "Website",
            add: "Add",
            status: "Status",
            effectiveAt: "Effective at",
            active: "Active",
            inactive: "Inactive",
            title: "Title",
            description: "Description",
            comment: "Comment",
            executingUser: "Executing User",
            createdBy: "Created By",

            download: "Download",
            downloadPdfPreview: "Download pdf preview",
            downloadPdf: "Downloading pdf...",
            downloadPdfFinish: "Finished downloading pdf",
            downloadPdfFailed: "Failed to download pdf",

            mainDetails: "Main Details",
            emails: "Emails",
            phoneNumbers: "Phone Numbers",
            addresses: "Addresses",
            primaryContactNumber: "Primary Contact Number",
            primaryEmailAddress: "Primary Email Address",
            primaryAddress: "Primary Address",

            primary: "Primary",
            dateModified: "Date Modified",
            dateCreated: "Date Created",

            disabled: "Disabled",
            enable: "Enable",
            disable: "Disable",

            file: "File",
            fileDownload: "Download File",
            documents: "Documents",
            upload: "Upload",
            uploadedBy: "Uploaded By",
            addDocument: "Add Document",
            documentName: "Document Name",

            location: "Location",
            headOffice: "Head Office",
            subOffice: "Sub Office",

            organisation: "Organisation",
            organisationType: "Organisation Type",
            organisationDetails: "Organisation Details",
            organisations: "Organisations",
            editOrg: "Edit organisation",
            viewOrganisation: "View Organisation",
            removeOrganisation: "Remove Organisation",

            addPhoneNumber: "Add phone number",
            editPhoneNumber: "Edit phone number",
            addEmail: "Add email",
            editEmail: "Edit email",

            makePrimary: "Make Primary",
            removeConfirmation: "Are you sure you want to remove this item?",
            primaryConfirmation: "Are you sure you want to set this item as your primary?",

            addAddress: "Add Address",
            editAddress: "Edit Address",
            timezone: "Timezone",

            users: "Users",
            createUser: "Create User",
            editUser: "Edit User",
            accessgroup: "Access Group",
            retypePassword: "Retype Password",
            passwordsDoNotMatch: "Passwords do not match",
            changePassword: "Change Password",
            enableConfirm: "Are you sure you want to enable this user?",
            disableConfirm: "Are you sure you want to disable this user?",

            selectTitle: "Select Title",
            forename: "Forename",
            surname: "Surname",
            tips: "Tips",

            unhandledAddRestError: "Failed to add item, something unexpected has happened",
            unhandledUpdateRestError: "Failed to update item, something unexpected has happened",
            unhandledRemoveRestError: "Failed to remove item, something unexpected has happened",
            queryMustBeAnObject: "Query must be an object, thus start with {",
            queryMustNotContain: "Query must not contain 'page', 'items_per_page', or 'sort'",
            queryMustContainAtLeastOne: "Query must contain at least one property",
            //===== STRINGS END   =====

            //===== SAFE STRINGS START =====

            removeFailedRefreshPage: "Failed to remove item.\nThe data might have changed. Please refresh the page and try again",
            updateFailedRefreshPage: "Failed to update item.\nThe data might have changed. Please refresh the page and try again",

            //===== SAFE STRINGS END   =====

            //===== TOKEN STRINGS START =====

            editItem: "Edit {0}",
            addItem: "Add {0}",
            removeItem: "Remove {0}",

            addingItem: "Adding {0}...",
            updatingItem: "Updating {0}...",
            removingItem: "Removing {0}...",

            successAddItem: "Successfully added {0}",
            successUpdateItem: "Successfully updated {0}",
            successRemoveItem: "Successfully removed {0}",

            failAddItem: "Failed to add {0}",
            failUpdateItem: "Failed to update {0}",
            failRemoveItem: "Failed to remove {0}",

            validationMaxLength: "{0} is not allowed to be more than {1} characters",
            validationMaxLengthMulti: "The items in {0} are not allowed to be more than {1} characters each",
            validationMinLength: "{0} is not allowed to be less than {1} characters",
            validationMinLengthMulti: "The items in {0} are not allowed to be less than {1} characters each",
            validationMaxFileSize: "{0} is not allowed to be more than {1}",
            validationMinFileSize: "{0} is not allowed to be less than {1}",
            validationFileAllowedMimeTypes: "{0} should be one of the following file types: {1}",
            validationFileAllowedAllMimeTypes: "Can`t read file type of {0}. Make sure {0} has a file type.",
            validationRequired: "{0} is a required field",
            validationSuggestionRequired: "{0} is a required field and must be selected from the suggested options",
            validationDateInFuture: "{0} is not allowed to be in the future",
            validationDateInPast: "{0} is not allowed to be in the past",
            validationDateBefore: "{0} is not allowed to be before {1}",
            validationDateOfBirthTooOld: "The {0} should not succeed more that {1} years(older than age {1})",
            validationDateOfBirthTooYoung: "The {0} should not precede back less than {1} years(younger than age {1})",
            validationDateOfBirthInconsistent: "{0} does not match the existing date of birth",
            validationDateOfBirthCantBeBefore: "The {0} should not be prior to Date of Birth",
            validationWholeNumber: "{0} must be a whole number",
            validationPasswordRequirement: "{0} must be between 6 and 100 characters long and not contain any spaces.",

            validationRestMinAge: "{0} is younger than the minimum allowed age",
            validationRestMaxAge: "{0} is older than the maximum allowed age",
            validationRestMaxLength: "{0} has more than the maximum allowed characters",
            validationRestMaxLengthMulti: "One or more of the items in {0} have more than the maximum allowed characters",
            validationRestMinLength: "{0} has less than the minimum allowed characters",
            validationRestMinLengthMulti: "One or more of the items in {0} have less than the minimum allowed characters",
            validationRestInvalidChar: "{0} has invalid characters",
            validationRestInvalidCharMulti: "One or more of the items in {0} have invalid characters",
            validationRestMax: "{0} is bigger that the maximum allowed size",
            validationRestMin: "{0} is smaller that the minimum allowed size",

            validationRestNotSupportedOption: "The option you have chosen for {0} is not of the supported options.",
            validationRestInvalidFormat: "{0} is not in the correct format.",
            validationRestUnsupportedValue: "The value for {0} is not supported.",
            isNotEmptyTag: "{0} is not empty.",
            "a24-string-html-span": "{0}<span class='a24-strings-html-item-1'>{1}</span>",
            "a24-string-html-dot-value": "<span class='a24-strings-html-black-text'>{0}</span>&nbsp;•&nbsp;" + "<span class='a24-strings-html-grey-text'>{1}</span>",
            "a24-string-html-colon-value": "<span class='a24-strings-html-grey-text'>{0}</span>:&nbsp;" + "<span class='a24-strings-html-black-text'>{1}</span>",

            "a24-string-html-icon-grey": "<i class='a24-material-text-icons material-icons a24-grey-text-lighten-1'>" + "{0}</i>",
            "a24-string-html-icon-grey-normal": "<i class='a24-material-text-icons material-icons grey-text'>{0}</i>",
            "a24-string-html-icon-secondary": "<i class='a24-material-text-icons material-icons " + "a24-secondary-color-text-accent-force font-h4-force'>{0}</i>",

            "a24-string-html-icon-warning": "<i class='a24-material-text-icons material-icons warning-color-text'>warning</i>",

            //===== TOKEN STRINGS END   =====

            //===== LOCALE STRINGS START =====

            province_localised: {
                en_gb: "County",
                en_uk: "County",
                en_za: "Province",
                en_us: "State",
                other: "County"
            },

            postCode_localised: {
                en_gb: "Post Code",
                en_uk: "Post Code",
                en_za: "Post Code",
                en_us: "Zip Code",
                other: "Post Code"
            }

            //===== LOCALE STRINGS END   =====
        }
    };

    exports.default = _a24StringsBase.default.extend(objStrings);
});
define("a24-ember-lib/services/google-analytics", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function () {
            this._super(...arguments);

            //Only create the google analytics if it is configured for the current environment
            if (!a24Core.isEmpty(this._getGoogleAnalyticsConfig())) {
                this._gaWrapper("create", this._getGoogleAnalyticsConfig().sWebPropertyId, "auto");
            }
        },
        doPageViewHit: function (sPage, bUseIds, bUseQueryParams) {
            //Only do screen hit if it is configured for the current environment
            if (!a24Core.isEmpty(this._getGoogleAnalyticsConfig())) {
                var sPageHit = sPage;
                if (!bUseQueryParams) {
                    if (sPage.indexOf("?") !== -1) {
                        sPageHit = sPage.slice(0, sPage.indexOf("?"));
                    }
                }

                var sUpdatedPage = sPageHit;
                if (!bUseIds) {
                    var objRegex = /[a-zA-Z0-9]{24}/g;
                    sUpdatedPage = sPageHit.replace(objRegex, this.get("_objLibConstants.GOOGLE_ANALYTICS_PAGE_VIEW_ID_TOKEN"));
                }

                this._gaWrapper("send", "pageview", sUpdatedPage);
            }
        },
        _getGoogleAnalyticsConfig: function () {
            var objConfig = Ember.getOwner(this).resolveRegistration("config:environment");
            return objConfig.objGoogleAnalytics;
        },
        _gaWrapper: function (sAction, sActionValue, mPayload) {
            ga(sAction, sActionValue, mPayload);
        }
    });
});
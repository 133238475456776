define("a24-ember-lib/services/query-param-object-builder", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        /**
         * Builds a object that can be used to get/update/observe query params in the url
         *
         * @param arrQueryParamsKeys - The array of query params from the queryParams in the controller
         * @param objSetupContext - The controller/object that contains the queryParams and the rest of the query params with default values
         * @param objExistingQueryObject - The existing objQueryParams or null if first time calling
         * @param bDoDefault - Whether to create/update the sDefault on query params
         * @param bDoValues - Whether to create/update the sValue on query params
         */
        createQueryParamObject: function (arrQueryParamsKeys, objSetupContext, objExistingQueryObject, bDoDefault, bDoValues) {
            if (a24Core.isEmpty(arrQueryParamsKeys)) {
                arrQueryParamsKeys = [];
            }
            if (a24Core.isEmpty(objExistingQueryObject)) {
                objExistingQueryObject = {};
            }

            for (var i = 0; i < arrQueryParamsKeys.length; i++) {
                var sQueryParam = arrQueryParamsKeys[i];

                if (a24Core.isEmpty(objExistingQueryObject[sQueryParam])) {
                    objExistingQueryObject[sQueryParam] = {};
                }
                if (bDoDefault) {
                    objExistingQueryObject[sQueryParam].sDefault = objSetupContext.get(sQueryParam);
                }
                if (bDoValues) {
                    objExistingQueryObject[sQueryParam].sValue = objSetupContext.get(sQueryParam);
                }
            }

            return objExistingQueryObject;
        }
    });
});
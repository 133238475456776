define("a24-ember-staffshift-core/services/intercom", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        bIntercomActive: false,

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        /**
         * Initialise intercom widget
         *
         * @param sIntercomAppId - the intercom app id
         * @param sWebsite - website intercom is running on
         * @param sEmailAddress - email address for logged in user
         * @param sName - full name for logged in user
         * @param sUserId - user id for logged in user
         * @param sHash - the user hash
         *
         * @return void
         */
        initIntercom: function (sIntercomAppId, sWebsite, sEmailAddress, sName, sUserId, sHash) {
            var sAppId = sIntercomAppId;
            var sEmail = sEmailAddress;
            var sCurrentUserName = sName;
            var sCurrentHash = sHash;
            var sCurrentUserId = sUserId;
            var sCurrentWebsite = sWebsite;

            window.intercomSettings = {
                app_id: sAppId
            };

            if (!a24Core.isEmpty(sCurrentUserName)) {
                window.intercomSettings.name = sCurrentUserName; // full name
            }
            if (!a24Core.isEmpty(sEmail)) {
                window.intercomSettings.email = sEmail; // email address
            }
            if (!a24Core.isEmpty(sCurrentUserId)) {
                window.intercomSettings.user_id = sCurrentUserId; // current user id
            }
            if (!a24Core.isEmpty(sCurrentHash)) {
                window.intercomSettings.user_hash = sCurrentHash; // the user hash
            }
            if (!a24Core.isEmpty(sCurrentWebsite)) {
                window.intercomSettings.website = sCurrentWebsite; // the current website
            }

            // this function is from intercom
            // @see https://developers.intercom.com
            var funcInitializeIntercom = function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic("reattach_activator");
                    ic("update", w.intercomSettings);
                } else {
                    var d = document;
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function () {
                        var s = d.createElement("script");
                        s.type = "text/javascript";
                        s.async = true;
                        s.src = "https://widget.intercom.io/widget/" + sAppId;
                        var x = d.getElementsByTagName("script")[0];
                        x.parentNode.insertBefore(s, x);
                    };

                    // the default code to load the widget when the window loads
                    // has been replaced with calling the intercom load function directly
                    l();

                    //if (w.attachEvent) {
                    //    w.attachEvent("onload", l);
                    //}
                    //else {
                    //    w.addEventListener("load", l, false);
                    //}
                }
            };

            funcInitializeIntercom();

            this.set("bIntercomActive", true);
        },

        /**
         * Shutdown the intercom
         */
        shutdown: function () {
            if (!a24Core.isEmpty(window.Intercom)) {
                window.Intercom("shutdown");
            }
            this.set("bIntercomActive", false);
        },

        /**
         * This service method will be used to get all integrations that the user has available
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sUserId - The id of the user getting the contexts
         * @param sIntegration - the user integration
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param funcFail - The callback function to execute when the service call is not successful
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  25 October 2017
         */
        getIntegration: function (objCaller, sProperty, sUserId, sIntegration, funcSuccess, funcFail) {
            var sUrl = this.get("_sRestApiBaseUrl") + "/v1/user/" + sUserId + "/integrations";

            a24RestUrlConstruct.setBaseUrl(sUrl);
            a24RestUrlConstruct.addQueryParam("integration", sIntegration);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, funcFail));
        },

        /**
         * Show the intercom widget
         */
        show: function () {
            if (!a24Core.isEmpty(window.Intercom)) {
                window.Intercom("show");
            }
        },

        /**
         * Hide the intercom widget
         */
        hide: function () {
            if (!a24Core.isEmpty(window.Intercom)) {
                window.Intercom("hide");
            }
        },

        update: function (objUpdate) {
            if (!a24Core.isEmpty(window.Intercom)) {
                window.Intercom("update", objUpdate);
            }
        },

        /**
         * Hide or show the default launcher(FAB button) for intercom
         *
         * @param bShow - Whether to show or hide the default launcher
         */
        showDefaultLauncher: function (bShow) {
            if (!a24Core.isEmpty(window.Intercom)) {
                window.Intercom("update", {
                    "hide_default_launcher": !bShow
                });
            }
        }

    });
});
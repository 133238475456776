define("a24-ember-staffshift-core/services/react-ember-utils", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        arrToResolve: null,
        objRequest: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("arrToResolve"))) {
                this.set("arrToResolve", []);
            }
        },

        genUid: function () {
            const sChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
            let iLength = 16;
            let sUid = '';
            for (let i = 0; i < iLength; i++) {
                sUid += sChars[Math.floor(Math.random() * sChars.length)];
            }

            if (this.get("_objEnv").environment === "test") {
                return "testUid";
            }
            return sUid;
        },

        makeReactApiRequest: function (objRequest) {
            this.set("objRequest", null);
            let objPromiseObject = {};
            let sRequestId = this.genUid();
            let _objRequest = {
                endpoint: objRequest.sEndpoint,
                options: objRequest.objOptions,
                params: objRequest.objParams,
                requestId: sRequestId
            };
            let objPromise = new Promise((resolve, reject) => {
                objPromiseObject = {
                    requestId: sRequestId,
                    resolve: resolve,
                    reject: reject,
                    request: _objRequest
                };
            });

            this.get("arrToResolve").push(objPromiseObject);

            this.set("objRequest", _objRequest);

            return objPromise;
        },

        triggerRequestQueue: function () {
            this.set("objRequest", null);
            let arrToResolve = this.get("arrToResolve");
            if (arrToResolve.length > 0) {
                arrToResolve.forEach(objRequest => {
                    this.set("objRequest", objRequest.request);
                });
            }
        },

        reactApiRequestResponse: function (objData, sRequestId) {
            let arrToResolve = this.get("arrToResolve");
            let iRequestIndex = null;

            let objToResolve = arrToResolve.find((objRequest, iIndex) => {
                if (objRequest.requestId === sRequestId) {
                    iRequestIndex = iIndex;
                    return true;
                }
                return false;
            });
            if (!a24Core.isEmpty(objToResolve)) {
                objToResolve.resolve(objData);
                arrToResolve.splice(iRequestIndex, 1);
            }
        }
    });
});
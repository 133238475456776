define("a24-ember-lib/components/react-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/react-popup"], function (exports, _didRenderChangesMixin, _reactPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com-base"),
        _objUserSession: Ember.inject.service("user-session"),
        classNames: ["react-popup"],
        classNameBindings: ["bShow::closed"],
        layout: _reactPopup.default,
        bShow: false,
        bLoading: false,
        objData: null,
        sIFrameUrl: null,
        sIFrameId: null,
        _sAllowList: "",
        // this relies on the react-ember-navigation having been created in the app
        _objReactEmberNavigation: Ember.inject.service("react-ember-navigation"),

        _bReadyFired: false,
        _sIFrameFullUrl: null,
        _funcUnregister: null,

        init() {
            this._super(...arguments);

            this.set("_sAllowList", "clipboard-read; clipboard-write;");

            if (!a24Core.isEmpty(this.get("sIFrameUrl"))) {
                var env = Ember.getOwner(this).resolveRegistration("config:environment");
                if (env.environment === "test") {
                    this.set("sIFrameId", "testFrameIdhere1234");
                } else if (a24Core.isEmpty(this.get("sIFrameId"))) {
                    this.set("sIFrameId", Ember.guidFor({}));
                }
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(objMessage => {
                    let objData = {};
                    if (!a24Core.isEmpty(objMessage.data)) {
                        objData = JSON.parse(objMessage.data);
                    }
                    if (a24Core.isEmpty(objData.frameId) || objData.frameId === this.get("sIFrameId")) {
                        this._handleMessages(objData);
                    }
                }, this.get("sIFrameUrl")));

                this._setFullUrl();
            }
        },

        willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            this._super(...arguments);
        },

        _setFullUrl: function () {
            if (a24Core.isEmpty(this.get("sIFrameUrl"))) {
                this.set("_sIFrameFullUrl", null);
                return;
            }
            let sPre = "?";
            if (this.get("sIFrameUrl").indexOf("?") !== -1) {
                sPre = "&";
            }
            this.set("_sIFrameFullUrl", this.get("sIFrameUrl") + sPre + "reactFrameId=" + this.get("sIFrameId"));
        },

        _notifyEventListener: function (event) {
            if (!a24Core.isEmpty(this.$("iframe"))) {
                this._postData({
                    type: "notify_event_listener",
                    data: {
                        event: event
                    }
                });
            }
        },

        _handleMessages: function (objData) {
            if (!this.get("_bReadyFired") && (objData.type === "app_ready" || objData.type === "router_ready")) {
                this.set("_bReadyFired", true);
                this._postData();
            } else if (objData.type === "check_parent_has_focus") {
                this._postData({
                    type: objData.type + "_response",
                    data: {
                        hasFocus: window.document.hasFocus() || document.hasFocus()
                    },
                    requestId: objData.requestId
                });
            } else if (objData.type === "add_event_listener") {
                if (objData.data.location === "window") {
                    window.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "remove_event_listener") {
                if (objData.data.location === "window") {
                    window.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "snackbar-message") {
                this.get("_objSnackbarService").showSnackbarMessage(objData.data.message);
            }

            // handle navigation message
            var objUrl = this.get("_objReactEmberNavigation").handleNavigationMessage(objData);
            if (!a24Core.isEmpty(objUrl)) {
                this._postObjectData(objUrl);
            }

            if (!a24Core.isEmpty(this.get("onRecieveData"))) {
                this.get("onRecieveData")(objData);
            }
        },

        _onChangeData: Ember.observer("objData", "sIFrameUrl", function () {
            this._setFullUrl();
            Ember.run.once(this, this._postData);
        }),

        _postData: function () {
            if (!a24Core.isEmpty(this.get("objData"))) {
                this._postObjectData(this.get("objData"));
            }
        },

        _postObjectData: function (objData) {
            let objDataCopy = Ember.copy(objData, true);
            if (objDataCopy.type === "trigger_route") {
                if (a24Core.isEmpty(objDataCopy.data)) {
                    objDataCopy.data = {};
                }
                objDataCopy.data.authResponse = Ember.copy(this.get("_objUserSession.objAuthData"), true);
                objDataCopy.data.contextResponse = Ember.copy(this.get("_objUserSession.objContextDetails"), true);
            }
            this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("sIFrameUrl"), this.$("iframe")[0].contentWindow);
        }
    });
});
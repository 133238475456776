define("a24-ember-lib/components/image-cropper-modal", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-lib/templates/components/image-cropper-modal"], function (exports, _didRenderChangesMixin, _inputFormElement, _imageCropperModal) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _imageCropperModal.default,
        classNames: ["image-cropper-modal"],

        bShowPopup: false,
        bLoading: true,
        sHeader: "",
        sAvatar: null,

        _objAjax: $.ajax,
        _objWhen: $.when,

        _bImageSet: false,
        _bScriptBusy: false,
        _bScriptLoaded: false,
        _bShowUnsavedData: false,
        _bShowDeleteConfirm: false,
        _bPopupLoading: false,

        _sChooseImage: null,

        _objCropper: null,
        _objOriginalData: null,

        _funcCreateObjectUrl: null,

        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),

        init: function () {
            this._super(...arguments);

            this.set("_sChooseImage", this.get("_objEmberLibStringService").getString("chooseImage").toUpperCase());

            if (a24Core.isEmpty(this.get("funcCreateObjectUrl"))) {
                this.set("_funcCreateObjectUrl", arrBlob => {
                    return URL.createObjectURL(arrBlob);
                });
            }

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._popupCancel();
                }
            });
        },

        _popupCancel: function () {
            if (this.get("bLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.get("objChildren.file.mValue"), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.get("onPopupToggle")(false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },

        _loadCropper: function () {
            var sResourceBase = "assets/cropperjs";
            var objThis = this;
            if (a24Core.isEmpty(this.get("_objCropper"))) {
                if (!this.get("_bScriptBusy") && !this.get("_bScriptLoaded")) {
                    this.set("_bScriptBusy", true);
                    this.get("_objWhen")(objThis.get("_objAjax")({
                        url: sResourceBase + "/cropper.min.js",
                        dataType: "script",
                        cache: true
                    }), objThis.get("_objAjax")({
                        url: sResourceBase + "/cropper.min.css",
                        cache: true
                    }).done(function (sCss) {
                        Ember.run(() => {
                            $("<style type='text/css'></style>").html(sCss).appendTo("head");
                        });
                    })).then(function () {
                        Ember.run(() => {
                            objThis.set("_bScriptBusy", false);
                            objThis.set("_bScriptLoaded", true);
                            objThis.set("bLoading", false);
                        });
                    }, function () {
                        Ember.run(() => {
                            objThis.set("_bScriptBusy", false);
                            objThis.set("_bScriptLoaded", false);
                        });
                    });
                }
            }
        },

        onPopupOpen: Ember.observer("bShowPopup", function () {
            this.set("_bShowDeleteConfirm", false);
            if (this.get("bShowPopup")) {
                this.set("_bPopupLoading", false);
                this.clearForm();
                this._loadCropper();

                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        this.set("_objOriginalData", this.get("objChildren.file.mValue"));
                        this.$(".image-cropper-container").height(this.$(".image-cropper-container").width());
                        // This is to allow the input to be able to capture from camera and only images
                        this.$("input[type=file]").attr("accept", "image/*");
                        this.$("input[type=file]").attr("capture", "");
                    });
                });
            } else {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").destroy();
                }
                this.set("_bImageSet", false);
            }
        }),

        onInputValueChanged: function (arrChildRef) {
            var sLookup = arrChildRef.join(".");
            var mValue = this.get(sLookup + ".mValue");
            if (!a24Core.isEmpty(mValue)) {
                var arrBlob = mValue.slice(0, mValue.size);
                this._initCropper(arrBlob);
                this.set("_bImageSet", true);
            } else {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").destroy();
                }
                this.set("_bImageSet", false);
            }
        },

        _initCropper: function (arrBlob) {
            if (this.get("_bScriptLoaded")) {
                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        if (a24Core.isEmpty(this.get("_objCropper"))) {
                            var objElement = $("img.image-cropper-image").get(0);

                            var objCropper = new Cropper(objElement, {
                                viewMode: 3,
                                // Show the grid background
                                background: false,
                                guides: false,
                                cropBoxMovable: false,
                                cropBoxResizable: false,
                                // Enable to move the image
                                movable: true,
                                dragMode: "move",
                                // Enable to zoom the image
                                zoomable: true,
                                aspectRatio: 1 / 1,
                                autoCropArea: 1
                            });
                            this.set("_objCropper", objCropper);
                        }
                        this.get("_objCropper").replace(this.get("_funcCreateObjectUrl")(arrBlob));
                    });
                });
            }
        },

        actions: {
            onFileInputClick: function () {
                this.$("input[type=text]").click();
            },
            onPopupCancel: function () {
                this._popupCancel();
            },
            onPopupConfirm: function () {
                var objFile = this.get("objChildren.file.mValue");
                if (!a24Core.isEmpty(objFile)) {
                    if (a24Core.isEmpty(HTMLCanvasElement.prototype.toBlob)) {
                        // This is to cater for if the msToBlob does not take in a callback
                        var objBlob = this.get("_objCropper").getCroppedCanvas({
                            fillColor: "#fff",
                            width: 400,
                            height: 400
                        }).msToBlob(objBlob => {
                            if (!a24Core.isEmpty(this.get("onUploadImage"))) {
                                this.get("onUploadImage")(objFile, objBlob);
                            }
                        });

                        if (!a24Core.isEmpty(objBlob)) {
                            if (!a24Core.isEmpty(this.get("onUploadImage"))) {
                                this.get("onUploadImage")(objFile, objBlob);
                            }
                        }
                    } else {
                        this.get("_objCropper").getCroppedCanvas({
                            fillColor: "#fff",
                            width: 400,
                            height: 400
                        }).toBlob(objBlob => {
                            if (!a24Core.isEmpty(this.get("onUploadImage"))) {
                                this.get("onUploadImage")(objFile, objBlob);
                            }
                        });
                    }
                }
            },
            onPopupRemove: function () {
                this.set("_bPopupLoading", false);
                this.set("_bShowDeleteConfirm", true);
            },
            onPopupDeletePopupClose: function () {
                if (this.get("_bPopupLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupDeleteConfirm: function () {
                this.set("_bPopupLoading", true);
                if (!a24Core.isEmpty(this.get("onDeleteImage"))) {
                    this.get("onDeleteImage")();
                }
            },

            onPopupUnsavedConfirm: function () {
                this.get("onPopupToggle")(false);
                this.set("_bShowUnsavedData", false);
            },
            zoomInAction: function () {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").zoom(0.1);
                }
            },
            zoomOutAction: function () {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").zoom(-0.1);
                }
            },
            rotateLeftAction: function () {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").rotate(-90);
                }
            },
            rotateRightAction: function () {
                if (!a24Core.isEmpty(this.get("_objCropper"))) {
                    this.get("_objCropper").rotate(90);
                }
            }
        }
    });
});
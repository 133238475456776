define("a24-ember-lib/components/input-form-checkbox", ["exports", "a24-ember-lib/templates/components/input-form-checkbox", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormCheckbox, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormCheckbox.default,
        classNames: ["a24ErrorInputParent"],
        classNameBindings: ["hasErrorMessageSpace::clear-margin-bottom"],
        hasErrorMessageSpace: false,
        bValue: false,
        mCheckboxValue: null,
        sInputType: "checkbox",
        bDisabled: false,
        _bUIValue: false,
        _bInternalProcess: false,
        init: function () {
            this._super(...arguments);

            // Set as internal process
            this.set("_bInternalProcess", true);
            // Set UI value
            this.set("_bUIValue", this.get("bValue"));
            // Set mValue
            this.setMValueWithInternalFlag({
                bValue: this.get("_bUIValue"),
                mCheckboxValue: this.get("mCheckboxValue")
            }, true);
            // Done with internal process
            this.set("_bInternalProcess", false);

            if (!a24Core.isEmpty(this.get("sErrorMessage"))) {
                this.observeErrorChanges();
            }
        },
        clear: function () {
            this.set("_bUIValue", false);
        },
        onUIValue: Ember.observer("_bUIValue", function () {
            // UI value might change as a result of internal changes,
            // here we use the state of _bInternalProcess to avoid unneeded events
            this.setMValueWithInternalFlag({
                bValue: this.get("_bUIValue"),
                mCheckboxValue: this.get("mCheckboxValue")
            }, this.get("_bInternalProcess"));
        }),
        observeChangesFromOutside: Ember.observer("bValue", "mCheckboxValue", function () {
            this.set("_bInternalProcess", true);
            this.set("_bUIValue", this.get("bValue"));
            this.set("_bInternalProcess", false);
        }),
        observeErrorChanges: Ember.observer("sErrorMessage", function () {
            if (a24Core.isEmpty(this.get("sErrorMessage"))) {
                this.set("hasErrorMessageSpace", false);
            } else {
                this.set("hasErrorMessageSpace", true);
            }
        })
    });
});
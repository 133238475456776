define("a24-ember-form-inputs/components/input-form-address-suggest", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-form-inputs/templates/components/input-form-address-suggest", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormAddressSuggest, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormAddressSuggest.default,
        classNames: ["input-form-address-suggest", "a24ErrorInputParent", "md-form"],
        classNameBindings: ["_bInputLoading:loading"],
        mValue: null,
        sInputType: "address-suggest",
        bLoading: false, // This is so the parent can justify if input should load or not

        arrLimitByCountryCodes: null,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _bLoading: true, // This is the internal flag that the input will justify if input is loading
        _bInputLoading: true, // This will input loading state
        _bInitDone: false,
        _objInputAddressService: Ember.inject.service("input-form-address-suggest-service"),
        _objAddressSuggestion: null,
        // This is a empty function so that the empty check would pass
        _funcScriptMock: function () {},

        willDestroy: function () {
            this._super(...arguments);

            if (!a24Core.isEmpty(window.google) && !a24Core.isEmpty(window.google.maps) && !a24Core.isEmpty(window.google.maps.event)) {
                window.google.maps.event.clearInstanceListeners(this.get("_objAddressSuggestion"));
            }
        },

        _getPlaceGoogle: function () {
            return this.get("_objAddressSuggestion").getPlace();
        },

        _initGoogleAutocomplete: function (objInput, objOptions, funcPopulateValues) {
            if (!a24Core.isEmpty(window.google) && !a24Core.isEmpty(window.google.maps) && !a24Core.isEmpty(window.google.maps.places)) {
                this.set("_objAddressSuggestion", new window.google.maps.places.Autocomplete(objInput, objOptions));

                this.get("_objAddressSuggestion").addListener("place_changed", funcPopulateValues);
            }
        },

        init: function () {
            this._super(...arguments);

            var objThis = this;

            var funcPopulateValues = function () {
                var objPlace = objThis._getPlaceGoogle();
                var sLongitude = "";
                var sLatitude = "";
                var sAddress = "";

                if (!a24Core.isEmpty(objPlace.geometry)) {
                    sLongitude = objPlace.geometry.location.lng();
                    sLatitude = objPlace.geometry.location.lat();
                }
                if (!a24Core.isEmpty(objPlace.formatted_address)) {
                    sAddress = objPlace.formatted_address;
                }

                var mValue = {
                    sLongitude: sLongitude,
                    sLatitude: sLatitude,
                    sAddressString: sAddress
                };
                objThis.set("mValue", mValue);
            };

            var funcSuccessCallback = function () {
                if (a24Core.isEmpty(objThis)) {
                    return;
                }
                Ember.run(() => {

                    var objHtmlInput = $("#" + objThis.get("_sInputId"))[0];

                    let objOptions = {
                        types: ["geocode"],
                        fields: ["geometry", "formatted_address"] //Have to limit what response gives back else we get billed more
                        //All Basic details are free, they are:
                        //address_component, adr_address, alt_id, formatted_address, geometry, icon, id, name,
                        //permanently_closed, photo, place_id, scope, type, url, utc_offset, vicinity

                        //NOTE!!! some responses from google can have address_components and geometry as empty so need to cater for that
                    };

                    let arrLimitByCountryCodes = objThis.get("arrLimitByCountryCodes");
                    if (!a24Core.isEmpty(arrLimitByCountryCodes)) {
                        objOptions.componentRestrictions = { country: arrLimitByCountryCodes };
                    }

                    objThis._initGoogleAutocomplete(objHtmlInput, objOptions, funcPopulateValues);

                    objThis.set("_bInitDone", true);
                    objThis.set("_bLoading", false);
                    if (!a24Core.isEmpty(objThis.get("onInputFormAddressSuggestInitDone"))) {
                        // Send the current input instance on
                        objThis.get("onInputFormAddressSuggestInitDone")(objThis);
                    }
                });
            };
            var funcFailCallback = function () {
                if (a24Core.isEmpty(objThis)) {
                    return;
                }
                Ember.run(() => {
                    //eslint-disable-next-line no-console
                    console.warn("Failed to load script needed for the input form address suggest");
                    objThis.get("onInputFormAddressSuggestError")();
                    objThis.set("_bLoading", false);
                });
            };

            if (!a24Core.isEmpty(this.get("_funcScriptMock"))) {
                this.set("_funcScriptMock", () => {
                    Ember.run.later(() => {
                        funcSuccessCallback();
                    }, 100);
                });
            }

            if (a24Core.isEmpty(this.get("onInputFormAddressSuggestInitDone"))) {
                //eslint-disable-next-line no-console
                console.error("You have to handle and wait for the onInputFormAddressSuggestInitDone to fire before interacting with " + "this input");
            }

            var objTextInput = null;
            Ember.run.scheduleOnce("afterRender", this, function () {

                objTextInput = $("#" + objThis.get("_sInputId"));

                if (this.get("_objEnv").environment === "test" && !a24Core.isEmpty(this.get("_funcScriptMock"))) {
                    // This is test setup
                    // We are doing this since we do not want to pass on properties from on component to the next the
                    // whole time just to mock the ajax call. so we default it to return this to set the mValue of the input
                    this.set("_getPlaceGoogle", function () {
                        return {
                            geometry: {
                                location: {
                                    lng: function () {
                                        return 123;
                                    },
                                    lat: function () {
                                        return 321;
                                    }
                                }
                            },
                            formatted_address: "some Address"
                        };
                    });
                    this.set("_initGoogleAutocomplete", (objInput, objSettings, funcListenerCallback) => {
                        objTextInput.on("onSetAddressSuggestForTest", (objEvent, objAddress) => {
                            this.set("_getPlaceGoogle", () => {
                                return objAddress;
                            });
                            funcListenerCallback();
                        });
                    });

                    this.get("_funcScriptMock")();
                } else {
                    this.get("_objInputAddressService").loadScript(funcSuccessCallback, funcFailCallback);
                }

                this.$("label").addClass("active");

                if (!a24Core.isEmpty(objThis.get("mValue"))) {

                    // Set the mvalue first
                    this.setMValueWithInternalFlag(this.get("mValue"), true);

                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(this.get("mValue.sAddressString"));
                    objTextInput.change();
                }

                objTextInput.on("keyup", objEvent => {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });
                1;
                objTextInput.on("blur", function () {
                    if (!objThis.get("_bInputLoading")) {
                        objThis.resetFloatingLabel(objTextInput);
                    }
                });
            });
        },
        clear: function () {
            this.setValueFromOutside("");
        },
        setValueFromOutside: function (mValue) {
            if (!this.get("_bInitDone")) {
                //eslint-disable-next-line no-console
                console.error("You have to wait for the onInputFormAddressSuggestInitDone to fire before interacting with this input");
            }

            this.setMValueWithInternalFlag(mValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.val(mValue.sAddressString);
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        observeValueChange: Ember.observer("mValue", function () {
            this.setValueFromOutside(this.get("mValue"));
        }),
        loadingObserver: Ember.observer("bLoading", "_bLoading", function () {
            if (!this.get("bLoading") && !this.get("_bLoading")) {
                this.resetFloatingLabel($("#" + this.get("_sInputId")));
                this.set("_bInputLoading", false);
            } else {
                this.$("label").addClass("active");
                this.set("_bInputLoading", true);
            }
        }),
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (!a24Core.isEmpty(objTextInput) && a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        }
    });
});
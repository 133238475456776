define("a24-ember-lib/components/react-page-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/react-page-component"], function (exports, _didRenderChangesMixin, _reactPageComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _reactPageComponent.default,
        classNames: ["tab-react-iframe", "react-page"],
        classNameBindings: ["_bShowPopup:modal-opened"],

        sIFrameId: null,
        sIFrameUrl: null,
        sIFrameLoader: null,
        objPostIframeData: null,
        sExtraQueryParams: null,

        _objDomService: Ember.inject.service("dom-event"),
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com-base"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationWithWillDestroyService: Ember.inject.service("navigation"),
        // this relies on the react-ember-navigation having been created in the app
        _objReactEmberNavigation: Ember.inject.service("react-ember-navigation"),

        _sAllowList: "",
        _sIFrameFullUrl: null,
        _bShowPopup: false,
        _bReadyFired: false,
        _funcUnregister: null,
        _funcPostLayoutData: null,
        _objLayoutInfo: null,
        _bFrameFullscreen: false,
        init: function () {
            this._super(...arguments);

            // Commented out since the fab does not show
            // schedule("afterRender", () => {
            //     $(".main-container").addClass("tab-react-iframe");
            // });

            // We need to specifically get the service since it is lazy loaded and incompatible with observe only
            this.get("_objDomService.iPageHeight");

            this.set("_sAllowList", "clipboard-read; clipboard-write;");

            if (!a24Core.isEmpty(this.get("sIFrameUrl"))) {
                var env = Ember.getOwner(this).resolveRegistration("config:environment");
                if (env.environment === "test") {
                    this.set("sIFrameId", "testFrameIdhere1234");
                } else if (a24Core.isEmpty(this.get("sIFrameId"))) {
                    this.set("sIFrameId", Ember.guidFor({}));
                }
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(objMessage => {
                    let objData = {};
                    if (!a24Core.isEmpty(objMessage.data)) {
                        objData = JSON.parse(objMessage.data);
                    }
                    if (a24Core.isEmpty(objData.frameId) || objData.frameId === this.get("sIFrameId")) {
                        this._handleMessages(objData);
                    }
                }, this.get("sIFrameUrl")));
            }

            var funcPostLayoutData = () => {
                if (a24Core.isEmpty(this) || !this.get("_bReadyFired")) {
                    return;
                }
                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        if (a24Core.isEmpty(this)) {
                            return;
                        }
                        var objRect = this.element.getBoundingClientRect();
                        let iRight = this.get("_objDomService.iPageWidth") - objRect.right;
                        let iBottom = this.get("_objDomService.iPageHeight") - objRect.bottom;
                        if (this.get("_objLayoutInfo.layoutLeft") !== objRect.left || this.get("_objLayoutInfo.layoutTop") !== objRect.top || this.get("_objLayoutInfo.layoutRight") !== iRight || this.get("_objLayoutInfo.layoutBottom") !== iBottom) {
                            if (!this.get("_bFrameFullscreen")) {
                                let objIframeEl = this.$(".a24-iframe");
                                if (!a24Core.isEmpty(objIframeEl)) {
                                    objIframeEl.width(objRect.width);
                                    objIframeEl.height(objRect.height);
                                }
                            }

                            var objLayoutInfo = {
                                layoutLeft: objRect.left,
                                layoutRight: iRight,
                                layoutTop: objRect.top,
                                layoutBottom: iBottom
                            };
                            this._postData({
                                type: "layout_info",
                                data: objLayoutInfo
                            });
                            this.set("_objLayoutInfo", objLayoutInfo);
                        }
                    });
                });
            };

            this.set("_funcPostLayoutData", funcPostLayoutData);
            this.get("_objDomService").on("resize", this.get("_funcPostLayoutData"));

            this._setFullUrl();
        },

        a24WillDestroy: function () {
            // Commented out since the fab does not show
            // $(".main-container").removeClass("tab-react-iframe");
        },

        _setFullUrl: function () {
            if (a24Core.isEmpty(this.get("sIFrameUrl"))) {
                this.set("_sIFrameFullUrl", null);
                return;
            }

            let sIframeUrl = this.get("sIFrameUrl");
            if (!a24Core.isEmpty(this.get("sExtraQueryParams"))) {
                sIframeUrl = `${sIframeUrl}?${this.get("sExtraQueryParams")}`;
            }

            let sPre = "?";
            if (sIframeUrl.indexOf("?") !== -1) {
                sPre = "&";
            }
            if (a24Core.isEmpty(this.get("sIFrameLoader"))) {
                // This will be the default
                this.set("_sIFrameFullUrl", `${sIframeUrl}${sPre}reactLoaderType=modeBackground&reactFrameId=${this.get("sIFrameId")}`);
            } else {
                this.set("_sIFrameFullUrl", `${sIframeUrl}${sPre}reactLoaderType=${this.get("sIFrameLoader")}&reactFrameId=${this.get("sIFrameId")}`);
            }
        },

        willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            this.get("_objDomService").off("resize", this.get("_funcPostLayoutData"));
            this._super(...arguments);
        },

        _onRender: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.once("afterRender", this._funcPostLayoutData);
        }),

        _onDataChange: Ember.observer("objPostIframeData", function () {
            Ember.run.once(this, this._postContextData);
        }),

        _postContextData: function () {
            if (!a24Core.isEmpty(this.get("objPostIframeData"))) {
                this._postData(this.get("objPostIframeData"));
            }
        },

        _postData: function (objData) {
            let objDataCopy = Ember.copy(objData, true);
            if (objDataCopy.type === "trigger_route") {
                if (a24Core.isEmpty(objDataCopy.data)) {
                    objDataCopy.data = {};
                }
                objDataCopy.data.authResponse = Ember.copy(this.get("_objUserSession.objAuthData"), true);
                objDataCopy.data.contextResponse = Ember.copy(this.get("_objUserSession.objContextDetails"), true);
            }
            this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _notifyEventListener: function (event) {
            if (!a24Core.isEmpty(this.$("iframe"))) {
                this._postData({
                    type: "notify_event_listener",
                    data: {
                        event: event
                    }
                });
            }
        },

        _handleMessages: function (objData) {
            if (!this.get("_bReadyFired") && (objData.type === "app_ready" || objData.type === "router_ready")) {
                this.set("_bReadyFired", true);
                Ember.run.once(this, this._funcPostLayoutData);
                Ember.run.once(this, this._postContextData);
            } else if (objData.type === "app_ready") {
                //Because the above if might run when the `router_ready` is received it means that the _funcPostLayoutData
                //might not be captured by react yet since they only start listening for that in the `app_ready`.
                //So we fire it here again if we receive app_ready after _bReadyFired
                Ember.run.once(this, this._funcPostLayoutData);
            } else if (objData.type === "check_parent_has_focus") {
                this._postData({
                    type: objData.type + "_response",
                    data: {
                        hasFocus: window.document.hasFocus() || document.hasFocus()
                    },
                    requestId: objData.requestId
                });
            } else if (objData.type === "add_event_listener") {
                if (objData.data.location === "window") {
                    window.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "remove_event_listener") {
                if (objData.data.location === "window") {
                    window.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "toggle_modal") {
                var bIsOpen = objData.data.open === true;
                this.set("_bShowPopup", bIsOpen);
                this.$(".react-iframe-wrapper").css("z-index", bIsOpen ? "1051" : "");
            } else if (objData.type === "snackbar-message") {
                this.get("_objSnackbarService").showSnackbarMessage(objData.data.message);
            } else if (objData.type === "show_modal_masks") {
                var iMaskCount = objData.data;
                var bOpen = iMaskCount > 0;
                this.set("_bShowPopup", bOpen);
                this.$(".react-iframe-wrapper").css("z-index", bOpen ? "1051" : "");

                var objBackgroundModal = this.$(".a24-modal-backdrop");
                var iOpacity = 1 - Math.pow(1 - 0.3, iMaskCount);
                objBackgroundModal.fadeTo(150, iOpacity);
            } else if (objData.type === "set_iframe_fullscreen") {
                if (objData.data) {
                    this.set("_bFrameFullscreen", true);
                    let objIframeEl = this.$(".a24-iframe");
                    if (!a24Core.isEmpty(objIframeEl)) {
                        objIframeEl.css({
                            position: "fixed",
                            "z-index": 1503 //to be above left menu on mobile, snackbar is still above this
                        });
                    }
                } else {
                    this.set("_bFrameFullscreen", false);
                    let objIframeEl = this.$(".a24-iframe");
                    if (!a24Core.isEmpty(objIframeEl)) {
                        objIframeEl.css({
                            position: "",
                            "z-index": ""
                        });
                    }
                }
            }

            // handle navigation message
            var objUrlData = this.get("_objReactEmberNavigation").handleNavigationMessage(objData);
            if (!a24Core.isEmpty(objUrlData)) {
                this._postData(objUrlData);
            }

            // This is for if we want to handle custom messages
            if (!a24Core.isEmpty(this.get("onRecieveData"))) {
                this.get("onRecieveData")(objData);
            }
        },

        actions: {
            onPopupClose() {
                if (this.get("_bShowPopup")) {
                    this._postData({
                        type: "modal_overlay_clicked"
                    });
                }
            }
        }
    });
});
define("a24-ember-lookup/services/a24-enums-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * This function will get you the backend equivalent when you have the frontend value
         *
         * @param sEnum - The name of the enum to use
         * @param sValue - The display value
         * @param sDefault - The default to return if the desired one was not found
         * @param bReturnEnumListItem - Returns the enum item as a list item
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return The backend value that you can send to api
         */
        getBackendValue: function (sEnum, sValue, sDefault, bReturnEnumListItem) {
            return this.getCustomValue(sEnum, "frontend", sValue, "backend", sDefault, bReturnEnumListItem);
        },

        /**
         * This function will get you the frontend equivalent when you have the api value
         *
         * @param sEnum - The name of the enum to use
         * @param sValue - The value from the api
         * @param sDefault - The default to return if the desired one was not found
         * @param bReturnEnumListItem - Returns the enum item as a list item
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return The frontend value that you can display on the UI
         */
        getFrontendValue: function (sEnum, sValue, sDefault, bReturnEnumListItem) {
            return this.getCustomValue(sEnum, "backend", sValue, "frontend", sDefault, bReturnEnumListItem);
        },

        /**
         * This function will get you the value from a enum based on the search and return key you want
         *
         * @param sEnum - The name of the enum to use
         * @param sSearchKey - The key to compare the value with
         * @param sSearchValue - The value to compare on the sSearchKey
         * @param sReturnKey - The key of the property value you want to be returned
         * @param sDefault - The default to return if the desired one was not found
         * @param bReturnEnumListItem - Returns the enum item as a list item
         *
         * @author Ruan Naude <ruan.naude@gmail.com>
         * @since  24 July 2020
         *
         * @return The custom key value matching the search
         */
        getCustomValue: function (sEnum, sSearchKey, sSearchValue, sReturnKey, sDefault, bReturnEnumListItem) {
            let objEnum = this[sEnum];

            if (a24Core.isEmpty(objEnum)) {
                return null;
            }

            let arrKeys = Object.keys(objEnum);
            let mDefaultReturn = null;
            for (var i = 0; i < arrKeys.length; i++) {
                if (objEnum[arrKeys[i]][sSearchKey] === sSearchValue) {
                    if (bReturnEnumListItem) {
                        return this._getListItemFromEnum(objEnum[arrKeys[i]]);
                    } else {
                        return objEnum[arrKeys[i]][sReturnKey];
                    }
                } else if (!a24Core.isEmpty(sDefault) && objEnum[arrKeys[i]][sSearchKey] === sDefault) {
                    if (bReturnEnumListItem) {
                        mDefaultReturn = this._getListItemFromEnum(objEnum[arrKeys[i]]);
                    } else {
                        mDefaultReturn = objEnum[arrKeys[i]][sReturnKey];
                    }
                }
            }

            return mDefaultReturn;
        },

        /**
         * This function will get you dropdown friendly list of items that are supported
         *
         * @param sEnum - The name of the enum to use
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return This method will return a dropdown key value list
         */
        getList: function (sEnum) {
            var objEnum = this[sEnum];

            if (a24Core.isEmpty(objEnum)) {
                return null;
            }

            return Object.keys(objEnum).map(sKey => {
                return this._getListItemFromEnum(objEnum[sKey]);
            });
        },

        _getListItemFromEnum: function (objEnumItem) {
            let obListItem = {
                sValue: objEnumItem.backend,
                sLabel: objEnumItem.frontend
            };
            Object.keys(objEnumItem).map(sKey => {
                if (sKey !== "backend" && sKey !== "frontend") {
                    obListItem[sKey] = objEnumItem[sKey];
                }
            });
            return obListItem;
        }
    });
});
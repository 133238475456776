define("a24-ember-lib/components/iframe-component", ["exports", "a24-ember-lib/templates/components/iframe-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _iframeComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _iframeComponent.default,
        sHeading: "",
        sIFrameUrl: "",
        sAllowList: "",
        bLoading: false,
        bPadHeader: false,
        _sIFrameName: null,
        bFullscreen: false,
        _objDomService: Ember.inject.service("dom-event"),
        _bInitSetSize: false,
        init: function () {
            this._super(...arguments);
            this.set("_sIFrameName", Ember.guidFor({}));

            // We need to specifically get the service since it is lazy loaded and incompatible with observe only
            this.get("_objDomService.iPageHeight");
        },
        didInsertElement: function () {
            this._super(...arguments);
            Ember.run.scheduleOnce("afterRender", () => {
                this.observeLoader();
            });
        },
        observeIFrameUrl: Ember.observer("sIFrameUrl", function () {
            this.set("_bInitSetSize", false);
            this.observeLoader();
        }),
        observeLoader: Ember.observer("bLoading", function () {
            if (!this.get("bLoading") && !this.get("_bInitSetSize")) {
                this.set("_bInitSetSize", true);
                this.onResize();
            }
        }),
        onResize: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            if (this.get("bFullscreen")) {
                window.parent.postMessage({
                    sEventType: "setHeight",
                    sIFrameName: this.get("_sIFrameName"),
                    iHeight: -1,
                    sIFrameType: "fullscreen"
                }, "*" // No location reference needed
                );
            }
        })
    });
});
define("a24-ember-form-inputs/components/input-form-shift", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-form-inputs/templates/components/input-form-shift"], function (exports, _didRenderChangesMixin, _inputFormElement, _inputFormShift) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _inputFormShift.default,
        classNames: ["input-form-shift", "md-form", "a24ErrorInputParent"],
        sInputType: "shift",
        bIsOutline: false,
        bDisabled: false,
        bForceMinuteStep: false,
        bDefaultDateToCurrentDate: true,
        bDefaultTimeToCurrentTime: true,
        iHourStepValue: 1,
        iMinuteStepValue: 15,

        _sTotal: null,
        _objMaxDate: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),

        init: function () {
            this._super(...arguments);

            // Set max date
            var objMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objMaxDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_YEARS"), 1);
            objMaxDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_YEAR"));

            this.set("_objMaxDate", objMaxDate);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    // Set start and end date
                    this._setDefaultDateAndTime();
                });
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.startDate"] = objMixin => {
                objMixin.get("arrValidation").pushObject(() => {
                    if (this.get("bRequired") && a24Core.isEmpty(this.get("mValue.sStartDate"))) {
                        return this.get("_objStringsService").getTokenString("validationRequired", this.get("_objFormInputsStringsService").getString("startDate"));
                    }
                });
            };

            this.set("objChildConfig", objValidationConfig);
        },

        clear: function () {
            this.get("objChildren.startDate").clear();
            this.get("objChildren.startTime").clear();
            this.get("objChildren.endDate").clear();
            this.get("objChildren.endTime").clear();
            this._setDefaultDateAndTime();
        },

        _setDefaultDateAndTime: function () {
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            if (this.get("bDefaultDateToCurrentDate")) {
                this.get("objChildren.startDate").setValueFromOutside(objCurrentDate.getDateFormat());
            }

            if (this.get("bDefaultTimeToCurrentTime")) {
                var iMinute = objCurrentDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"));
                iMinute = Math.floor(iMinute / this.get("iMinuteStepValue")) * this.get("iMinuteStepValue");

                objCurrentDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"), iMinute);

                this.get("objChildren.startTime").setValueFromOutside({
                    iHour: objCurrentDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS")),
                    iMinute: objCurrentDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"))
                });

                objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"), this.get("iMinuteStepValue"));

                this.get("objChildren.endTime").setValueFromOutside({
                    iHour: objCurrentDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS")),
                    iMinute: objCurrentDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"))
                });
            }
        },

        getPlainFormValueObject: function () {
            return {
                startDate: this.get("objChildren.startDate.mValue"),
                startTime: this.get("objChildren.startTime.mValue"),
                endDate: this.get("objChildren.endDate.mValue"),
                endTime: this.get("objChildren.endTime.mValue")
            };
        },

        onInputValueChanged: function (arrChildRef) {
            if (a24Core.isEmpty(arrChildRef)) {
                return;
            }

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.startDate" && !a24Core.isEmpty(this.get("objChildren.startDate.mValue"))) {
                this.get("objChildren.endDate").setValueFromOutside(this.get("objChildren.startDate.mValue"));
            }

            if (sLookup === "objChildren.startTime" || sLookup === "objChildren.startDate" || sLookup === "objChildren.endTime") {
                var objValue = this._formatPlainFormValueObject(this.getPlainFormValueObject());

                if (!a24Core.isEmpty(objValue.sStartDate) && !a24Core.isEmpty(objValue.sEndDate)) {
                    var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objStartDate.setDateFromBrowser(objValue.sStartDate, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                    objEndDate.setDateFromBrowser(objValue.sEndDate, this.get("_objLibConstants.DATE_TIME_FORMAT"));

                    var iStartHour = objStartDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS"));
                    var iStartMinute = objStartDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"));
                    var iEndHour = objEndDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS"));
                    var iEndMinute = objEndDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"));

                    // If end date is less than or equal to start date by minutes
                    if (objEndDate.difference(objStartDate, this.get("_objLibConstants.MOMENT_TIME_MINUTES")) <= 0) {
                        objEndDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                        this.get("objChildren.endDate").setValueFromOutside(objEndDate.getDateFormat());
                    } else if (objEndDate.difference(objStartDate, this.get("_objLibConstants.MOMENT_TIME_DAYS")) > 0 && (iEndHour > iStartHour || iEndMinute > iStartMinute)) {
                        this.get("objChildren.endDate").setValueFromOutside(objStartDate.getDateFormat());
                    }
                }
            }

            Ember.run.once(this, this._setMValue);
            Ember.run.once(this, this._updateTotal);
        },

        _setMValue: function () {
            this.setMValueWithInternalFlag(this._formatPlainFormValueObject(this.getPlainFormValueObject()), false);
        },

        setValueFromOutside(objValue) {
            if (!a24Core.isEmpty(objValue.sStartDate)) {
                var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                objStartDate.setDateFromBrowser(objValue.sStartDate, this.get("_objLibConstants.DATE_TIME_FORMAT"));

                this.get("objChildren.startDate").setValueFromOutside(objStartDate.getDateFormat());
                this.get("objChildren.startTime").setValueFromOutside({
                    iHour: objStartDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS")),
                    iMinute: objStartDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"))
                });
            }

            if (!a24Core.isEmpty(objValue.sEndDate)) {
                var objEndDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                objEndDate.setDateFromBrowser(objValue.sEndDate, this.get("_objLibConstants.DATE_TIME_FORMAT"));

                this.get("objChildren.endDate").setValueFromOutside(objEndDate.getDateFormat());
                this.get("objChildren.endTime").setValueFromOutside({
                    iHour: objEndDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_HOURS")),
                    iMinute: objEndDate.getTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MINUTES"))
                });
            }
        },

        checkExtraTypes: function () {
            return {
                bHandled: true,
                mReturned: this.get("mValue")
            };
        },

        _formatPlainFormValueObject: function (objData) {
            var objReturn = {};

            if (!a24Core.isEmpty(objData.startDate)) {
                objReturn.sStartDate = `${objData.startDate}`;

                if (!a24Core.isEmpty(objData.startTime) && !a24Core.isEmpty(objData.startTime.iHour) && !a24Core.isEmpty(objData.startTime.iMinute)) {
                    var sStartHour = this._formatTimeUnit(objData.startTime.iHour);
                    var sStartMinute = this._formatTimeUnit(objData.startTime.iMinute);
                    objReturn.sStartDate += ` ${sStartHour}:${sStartMinute}`;
                }
            }

            if (!a24Core.isEmpty(objData.endDate)) {
                objReturn.sEndDate = `${objData.endDate}`;

                if (!a24Core.isEmpty(objData.endTime) && !a24Core.isEmpty(objData.endTime.iHour) && !a24Core.isEmpty(objData.endTime.iMinute)) {
                    var sEndHour = this._formatTimeUnit(objData.endTime.iHour);
                    var sEndMinute = this._formatTimeUnit(objData.endTime.iMinute);
                    objReturn.sEndDate += ` ${sEndHour}:${sEndMinute}`;
                }
            }

            return objReturn;
        },

        _formatTimeUnit: function (iUnit) {
            return iUnit < 10 ? "0" + iUnit : "" + iUnit;
        },

        _updateTotal: function () {
            var objValue = this.get("mValue");
            var sTotal = "";

            if (!a24Core.isEmpty(objValue.sStartDate) && !a24Core.isEmpty(objValue.sEndDate)) {
                var iDiff = momentHelper.stringDiffInUnits(objValue.sEndDate, this.get("_objLibConstants.DATE_TIME_FORMAT"), objValue.sStartDate, this.get("_objLibConstants.DATE_TIME_FORMAT"), this.get("_objLibConstants.MOMENT_TIME_HOURS"), true);

                var iHour = parseInt(iDiff);
                var iMinute = Math.round(60 * (iDiff - iHour));

                if (iHour > 0) {
                    sTotal += `${iHour}H`;
                }
                if (iMinute > 0) {
                    if (!a24Core.isEmpty(sTotal)) {
                        sTotal += " ";
                    }
                    sTotal += `${iMinute}Min`;
                }
            }

            if (a24Core.isEmpty(sTotal)) {
                sTotal = this.get("_objFormInputsStringsService").getString("total");
            }

            this._setTotal(sTotal);
        },

        _setTotal: function (sTotal) {
            this.set("_sTotal", sTotal);
        }
    });
});
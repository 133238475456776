define("a24-ember-lib/helpers/card-datagrid-table-get-key", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cardDatagridTableGetKey = cardDatagridTableGetKey;


    /**
     * This function will return the key path for value of a table cell
     *
     * @param arrParams - The array of params sent to the helper
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since 22 October 2018
     */
    function cardDatagridTableGetKey([objHeader]) {
        var sProperty = objHeader.sProperty;

        sProperty = sProperty.replace("___", ".0.").replace("__", ".");

        if (objHeader.bHTML) {
            sProperty = sProperty + "HTML";
        }

        return sProperty;
    }

    exports.default = Ember.Helper.helper(cardDatagridTableGetKey);
});
define("a24-ember-staffshift-core/services/rest-entity-action-shift", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sEntityActionApiBaseUrl: Ember.computed.readOnly("objEnv.sEntityActionApiBaseUrl"),

        objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Get shifts entity Actions
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQuery - The query params object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  07 April 2020
         */
        getShiftsEntityActions: function (objContext, sProperty, objQuery, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/entity-action/shift");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sEntityActionApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});
define("a24-ember-title-bar/components/title-bar", ["exports", "a24-ember-title-bar/templates/components/title-bar", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-window/mixins/a24responsive"], function (exports, _titleBar, _didRenderChangesMixin, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _a24responsive.default, {
        /*
         * Default values
        */
        layout: _titleBar.default,
        classNames: ["title-bar"],

        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objLibStrings: Ember.inject.service("a24-strings-ember-lib"),
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        _objNavigationService: Ember.inject.service("navigation"),
        _bShowDropdown: false,
        _bShowMore: false,
        _sIFrameUrl: null,
        _sCurrentBaseRoute: "",

        objTitleBarService: Ember.inject.service("title-bar"),
        arrTabs: null,

        init: function () {
            this._super(...arguments);
            this.get("_objNavigationService");

            let objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);
            }
        },

        arrHiddenTabs: Ember.computed("objTitleBarService.arrTabs.@each.bActive", function () {
            var arrHiddenTabs = Ember.copy(this.get("objTitleBarService.arrTabs"), true);
            if (!a24Core.isEmpty(arrHiddenTabs)) {
                arrHiddenTabs.push({
                    iIndex: arrHiddenTabs.length,
                    bActive: false,
                    sText: this.get("_objLibStrings").getHTMLTokenString("moreWithIcon"),
                    bMore: true
                });
            }
            Ember.run.once(this, "_setTabsForViewMore");
            return arrHiddenTabs;
        }),

        _bShowReacTabs: Ember.computed("objTitleBarService.objReactTabsPostMessage", function () {
            return !a24Core.isEmpty(this.get("objTitleBarService.objReactTabsPostMessage"));
        }),

        _setTabsForViewMore: function () {
            if (a24Core.isEmpty(this.get("objTitleBarService.arrTabs"))) {
                this.set("arrTabs", []);
                return;
            }
            var arrTabs = this.get("objTitleBarService.arrTabs");
            var objCurrentHiddenItem = null;
            var iPosition = 0;
            var arrNewTabs = [];
            var arrOverflowTabs = [];

            var objMoreItem = this.$(".title-bar-tabs-table-hidden table tr .title-bar-tab").last();
            var iScrollWidth = this.$(".title-bar-tabs-table-hidden").prop("scrollWidth") - objMoreItem.width();
            if (iScrollWidth > this.$(".title-bar-tabs-table").width()) {
                for (var i = 0; i < arrTabs.length; i++) {
                    objCurrentHiddenItem = this.$(".title-bar-tabs-table-hidden table tr .title-bar-tab").eq(i);
                    var iCurrentHiddenItemLength = objCurrentHiddenItem.position().left + objCurrentHiddenItem.width();
                    // Added 48 here to give it grace for the more tab as the tab might fit in barely
                    if (iCurrentHiddenItemLength + objMoreItem.width() + 48 <= this.$(".title-bar-tabs-table").width()) {
                        iPosition = i;
                    }
                }

                arrNewTabs = arrTabs.slice(0, iPosition + 1);
                arrOverflowTabs = arrTabs.slice(iPosition + 1);
                var bActive = false;
                for (var k = 0; k < arrOverflowTabs.length; k++) {
                    if (arrOverflowTabs[k].bActive) {
                        bActive = true;
                        break;
                    }
                }
                arrNewTabs.push({
                    iIndex: arrNewTabs.length,
                    bActive: bActive,
                    sText: this.get("_objLibStrings").getHTMLTokenString("moreWithIcon"),
                    bMore: true
                });

                this.set("_bShowMore", true);

                this.set("objTitleBarService.arrOverflowTabs", arrOverflowTabs);
            } else {
                this.set("_bShowMore", false);
                this.set("_bShowDropdown", false);
                arrNewTabs = this.get("objTitleBarService.arrTabs");
            }

            if (a24Core.isEmpty(this.get("arrTabs")) && !a24Core.isEmpty(arrNewTabs)) {
                this.set("arrTabs", arrNewTabs);
            } else {
                var bIsSame = this._isTabsSame(this.get("arrTabs"), arrNewTabs);
                if (!bIsSame) {
                    this.set("arrTabs", arrNewTabs);
                }
            }

            if (a24Core.isEmpty(this.get("arrOverflowTabs")) && !a24Core.isEmpty(arrOverflowTabs)) {
                this.set("arrOverflowTabs", arrOverflowTabs);
            } else if (!a24Core.isEmpty(this.get("arrOverflowTabs")) && a24Core.isEmpty(arrOverflowTabs)) {
                this.set("arrOverflowTabs", arrOverflowTabs);
            } else {
                var bOverFlowIsSame = this._isTabsSame(this.get("arrOverflowTabs"), arrOverflowTabs);
                if (!bOverFlowIsSame) {
                    this.set("arrOverflowTabs", arrOverflowTabs);
                }
            }
        },

        _isTabsSame: function (arrTabCurrent, arrTabNew) {
            var bIsSame = true;
            if (a24Core.isEmpty(arrTabCurrent) && a24Core.isEmpty(arrTabNew)) {
                return bIsSame;
            }
            if (arrTabCurrent.length != arrTabNew.length) {
                bIsSame = false;
            } else {
                for (var l = 0; l < arrTabCurrent.length; l++) {
                    if (JSON.stringify(arrTabCurrent[l]) !== JSON.stringify(arrTabNew[l])) {
                        bIsSame = false;
                        break;
                    }
                }
            }
            return bIsSame;
        },

        _funcGetCurrentBaseRoute: function () {
            var sLongestRoute = "";
            var arrRouteKeys = Object.keys(this.get("_objNavigationService.objRouteParams"));
            for (let i = 0; i < arrRouteKeys.length; i++) {
                if (arrRouteKeys[i].length > sLongestRoute.length) {
                    sLongestRoute = arrRouteKeys[i];
                }
            }

            let arrRoutesPart = sLongestRoute.split(".");

            // This works under the assumption that tabs will always be on 3rd level routes with tab route params being
            // the 4th level.
            // Thus the parent route of all the tabs will be the second level route.
            if (arrRoutesPart.length === 1) {
                return arrRoutesPart[0];
            } else {
                return arrRoutesPart[0] + "." + arrRoutesPart[1];
            }
        },

        _observeRenderCount: Ember.observer("_objDidRenderService._iCountRender", function () {
            if (!a24Core.isEmpty(this.get("objTitleBarService.arrTabs"))) {
                Ember.run.once(this, "_setTabsForViewMore");
            }
        }),

        _onRouteChange: Ember.observer("_objNavigationService.objRouteParams", function () {
            if (!a24Core.isEmpty(this.get("objTitleBarService.objReactTabsPostMessage"))) {
                if (this.get("_sCurrentBaseRoute") === this._funcGetCurrentBaseRoute()) {
                    this.set("objTitleBarService.objReactTabsPostMessage", {
                        type: "tab_route_info",
                        data: Ember.copy(this.get("_objNavigationService.objRouteParams"), true)
                    });
                } else {
                    this.set("_sCurrentBaseRoute", "");
                }
            } else {
                this.set("_sCurrentBaseRoute", "");
            }
        }),

        actions: {
            onBack: function () {
                if (!this.get("objTitleBarService.bBackDisabled")) {
                    var funcOnBack = this.get("objTitleBarService.onBack");
                    if (!a24Core.isEmpty(funcOnBack)) {
                        funcOnBack();
                    }
                }
            },
            executeMoreDropdownAction: function (objOverflowTab) {
                this.get("objTitleBarService").setCurrentSelectedTab(objOverflowTab, this.get("arrTabs"));
                this.set("_bShowDropdown", false);
            },
            onTabClick: function (objTab, objEvent) {
                if (objEvent.ctrlKey || objEvent.metaKey) {
                    //this means the user wants to open in new tab so we let the a link handle it.
                    return;
                }

                //Always prevent default here becasue click needs to be handled manually. The a link is only there
                //to support opening in new tab, normal click navigation should go through the navigate service and in most
                //caseses not create browser history.
                objEvent.preventDefault();

                if (!a24Core.isEmpty(objTab.bMore) || objTab.bMore === true) {
                    this.set("_bShowDropdown", true);
                } else {
                    this.get("objTitleBarService").setCurrentSelectedTab(objTab, this.get("arrTabs"));
                }
            },
            onRecieveData: function (objData) {
                if (objData.type === "component_ready") {
                    this.set("_sCurrentBaseRoute", this._funcGetCurrentBaseRoute());
                    this.set("objTitleBarService.objReactTabsPostMessage", {
                        type: "tab_route_info",
                        data: Ember.copy(this.get("_objNavigationService.objRouteParams"), true)
                    });
                }

                // Components can observe this to handle messages from the react frame if needed
                this.set("_objNavigationService.objReactTabsReceiveMessage", objData);
            }
        }
    });
});
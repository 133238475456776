define("a24-ember-staffshift-core/components/page-react-easter-egg-item", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/page-react-easter-egg-item", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageReactEasterEggItem, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageReactEasterEggItem.default,
        bShowInitFields: true,
        _sFuncName: "",
        _bShowPopup: false,
        _bPopupLoading: true,
        _objFrameData: null,
        _sUrlStart: "",
        init: function () {
            this._super(...arguments);
            var env = Ember.getOwner(this).resolveRegistration("config:environment");

            this.set("_sUrlStart", env.sReactGatewayUrl);

            let sFuncName = "reactEasterPost" + Ember.guidFor({});
            this.set("_sFuncName", sFuncName);
            window[sFuncName] = objData => {
                this.set("_bShowPopup", true);
                this.set("_objFrameData.data", objData);
            };
            // eslint-disable-next-line no-console
            console.log("EMBER " + sFuncName);
        },
        willDestroyElement: function () {
            this._super(...arguments);
            delete window[this.get("_sFuncName")];
        },
        onScreenChange: Ember.observer("bShowInitFields", function () {
            if (!this.get("bShowInitFields") && !a24Core.isEmpty(this.get("_objFrameData.data"))) {
                this.set("_bShowPopup", true);
            }
        }),
        onInputValueChanged: function () {
            var objData = this.getPlainFormValueObject();
            eval("window.reactEasterPostData = " + objData.data + ";");
            objData.data = window.reactEasterPostData;
            objData.url = objData.url.replace("http:", "").replace("https:", "");
            if (objData.url.charAt(objData.url.length - 1) === "/") {
                objData.url = objData.url.substring(0, objData.url.length - 1);
            }
            window.reactEasterPostData = null;
            this.set("_sUrlStart", objData.url);
            this.set("_objFrameData", objData);
        },
        actions: {
            onRecieveDataReactPopup: function (objData) {
                // eslint-disable-next-line no-console
                console.log("EMBER Receive Data From React Popup", objData);
                if (!a24Core.isEmpty(objData)) {
                    if (objData.type === "app_ready") {
                        this.set("_bPopupLoading", false);
                    } else if (objData.type === "close") {
                        this.set("_bShowPopup", false);
                    }
                }
            },
            onRecieveDataReactPage: function (objData) {
                // eslint-disable-next-line no-console
                console.log("EMBER Receive Data From React Page", objData);
            }
        }
    });
});
define("a24-ember-lib/components/dropdown-content", ["exports", "a24-ember-lib/templates/components/dropdown-content", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _dropdownContent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _dropdownContent.default,
        classNames: ["a24-dropdown-content"],
        sDropdownContentId: null,
        bShow: false,
        bAutoShow: true,
        bShowOnHover: false,
        sAlign: "left", //only left and right for now
        sDropdownClass: "",
        _bHovered: false,
        _bClicked: false,
        init: function () {
            this._super(...arguments);

            if (this.get("sAlign") !== "left" && this.get("sAlign") !== "right") {
                this.set("sAlign", "left");
            }
        },

        didInsertElement: function () {
            this._super(...arguments);

            if (this.get("bAutoShow")) {
                this.$("#" + this.get("sDropdownContentId")).on("a24DropdownClick", () => {
                    // Register custom click event
                    this.onClickAction();
                }).on("a24DropdownEnter", () => {
                    // Register custom mouse enter event
                    this.onEnterAction();
                }).on("a24DropdownLeave", () => {
                    // Register custom mouse leave event
                    this.onLeaveAction();
                }).on("mouseout", () => {
                    // Register standard mouse leave event
                    this.onLeaveAction();
                });
            }
        },
        onClickAction: function () {
            if (this.get("bAutoShow")) {
                this.toggleProperty("_bClicked");
                this.set("_bHovered", false);
            }
        },
        onEnterAction: function () {
            if (this.get("bAutoShow") && this.get("bShowOnHover") && !this.get("_bClicked")) {
                this.set("_bHovered", true);
            }
        },
        onLeaveAction: function () {
            if (this.get("bAutoShow") && this.get("bShowOnHover") && !this.get("_bClicked")) {
                // Do a custom check to make sure that if a user hovers over this item, that the trigger leave
                // event does not fire a close on this.
                if (!$("#" + this.get("sDropdownContentId")).is(":hover")) {
                    this.set("_bHovered", false);
                }
            }
        },
        onClickHoverChange: Ember.observer("_bHovered", "_bClicked", function () {
            this.set("bShow", this.get("_bHovered") || this.get("_bClicked"));
        }),
        _bShowBackdrop: Ember.computed("_bClicked", "bShow", function () {
            return this.get("bShow") && !this.get("_bHovered");
        }),
        _onShowChange: Ember.observer("bShow", function () {
            if (!this.get("bShow")) {
                this.set("_bClicked", false);
            }
        }),
        actions: {
            onBackdropClick: function () {
                if (this.get("bAutoShow")) {
                    this.set("_bHovered", false);
                    this.set("_bClicked", false);
                    this.set("bShow", false);
                } else {
                    if (!a24Core.isEmpty(this.get("a24DropdownBlockerClicked"))) {
                        return this.get("a24DropdownBlockerClicked")(this.get("sDropdownContentId"));
                    }
                }
            }
        }
    });
});
define("a24-ember-staffshift-core/components/reset-password-popup-component", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/reset-password-popup-component"], function (exports, _inputFormElement, _didRenderChangesMixin, _resetPasswordPopupComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _resetPasswordPopupComponent.default,
        sUsernameEmail: "",
        bShowPopup: false,
        _bLoading: false,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserService: Ember.inject.service("rest-user"),
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSpecialForgotPasswordPopupAction: null,
        init: function () {
            this._super(...arguments);
            this.set("objChildConfig", {
                "objChildren._sUsernameEmail": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 100);
                }
            });

            this.set("_objSpecialForgotPasswordPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    if (!a24Core.isEmpty(this.get("onForgotPasswordClose"))) {
                        this.get("onForgotPasswordClose")();
                    }
                }
            });

            this.set("_bLoading", false);
        },
        _onShowPopup: Ember.observer("bShowPopup", function () {
            this.get("objChildren._sUsernameEmail").setValueFromOutside(this.get("sUsernameEmail"));
        }),
        isValidEmail: function (sEmail) {
            var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(sEmail);
        },
        actions: {
            onPopupForgotPasswordCancel: function () {
                if (!this.get("_bLoading")) {
                    if (!a24Core.isEmpty(this.get("onForgotPasswordClose"))) {
                        this.get("onForgotPasswordClose")();
                    }
                }
            },

            onPopupForgotPasswordConfirm: function () {
                // validate
                if (!this.doFullValidation()) {
                    // determine if username or email
                    var objData = this.getPlainFormValueObject();
                    var sUsernameEmail = objData._sUsernameEmail.trim();
                    var sUsername = null;
                    var sEmail = null;
                    if (this.isValidEmail(sUsernameEmail)) {
                        sEmail = sUsernameEmail;
                    } else {
                        sUsername = sUsernameEmail;
                    }

                    this.set("_bLoading", true);

                    // call service to reset password
                    this.get("_objUserService").resetPassword(this, sUsername, sEmail, () => {
                        // reset password was a success
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStaffshiftCoreStringsService").getString("resetPasswordEmailSent"));
                        this.set("_bLoading", false);
                        if (!a24Core.isEmpty(this.get("onForgotPasswordClose"))) {
                            this.get("onForgotPasswordClose")();
                        }
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], (sCode /*,sStatus, objErrorThrown, objError*/) => {
                        window.dataLayer = window.dataLayer || [];

                        // Show failure message
                        if (sCode === "DOES_NOT_EXIST") {
                            window.dataLayer.push({
                                "event": "forgot-password-user-does-not-exist"
                            });

                            this.setResponseError(["_sUsernameEmail"], "emailNotInDatabase", false, false, this.get("_objStaffshiftCoreStringsService"));
                        } else if (sCode === "REGEX_NAME_NO_MATCH") {
                            window.dataLayer.push({
                                "event": "forgot-password-username-invalid-chars"
                            });

                            this.setResponseError(["_sUsernameEmail"], "validationRestInvalidChar");
                        } else if (sCode === "STRING_TOO_LONG") {
                            window.dataLayer.push({
                                "event": "forgot-password-username-too-long"
                            });

                            this.setResponseError(["_sUsernameEmail"], "validationRestMaxLength");
                        } else if (sCode === "USER_NOT_ACTIVE") {
                            window.dataLayer.push({
                                "event": "forgot-password-inactive-user"
                            });

                            this.setResponseError(["_sUsernameEmail"], this.get("_objStaffshiftCoreStringsService").getString("userNotActive"), true, true);
                        } else {
                            this.setResponseError(["_sUsernameEmail"], this.get("_objStringsService").getString("serverError"), true, true);
                        }
                        this.set("_bLoading", false);
                    }));
                }
            }
        }
    });
});
define("a24-ember-lib/components/export-popup-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/export-popup-component"], function (exports, _didRenderChangesMixin, _exportPopupComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _exportPopupComponent.default,
        bLoading: true,
        bShow: true,
        objDatagridConfig: null,
        sQuery: null,
        sExportRef: null,
        objExportService: null,
        _bDataWarning: false,
        _bTooManyResults: false,
        onExportPopupCloseAction: null,
        onExportPopupLoadingStateChangeAction: null,
        _objEmberLibStringsService: Ember.inject.service("a24-strings-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objExportPopupSpecialAction: null,
        _objExportServicePromise: null,
        init: function () {
            this._super(...arguments);

            this.set("_objExportPopupSpecialAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this._closeExportPopup();
                }
            });

            var objThis = this;
            if (!a24Core.isEmpty(this.get("objDatagridConfig"))) {
                if (!a24Core.isEmpty(this.get("objDatagridConfig.funcResultCountManyClicked"))) {
                    var objResultCountFunctions = this.get("objDatagridConfig.funcResultCountManyClicked")();
                    this.set("objDatagridConfig.funcResultCountManyClicked", () => {
                        return {
                            funcSuccess: () => {
                                if (!a24Core.isEmpty(objThis.get("onExportPopupLoadingStateChangeAction"))) {
                                    objThis.get("onExportPopupLoadingStateChangeAction")(false);
                                }
                                objResultCountFunctions.funcSuccess();
                            },
                            funcFailure: () => {
                                if (!a24Core.isEmpty(objThis.get("onExportPopupLoadingStateChangeAction"))) {
                                    objThis.get("onExportPopupLoadingStateChangeAction")(false);
                                }

                                objThis._closeExportPopup();
                                objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objEmberLibStringsService").getString("somethingWentWrongTryAgain"));
                                objResultCountFunctions.funcFailure();
                            }
                        };
                    });
                } else {
                    this.set("objDatagridConfig.funcResultCountManyClicked", () => {
                        return {
                            funcSuccess: () => {
                                if (!a24Core.isEmpty(objThis.get("onExportPopupLoadingStateChangeAction"))) {
                                    objThis.get("onExportPopupLoadingStateChangeAction")(false);
                                }
                            },
                            funcFailure: () => {
                                if (!a24Core.isEmpty(objThis.get("onExportPopupLoadingStateChangeAction"))) {
                                    objThis.get("onExportPopupLoadingStateChangeAction")(false);
                                }

                                objThis._closeExportPopup();
                                objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objEmberLibStringsService").getString("somethingWentWrongTryAgain"));
                            }
                        };
                    });
                }
            }
        },
        onShow: Ember.observer("bShow", function () {
            if (this.get("bShow")) {
                if (!a24Core.isEmpty(this.get("objDatagridConfig.funcTriggerResultCountManyClick"))) {
                    Ember.run.scheduleOnce("afterRender", () => {
                        this.get("objDatagridConfig.funcTriggerResultCountManyClick")();
                    });
                }
            }
        }),
        onDataChange: Ember.observer("bLoading", function () {

            if (!this.get("bLoading")) {
                this.set("_bTooManyResults", false);
                this.set("_bDataWarning", false);
                if (this.get("objDatagridConfig").iResultCount > 63999) {
                    // do not allow export
                    this.set("_bTooManyResults", true);
                } else if (this.get("objDatagridConfig").iResultCount > 500) {
                    // display warning
                    this.set("_bDataWarning", true);
                }
            }
        }),
        _closeExportPopup: function () {
            if (!a24Core.isEmpty(this.get("onExportPopupCloseAction"))) {
                this.get("onExportPopupCloseAction")();
            }
        },
        actions: {
            onExportPopupCancel: function () {
                this._closeExportPopup();
            },
            onExportPopupConfirm: function () {
                if (!this.get("_bTooManyResults")) {
                    var objQuery = JSON.parse(this.get("sQuery")).query;
                    var objHeaders = {};
                    var arrSort = [];
                    var objProjectConfig = {};
                    var arrDataGridHeaderConfig = this.get("objDatagridConfig").arrHeaderConfig;
                    var objPermanentQuery = this.get("objDatagridConfig").objPermanentQuery;
                    var objLastIndex = {};
                    arrDataGridHeaderConfig.forEach(objItem => {
                        if (!a24Core.isEmpty(objItem.sExportPostColumn) && objItem.bShowHeader && !objItem.bSilent) {
                            objLastIndex[objItem.sExportPostColumn] = objItem.iUniqueColNum;
                        }
                    });
                    var bHasHeaders = false;
                    arrDataGridHeaderConfig.forEach(objHeaderItem => {
                        if (objHeaderItem.bShowHeader && !objHeaderItem.bSilent) {
                            bHasHeaders = true;
                            if (!a24Core.isEmpty(objHeaderItem.sExportPreColumn) && !objHeaders.hasOwnProperty(objHeaderItem.sExportPreColumn)) {
                                objHeaders[objHeaderItem.sExportPreColumn] = 1;
                                objProjectConfig[objHeaderItem.sExportPreColumn] = {
                                    "column_name": objHeaderItem.sExportPreColumnName
                                };
                            }

                            if (!a24Core.isEmpty(objHeaderItem.sExportColumn)) {
                                objHeaders[objHeaderItem.sExportColumn] = 1;
                            } else {
                                objHeaders[objHeaderItem.sProperty] = 1;
                            }

                            if (!a24Core.isEmpty(objHeaderItem.sExportColumnName)) {
                                objProjectConfig[!a24Core.isEmpty(objHeaderItem.sExportColumn) ? objHeaderItem.sExportColumn : objHeaderItem.sProperty] = {
                                    "column_name": objHeaderItem.sExportColumnName
                                };
                            } else {
                                objProjectConfig[!a24Core.isEmpty(objHeaderItem.sExportColumn) ? objHeaderItem.sExportColumn : objHeaderItem.sProperty] = {
                                    "column_name": objHeaderItem.sTitle
                                };
                            }

                            if (!a24Core.isEmpty(objHeaderItem.sExportPostColumn) && objHeaderItem.iUniqueColNum === objLastIndex[objHeaderItem.sExportPostColumn]) {
                                objHeaders[objHeaderItem.sExportPostColumn] = 1;
                                if (!objProjectConfig.hasOwnProperty(objHeaderItem.sExportPostColumn)) {
                                    objProjectConfig[objHeaderItem.sExportPostColumn] = {
                                        "column_name": objHeaderItem.sExportPostColumnName
                                    };
                                }
                            }
                        }

                        let sProperty = objHeaderItem.sProperty;
                        if (!a24Core.isEmpty(objHeaderItem.sAsc)) {
                            if (objHeaderItem.bAsc) {
                                arrSort.push(sProperty);
                            } else {
                                arrSort.push("-" + sProperty);
                            }
                        }

                        if (!a24Core.isEmpty(objPermanentQuery) && !a24Core.isEmpty(objPermanentQuery[sProperty]) && objPermanentQuery[sProperty].bSortBy) {
                            if (objPermanentQuery[sProperty].mValue === "asc") {
                                arrSort.push(sProperty);
                            } else {
                                arrSort.push("-" + sProperty);
                            }
                        }
                    });

                    if (bHasHeaders) {
                        var objExportData = {
                            "query": objQuery,
                            "sort": arrSort,
                            "project": objHeaders,
                            "project_config": objProjectConfig
                        };

                        if (!a24Core.isEmpty(this.get("onExportPopupLoadingStateChangeAction"))) {
                            this.get("onExportPopupLoadingStateChangeAction")(true);
                        }

                        this.get("objExportService").exportData(this, "_objExportServicePromise", this.get("sExportRef"), objExportData, arrResponse => {
                            if (!a24Core.isEmpty(this.get("onExportPopupLoadingStateChangeAction"))) {
                                this.get("onExportPopupLoadingStateChangeAction")(false);
                            }
                            if (!a24Core.isEmpty(arrResponse)) {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objEmberLibStringsService").getString("dataHasBeenSent"));

                                if (!a24Core.isEmpty(this.get("onExportPopupCloseAction"))) {
                                    this.get("onExportPopupCloseAction")();
                                }
                            } else {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("serverError"));
                            }
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode /*, sStatus, objErrorThrown, objError*/) => {
                            if (sCode === "401") {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objEmberLibStringsService").getString("requiredPermission"));
                            } else {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("serverError"));
                            }
                            if (!a24Core.isEmpty(this.get("onExportPopupLoadingStateChangeAction"))) {
                                this.get("onExportPopupLoadingStateChangeAction")(false);
                            }
                        }));
                    }
                }
            }
        }

    });
});
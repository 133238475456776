define("a24-ember-staffshift-core/components/status-error-page", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/status-error-page"], function (exports, _didRenderChangesMixin, _statusErrorPage) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _statusErrorPage.default,
        classNames: ["status-error-page"],
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _bMobile: false,
        _iMobileWidth: 800,
        _iMobileHeight: 500,
        init: function () {
            this._super(...arguments);
            this.onWindowResize();
        },
        willDestroyElement: function () {
            this._super(...arguments);
        },
        onWindowResize: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            var iPageWidth = this.get("_objDomService.iPageWidth");
            var iPageHeight = this.get("_objDomService.iPageHeight");

            if (iPageWidth < this.get("_iMobileWidth") || iPageHeight < this.get("_iMobileHeight")) {
                this.set("_bMobile", true);
            } else {
                this.set("_bMobile", false);
            }
        })
    });
});
define("a24-ember-policy-library/components/policy-details", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/policy-details"], function (exports, _inputFormElement, _didRenderChangesMixin, _policyDetails) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _policyDetails.default,
        arrPolicyVersionData: null,
        bPolicyVersionsLoading: true,
        sPolicyTypeRestService: "rest-agency-group-policy-type",

        _arrPolicyData: null,
        _bLoading: false,
        _arrHeaderActions: null,
        _bShowUnsavedData: false,
        _bEditPopupShow: false,
        _bEditPopupLoading: false,
        _objSelectedClassification: null,
        _bRegistration: false,
        _bPublic: false,
        _objRestAgencyGroupPolicyProperty: null,
        _objPolicyTypeRestService: Ember.computed("sPolicyTypeRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyTypeRestService"));
        }),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objPolicyEnumsService: Ember.inject.service("a24-enums-policy-library"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _arrClassificationDropdown: null,
        _objOriginalData: null,
        _bClassificationVisible: true,
        _objSpecialEditPopupAction: null,
        _sTitle: null,
        init: function () {
            this._super(...arguments);

            this.setInitialDropdownPopupData();

            this.set("objChildConfig", {
                "objChildren.title": objMixin => {
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 255);
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.description": objMixin => {
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 1000);
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.classification": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this.send("onPopupEditCancel");
                }
            });

            this.set("_bLoading", true);
        },
        setClassificationVisibility: Ember.observer("bPolicyVersionsLoading", function () {
            if (!a24Core.isEmpty(this.get("arrPolicyVersionData"))) {
                this.set("_bClassificationVisible", false);
            } else {
                this.set("_bClassificationVisible", true);
            }

            this.doServiceCall();
        }),
        doServiceCall: function () {
            this.set("_bLoading", true);

            var sPolicyTypeId = this.get("_objPolicyTypeRestService").getPolicyTypeViewId();
            //sPolicyTypeId read from route params that will be null during transition
            if (a24Core.isEmpty(sPolicyTypeId)) {
                return;
            }

            this.get("_objPolicyTypeRestService").getPolicyType(this, "_objRestAgencyGroupPolicyProperty", this.get("_objUserSession").getUserContextReference().mId, sPolicyTypeId, objResponse => {
                this.setPolicyDetails(objResponse);

                if (!a24Core.isEmpty(this.get("breadcrumbTitleEvent"))) {
                    this.get("breadcrumbTitleEvent")(objResponse.title);
                }

                this.set("_sTitle", objResponse.title);
                this.set("_bLoading", false);
            });
        },
        setInitialDropdownPopupData() {
            this.set("_arrClassificationDropdown", this.get("_objPolicyEnumsService").getList("classification"));
        },
        setPolicyDetails: function (objData) {
            if (!a24Core.isEmpty(objData.options)) {
                if (a24Core.isEmpty(objData.options.public_policy)) {
                    objData.options.public_policy = false;
                }

                if (a24Core.isEmpty(objData.options.registration_policy)) {
                    objData.options.registration_policy = false;
                }
            } else {
                objData.options = {
                    public_policy: false,
                    registration_policy: false
                };
            }

            this.set("_arrPolicyData", [{
                arrItems: [{
                    sLabel: this.get("_objStringsService").getString("title"),
                    sValue: objData.title,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("description"),
                    sValue: objData.description,
                    bText: true
                }, {
                    sLabel: this.get("_objPolicyStringsService").getString("classification"),
                    sValue: this.get("_objPolicyEnumsService").getFrontendValue("classification", objData.classification),
                    bText: true
                }, {
                    sLabel: this.get("_objPolicyStringsService").getString("public"),
                    sValue: objData.options.public_policy ? this.get("_objStringsService").getString("yes") : this.get("_objStringsService").getString("no"),
                    bText: true
                }, {
                    sLabel: this.get("_objPolicyStringsService").getString("registration"),
                    sValue: objData.options.registration_policy ? this.get("_objStringsService").getString("yes") : this.get("_objStringsService").getString("no"),
                    bText: true
                }]
            }]);
            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "mode_edit",
                executeCardAction: () => {
                    Ember.run.next(() => {
                        this.get("objChildren.title").setValueFromOutside(objData.title);
                        this.get("objChildren.description").setValueFromOutside(objData.description);
                        this.set("_objSelectedClassification", {
                            "sValue": objData.classification,
                            "sLabel": this.get("_objPolicyEnumsService").getFrontendValue("classification", objData.classification)
                        });

                        if (!a24Core.isEmpty(objData.options)) {
                            this.set("_bRegistration", objData.options.registration_policy);
                            this.set("_bPublic", objData.options.public_policy);
                        }

                        this.set("_bEditPopupLoading", false);
                        this.set("_bEditPopupShow", true);

                        this.clearAllErrorMessages();

                        Ember.run.next(() => {
                            Ember.run.schedule("afterRender", () => {
                                // reset breadcrumb
                                if (!a24Core.isEmpty(this.get("breadcrumbTitleEvent"))) {
                                    this.get("breadcrumbTitleEvent")(this.get("_sTitle"));
                                }

                                this.set("_objOriginalData", this.getPlainFormValueObject());
                            });
                        });
                    });
                }
            }]);
        },
        actions: {
            onPopupEditConfirm: function () {
                if (!this.doFullValidation()) {
                    var objData = this.getPlainFormValueObject();
                    if (a24Core.isEmpty(objData.classification)) {
                        objData.classification = this.get("_objSelectedClassification").sValue;
                    }
                    this.set("_bEditPopupLoading", true);
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("updatingPolicyDetails"));
                    var sPolicyTypeId = this.get("_objPolicyTypeRestService").getPolicyTypeViewId();
                    //sPolicyTypeId read from route params that will be null during transition
                    if (a24Core.isEmpty(sPolicyTypeId)) {
                        return;
                    }

                    this.get("_objPolicyTypeRestService").updatePolicyType(this, "_objRestAgencyGroupPolicyProperty", this.get("_objUserSession").getUserContextReference().mId, sPolicyTypeId, () => {
                        this.doServiceCall();
                        this.set("_bEditPopupShow", false);
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("updatingPolicySuccess"));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                        if (sCode === "MIN_LENGTH") {
                            this.setResponseError(objError.arrPath, "validationRestMinLength");
                        } else if (sCode === "MAX_LENGTH") {
                            this.setResponseError(objError.arrPath, "validationRestMaxLength");
                        } else if (sCode === "PATTERN") {
                            this.setResponseError(objError.arrPath, "validationRestInvalidChar");
                        } else if (sCode === "POLICY_TYPE_ALREADY_IN_USE") {
                            this.setResponseError(["classification"], "updatingPolicyTypeAlreadyInUse", true, false, this.get("_objPolicyStringsService"));
                        } else {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("updatingPolicyFailed"));
                        }
                        this.set("_bEditPopupLoading", false);
                    }), objData);
                }
            },
            onPopupEditCancel: function () {

                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bEditPopupShow", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupUnsavedConfirm: function () {
                this.set("_bEditPopupShow", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});
define("a24-ember-staffshift-core/routes/abstractReactRoute", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({

        activate: function () {
            this._super(...arguments);
            Ember.run.schedule("afterRender", () => {
                $(".reactTabEmberPage").addClass("reactTabEmberPageSpacing");
            });
        },
        deactivate: function () {
            $(".reactTabEmberPage").removeClass("reactTabEmberPageSpacing");
            this._super(...arguments);
        }
    });
});
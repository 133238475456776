define("a24-ember-staffshift-core/services/a24-strings-staffshift-core", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function () {
            this._super(...arguments);
            this.set("_objStrings", {

                //===== STRINGS START =====
                keywords: "Keywords",
                open: "Open",
                closed: "Closed",
                closing: "Closing",
                orphan: "Orphan",
                closedDate: "Closed Date",
                participants: "Participants",
                team: "Team",
                conversations: "Conversations",
                current: "Current",
                archived: "Archived",
                reload: "Reload",
                goToConversation: "Go to Conversation",
                conversationPreview: "Conversation Preview",
                openConversationPreview: "Open Conversation Preview",
                failedToLoadConversationPreview: "Failed to load Conversation Preview",

                notificationPreview: "Notification Preview",

                statusReason: "Status Reason",

                candidate: "Candidate",
                agency: "Agency",
                agencyGroup: "Agency Group",
                organisation: "Organisation",
                systemAdmin: "System Admin",

                contextWrongIp: "Your not allowed to access this context at this time. Please try using a different context.",

                incorrectLoginDetails: "Either the Email or Password entered was incorrect",
                wantsAccess: " wants to get information from your Staffshift Account",
                welcomeMessage: "Please select an item from the menu on the left to get started",
                supportChat: "Support Chat",
                forgotYourPassword: "Forgot Your Password?",
                bySubmittingThisForm: "By submitting this form, you will receive an email to reset your password.<br/>To complete the process you will need to follow the instructions set out in the email.",
                emailNotInDatabase: "The email address/username you have entered does not appear in our database, please enter the correct email address or username.",
                resetPasswordEmailSent: "An email to reset your password has been sent",
                usernameEmail: "Username/Email",
                resetPassword: "Reset Password",
                signIn: "Sign In",
                submit: "Submit",

                inbox: "Inbox",

                openInNewTab: "Open in new tab",
                viewCandidate: "View Candidate",
                openCalendarInNewTab: "Open calendar in new tab",
                viewCandidateCalendar: "View Calendar",

                candidateSearch: "Candidate Search",
                type: "Type",
                fullName: "Full Name",
                userNotActive: "We do not have an active profile for this email address. Please contact us if you need further help.",
                lastShiftDate: "Last Shift Date",
                nextShiftDate: "Next Shift Date",
                lastLoggedIn: "Last Logged In",

                belongsToSite: "Belongs to Site",
                andWardName: "And Ward Name is",
                sessionData: "Session Data",
                techData: "Tech Data",
                userData: "User Data",
                timeOnPage: "Time on page",
                device: "Device",
                browser: "Browser",
                version: "Version",
                screen: "Screen",
                os: "OS",
                context: "Context",
                currentTime: "Current time",
                locale: "Locale",
                timezone: "Timezone",
                timezoneOffset: "Timezone Offset",
                currentPage: "Current page",
                supportsVideoCalls: "Supports Video Calls",
                page: "Page",
                url: "URL",
                user: "User",
                username: "Username",
                emailVerified: "Email verified",
                lastEmailVerifySent: "Verify email last sent",
                needAnyHelp: "Need any help? Just click here!",

                new: "New",
                accepted: "Accepted",
                cancelled: "Cancelled",
                reopened: "Reopened",

                sms: "SMS",
                whatsapp: "WhatsApp",
                notificationType: "Notification Type",
                sendDate: "Send Date",
                recipientName: "Recipient Name",
                recipientNumber: "Recipient Number",
                senderNumber: "Sender Number",

                noNotificationTypeSupport: "Notification type preview not supported yet. We are currently working on a way to show this",
                failedToGenerateNotificationPreview: "Could not generate preview of message due to the message not being delivered yet. Check if the candidate's mobile number is correct and then retry the notification. If the candidate's mobile number is correct then please check back here in a few minutes.",
                failedToGenerateNotificationPreviewOptOut: "Could not generate preview of message due to the candidate having opted out of receiving the message.",

                deliveryStatus: "Delivery Status",
                deliveryFailureReason: "Delivery Failure Reason",
                from: "From",
                to: "To",
                cc: "CC",
                bcc: "BCC",
                replyTo: "Reply to",
                numberOfOccurrences: "Number of occurrences",
                lastOccurredAt: "Last occurred at",
                emailPreview: "Email Preview",
                notificationDetails: "Notification Details",
                notificationsSent: "Notifications sent:",
                notificationOptOut: "The candidate has opted out of receiving this notification.",
                viewNotificationPreview: "View Notification Preview",
                couldNotLoadNotificationPreview: "Could not load the notification preview, please try again",
                couldNotLoadNotificationSentInformation: "Could not load the notification sent information, please try again",
                couldNotLoadEmailPreview: "Could not load the email preview, please try again",
                couldNotLoadConversationEmailPreview: "Could not load the conversation preview, please try again",
                client: "Client",

                candidateSupport: "Candidate Support",
                systemSupport: "System Support",
                candidateSupportInitiated: "Candidate Support Initiated",
                systemSupportInitiated: "System Support Initiated",
                clientSupport: "Client Support",
                clientSupportInitiated: "Client Support Initiated",
                guestSupport: "Guest Support",

                chat: "Chat",
                viewConversationPreview: "View Conversation Preview",

                listConversations: "List Conversations",
                conversationWith: "Conversation With",
                medium: "Medium",
                createdAt: "Created At",
                closedAt: "Closed At",
                updatedAt: "Updated At",
                closedBy: "Closed By",
                teamName: "Team Name",
                participant: "Participant",

                //===== STRINGS END   =====

                //===== SAFE STRINGS START =====

                updateItemDelay: "Please note that updates might take a while to reflect here.</br>Check back later or refresh page to try again",

                apiUnavailablePleaseReload: "<i class='a24-material-text-icons material-icons warning-color-text'>warning</i> Failed to retrieve data from server.<br/>Please click the reload icon at the top right of this card to try again.",
                conversationNoPreview: "<div class='a24-conversation-no-preview'>Preview can not be viewed as conversations is still 'Open'. In order to view the conversation, click on 'Inbox' in the left menu, then open the chat of the candidate.</div>",
                conversationNoData: "<div class='a24-conversation-no-preview'>No Conversation Data</div>",
                noMessages: "<div class='a24-conversation-no-preview'>No Messages</div>",
                //===== SAFE STRINGS END =====

                //===== TOKEN STRINGS START =====

                currentlyLoggedIn: "{0} You are currently logged in as ",
                iconAndText: "<div class='position-relative icon-and-text'><i class='a24-material-text-icons-column-wrap material-icons'>{0}</i> <div class='padding-left-double'>{1}</div></div>",
                clickHereWithIcon: "Click here <i class='material-icons a24-material-text-icons-no-color'>open_in_new</i>",

                //NOTE this is meant to be temporary since a having a unstyled tooltip is better than
                //none(dtagrid also works the same currently)
                tooltipOnString: "<span title='{0}'>{1}</span>"

                //===== TOKEN STRINGS END =====
            });
        }
    });
});
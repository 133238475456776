define("a24-ember-staffshift-core/services/notification-preview-generator", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objSSCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),

        generateFullHtmlWithHTMLWrappingFromNotification: function (objNotification) {
            let sHtml = `
            <html>
                <head>
                    <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/font.css">
                    <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/style.css">
                </head>
                <body>
                    ${this.generateFullHtmlFromNotification(objNotification)}
                </body>
            </html>
        `;

            return sHtml;
        },

        generateFullHtmlForStyleSheet: function () {
            let sHtml = `
            <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/style.css">
        `;

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        generateFullHtmlFromNotification: function (objNotification) {
            let sHeaderHtml = this._generateHTMLForNotificationHeader(objNotification);
            let sMessage = this._generateHTMLForMessage(objNotification);

            var sHtml = `
            <div class="a24-conversation-preview">
                ${sHeaderHtml}
                <div class="chat-messages-wrapper${a24Core.isEmpty(sHeaderHtml) ? " full-height" : ""}">`;
            if (!a24Core.isEmpty(objNotification.data)) {
                if (objNotification.medium === "sms" || objNotification.medium === "whatsapp") {
                    sHtml += `
                        <div class="chat-messages-preview">
                            <ul class="messages-list-preview-box">
                                ${sMessage}
                            </ul>
                        </div>`;
                } else {
                    sHtml += `<div style="padding: 1.5em;">${this.get("_objSSCoreStrings").getString("noNotificationTypeSupport")}</div>`;
                }
            } else if (objNotification.status === this.get("_objSSCoreEnums.notificationStatus.OPT_OUT.backend")) {
                sHtml += `<div style="padding: 1.5em;">${this.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreviewOptOut")}</div>`;
            } else {
                sHtml += `<div style="padding: 1.5em;">${this.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreview")}</div>`;
            }

            sHtml += `
                </div>
            </div>
        `;

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        _generateHTMLForNotificationHeader: function (objNotification) {
            let objCreatedDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
            var arrRows = [];

            if (!a24Core.isEmpty(objNotification.created_at)) {
                objCreatedDate.setDateFromRest(objNotification.created_at);

                arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("sendDate") + ":", objCreatedDate.getDateTimeFormat()));
            }

            arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("notificationType") + ":", this.get("_objSSCoreEnums").getFrontendValue("mediumType", objNotification.medium)));
            if (!a24Core.isEmpty(objNotification.status)) {
                arrRows.push(this._generateHeaderCellHtml(this.get("_objStringsService").getString("status") + ":", this.get("_objSSCoreEnums").getFrontendValue("notificationStatus", objNotification.status)));
                if (!a24Core.isEmpty(objNotification.status_reason)) {
                    arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("statusReason") + ":", objNotification.status_reason));
                }
            }

            var arrTokens = objNotification.collected_tokens;
            var sSender = "";
            var sRecipient = "";
            var sRecipientName = "";

            for (var i = 0; i < arrTokens.length; i++) {
                switch (arrTokens[i].token) {
                    case "recipient_full_name":
                        sRecipientName = arrTokens[i].value;
                        break;
                    case "recipient_mobile_number":
                        sRecipient = arrTokens[i].value;
                        break;
                    case "sender_phone_number":
                        sSender = arrTokens[i].value;
                        break;
                    default:
                        break;
                }
            }
            if (objNotification.medium === "sms" || objNotification.medium === "whatsapp") {
                if (!a24Core.isEmpty(sRecipientName)) {
                    arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("recipientName") + ": ", sRecipientName));
                }
                if (!a24Core.isEmpty(sRecipient)) {
                    arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("recipientNumber") + ": ", objNotification.recipient_mobile_number_formatted ? objNotification.recipient_mobile_number_formatted : sRecipient));
                }
                if (!a24Core.isEmpty(sSender)) {
                    arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("senderNumber") + ":", objNotification.sender_phone_number_formatted ? objNotification.sender_phone_number_formatted : sSender));
                }
            }

            return `
            <div class="a24-conversation-heading-block">
                <table class="a24-conversation-details-table">${arrRows.join("")}
                </table>
            </div>
        `;
        },

        _generateHeaderCellHtml(sLabel, sValue) {
            return `
            <tr>
                <td class="a24-conversation-details-label">${sLabel}</td>
                <td class="a24-conversation-details-value">${sValue}</td>
            </tr>`;
        },

        _generateHTMLForMessage(objNotification) {
            var sNode = ``;
            if (objNotification.medium === "sms" || objNotification.medium === "whatsapp") {
                if (!a24Core.isEmpty(objNotification) && !a24Core.isEmpty(objNotification.data)) {
                    let sBody = Ember.Handlebars.Utils.escapeExpression(objNotification.data.body);
                    sNode = `
                    <li class="chat-message chat-message-preview">
                        <div class="bubble">${sBody}</div>
                    </li>
                `;
                }
            }

            return Ember.String.htmlSafe(sNode);
        }
    });
});
define("a24-ember-lib/services/card-datagrid-filter-custom-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        funcFilterCustomPrimaryQuery: null,
        // function(objHeaderItem, objHeaderSecondaries) {
        //     //The returned object is the object that will be send to rest helper.
        //     //Could contain only the primary value or add some secondaries depending how the custom filter
        //     //for primary works
        //     return {
        //         bLike: true,
        //         bEncode: true,
        //         mValue: get(objHeaderItem, "_sFilterCustomPropThingy"), //pull this value from custom properties added to the objHeaderItem
        //         // arSec: [
        //         //     {
        //         //         sProp: "version",
        //         //         mValue: 23
        //         //     }
        //         // ]
        //     };
        // },
        funcFilterCustomTag: null,
        // function(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
        //     //Here the custom properties added to the objHeaderItem would have been cleared so tag needs to be
        //     //build up from the objQuery values
        //
        //     //Here we build up the tag for the custom item since this might be different for custom items.
        //     //We can also make the _createAdvanceTagSingleProp public that is in the card datagrid filter service
        //     //and then call it from here since I think that will still cover most scenarios
        //
        //     //The bSkipTitle caters for `AND` and `OR` tag buildup
        //     //When bSkipTitle is true then the string returned should not start with the primary title e.g
        //     //"First Name contains bloep, Last name is Tom" <--bSkipTitle = false (we lowercase the contains)
        //     //"Contains bloep, Last name is Tom" <--bSkipTitle = false (we do NOT lowercase the contains)
        //
        //     NOTE: See how sTagSimple is the full/expanded version of the tag, no compressing values/groups
        //     also the expanded values should be alphabetical and also only return sTagSimple if you had such
        //     a case.
        //
        //     return {
        //         sTag: "Status is Multiple, First name is " + objQuery.mValue,
        //         sTagSimple: "Status is (Active, Inactive, Other), First name is " + objQuery.mValue"
        //     }
        funcFilterCustomPrimaryReset: null,
        // function(objHeaderItem) {
        //     //Rest the state of the custom filter input
        //     set(objHeaderItem, "_sFilterCustomPropThingy", null);
        // }
        funcFilterCustomFixupQuery: null,
        // function(objHeader, objHeaderSecondaries) {
        //     //Here you need to fix values that might not be correct or supported. You will need to loop throught all the
        //     //items in the objHeader.mFilterValue to do this. Each item in there will look like what you returned inspect
        //     //funcFilterCustomPrimaryQuery or future function funcFilterCustomSecondaryQuery
        //     //We need to set the correct to and from date for dates that have a value like
        //     //dateFilterYesterday since yesterday will have different dates depending when you execute it
        //     //Currently we only have dates that work this way, so if you have something similar the fix it up here
        //     //else in most cases this can just remain empty.
        // }
        funcFilterCustomSetEditState: null,
        // function(objHeaderItem, iIndex) {
        //     //Here you need to set back the filter values onto the objHeaderItem
        //     //You can retrieve the filter values for the filter being edited by
        //     //objHeaderItem.mFilterValue[iIndex];
        //
        // }

        init: function () {
            this._super(...arguments);
            if (a24Core.isEmpty(this.get("funcFilterCustomPrimaryQuery")) || a24Core.isEmpty(this.get("funcFilterCustomTag")) || a24Core.isEmpty(this.get("funcFilterCustomPrimaryReset")) || a24Core.isEmpty(this.get("funcFilterCustomFixupQuery")) || a24Core.isEmpty(this.get("funcFilterCustomSetEditState"))) {
                //eslint-disable-next-line no-console
                console.warn("Not all required functions were implemented for the service that extend " + "card-datagrid-filter-custom-base");
            }
        }
    });
});
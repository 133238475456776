define("a24-ember-staffshift-core/services/rest-chat-conversations", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _sRestApiBaseUrl: Ember.computed.readOnly("objEnv.sRestApiBaseUrl"),

        objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Retrieves a specific conversation
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sConversationId - The conversation id to fetch
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  09 June 2023
         */
        getConversation: function (objContext, sProperty, sConversationId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger(`/v1/chat/conversations/${sConversationId}`);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});
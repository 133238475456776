define("a24-ember-lib/components/component-sort", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/component-sort"], function (exports, _didRenderChangesMixin, _componentSort) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _componentSort.default,
        sGroupTitleStyle: null,
        sSortProperty: null,
        sInnerSortProperty: null,
        arrGroupings: null,
        sFilterProperty: null,
        sFilterValue: null,
        arrComponents: null,

        _objComponentSort: Ember.inject.service("component-sort"),

        _arrGroups: Ember.computed("arrComponents.[]", "sFilterValue", function () {
            return Ember.get(this, "_objComponentSort").filterAndSortComponents(Ember.get(this, "arrComponents"), Ember.get(this, "arrGroupings"), Ember.get(this, "sFilterProperty"), Ember.get(this, "sFilterValue"), Ember.get(this, "sInnerSortProperty"));
        }).readOnly(),

        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("arrGroupings"))) {
                var arrGroups = [];
                var sTitles = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var iTitlesLength = sTitles.length;
                var sSortProperty = this.get("sSortProperty");

                for (var i = 0; i < iTitlesLength; i++) {
                    var sTitle = sTitles.charAt(i);

                    arrGroups.push({
                        sTitle: sTitle,
                        funcGroupFilter: function (objComponent) {
                            return this.sTitle.toLowerCase() === Ember.get(objComponent, sSortProperty).charAt(0).toLowerCase();
                        }
                    });
                }

                this.set("arrGroupings", arrGroups);
            }
        }
    });
});
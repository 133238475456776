define("a24-ember-staffshift-core/components/page-react-easter-egg", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/page-react-easter-egg"], function (exports, _didRenderChangesMixin, _pageReactEasterEgg) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageReactEasterEgg.default,
        bShowInitFields: true,
        arrItems: null,
        init: function () {
            this._super(...arguments);
            this.set("arrItems", ["me"]);
        },
        actions: {
            addAnother: function () {
                this.get("arrItems").pushObject("me");
            },
            flipIt: function functionName() {
                this.set("bShowInitFields", false);
            }
        }
    });
});
define("a24-ember-staffshift-core/components/navigation-bar-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/navigation-bar-component"], function (exports, _didRenderChangesMixin, _navigationBarComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _navigationBarComponent.default,

        arrNavbarItems: null, // The navbar items to display
        sAvatarUrl: null, // The Avatar url to display
        sFirstNameInitial: null, // The initial of the user to display if the avatar is null
        sContextSwitcherDropdownContentId: "contextSwitcherDropdown",
        sBaseClass: "white",

        _objTitleBarService: Ember.inject.service("title-bar-service"), // The title bar service
        _objConstantsEmberLib: Ember.inject.service("a24-constants-ember-lib"),
        _objNavBarService: Ember.inject.service("navigation-bar-component-service"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objUserSession: Ember.inject.service("user-session"),

        _sIFrameUrl: null,
        _bDisplayReactFrame: false,
        _objPostIframeData: null,
        _sContextId: null,
        _bHasContextDetails: false,

        init: function () {
            this._super(...arguments);
            this.set("arrNavbarItems", []);
        },

        _hasContextDetails: Ember.observer("_objUserSession.objContextDetails", function () {
            this.set("_bHasContextDetails", false);
            if (!a24Core.isEmpty(this.get("_objUserSession.objContextDetails"))) {
                this.set("_bHasContextDetails", true);
            }
            this._triggerPostIframe();
        }).on('init'),

        _contextChange: Ember.observer("_objUserSession.objAuthData.context.objReferenceId.mId", function () {
            if (this.get("_objUserSession.objAuthData.context.objReferenceId.mId") !== this.get("_sContextId")) {
                this.set("_bDisplayReactFrame", false);
                this.set("_objPostIframeData", null);
                this.set("_sIFrameUrl", null);
                this.set("_sContextId", this.get("_objUserSession.objAuthData.context.objReferenceId.mId"));

                this._triggerPostIframe();
            }
        }).on('init'),

        _triggerPostIframe: function () {
            if (!this.get("_bHasContextDetails")) {
                return;
            }
            let sContext = this.get("_objUserSession.objAuthData.context.objReferenceId.sResource");
            if (sContext === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY") || sContext === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                Ember.run.next(() => {
                    if (a24Core.isEmpty(this)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", () => {

                        let objEnv = Ember.getOwner(this).resolveRegistration("config:environment");
                        if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                            this.set("_sIFrameUrl", objEnv.sReactGatewayUrl);
                        }
                        this.set("_objPostIframeData", {
                            type: "trigger_route",
                            data: {
                                route: "general/globalSearch"
                            }
                        });
                        this.set("_bDisplayReactFrame", true);
                    });
                });
            }
        },

        _buildTitleBarItems: Ember.observer("_objTitleBarService.sPageTitle", function () {
            // Set the new navbar items when the page title changes
            this.set("arrNavbarItems", []);
            // Push only the page title as a navbar item
            this.get("arrNavbarItems").push(this.get("_objTitleBarService.sPageTitle"));
        }).on("init"),
        _buildTitleBarAvatar: Ember.observer("_objTitleBarService.sUserAvatar", function () {
            // Set the new avatar url if the user avatar changes
            this.set("sAvatarUrl", this.get("_objTitleBarService.sUserAvatar"));
        }).on("init"),
        _buildTitleInitial: Ember.observer("_objTitleBarService.sUserNameInitial", function () {
            // Set the new avatar alternative letter if the user initial changes
            this.set("sFirstNameInitial", this.get("_objTitleBarService.sUserNameInitial"));
        }).on("init")
    });
});
define("a24-ember-lib/components/card-datagrid-container", ["exports", "a24-ember-lib/templates/components/card-datagrid-container", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridContainer, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridContainer.default,
        sUrlBase: "",
        bDisableFilterKeyboardShortcuts: false,
        bDisableTableKeyboardShortcuts: false,
        objUrlConstantPart: null,
        objDatagridConfig: null,
        objQueryParams: null,
        bShowShareButton: false,
        bShareableLink: false,
        bSwaggerServiceCalls: true,
        bShowSaveAction: true,
        bPostTypeDatagrid: false,
        bPostTypeDatagridV2: false,
        bAllowHiddenColumns: false,
        bAllowHiddenColumnsProjection: false,
        arrAllowedHeaders: null,
        arrExtraActions: null,
        iVisibleActions: 0,
        _arrSelectedItems: null,
        _arrActionItems: null,
        _bDatagridLoading: false,
        _arrTableData: null,
        _objFilterParamObject: null,
        _objTableParamObject: null,
        _objPageParamObject: null,
        _objUrlCall: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objFilterService: Ember.inject.service("card-datagrid-filter"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _bDidInsertElement: false,
        _bPagerLoading: false,
        _sPageParam: "",
        _sSortParam: "",
        _bManyLabelClicked: false,
        _bResultCountReturned: false,

        _objProjectionQuery: null,
        _funcQueryParamsObserverCallback: function () {
            var objThis = this;
            // We do this purely because we need certain variables to bind back to the parent for testing
            Ember.run.schedule("actions", function () {
                objThis.getQueryParams();
            });
        },
        _funcPageParamChangeCallback: function () {
            this.doServiceCall(false);
        },
        _funcSortParamChangeCallback: function () {
            this.doServiceCall(true);
        },
        init: function () {
            this._super(...arguments);
            var objThis = this;
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            //Since paging is not something we want to control from outside it is highly likely that these values will
            //not be set, so we set them here to default on the objDatagridConfig so that they also go into the gwt history
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPerPage"))) {
                this.set("objDatagridConfig.iPerPage", 25);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iResultCount"))) {
                this.set("objDatagridConfig.iResultCount", 0);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPage"))) {
                this.set("objDatagridConfig.iPage", 1);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.bHasReloadButton"))) {
                this.set("objDatagridConfig.bHasReloadButton", false);
            }
            if (a24Core.isEmpty(this.get("_arrTableData"))) {
                this.set("_arrTableData", []);
            }
            if (a24Core.isEmpty(this.get("_objProjectionQuery"))) {
                this.set("_objProjectionQuery", {});
            }
            if (!a24Core.isEmpty(this.get("objDatagridConfig.funcResultCountManyClicked")) && !a24Core.isEmpty(this.get("objDatagridConfig.iResultCountLimit"))) {
                this.set("objDatagridConfig.funcTriggerResultCountManyClick", () => {
                    if (!this.get("_bResultCountReturned") && this.get("objDatagridConfig.iResultCount") === this.get("objDatagridConfig.iResultCountLimit")) {
                        if (!this.get("_bManyLabelClicked")) {
                            // check that service is not already running
                            this.set("_bManyLabelClicked", true);
                            this.doServiceCall(false, true);
                        }
                    } else {
                        this.get("objDatagridConfig.funcResultCountManyClicked")().funcSuccess(this.get("objDatagridConfig.iResultCount"));
                    }
                });
            }

            this.set("_bManyLabelClicked", false);
            this.set("_bResultCountReturned", false);

            //Add query params observer if we have any
            if (!a24Core.isEmpty(this.get("objQueryParams"))) {
                this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
                this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));

                for (var i = 0; i < arrHeaders.length; i++) {
                    //Set the sort if the column has it applied
                    if (!a24Core.isEmpty(arrHeaders[i].sAsc)) {
                        this.set("_sSortParam", arrHeaders[i].sProperty + "::" + arrHeaders[i].sAsc);
                        break;
                    }
                }
                this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

                this.getQueryParams();
            } else {
                $.each(arrHeaders, function (sKey, objItem) {
                    Ember.set(objItem, "bShowHeader", !objItem.bInitialyHidden);
                });
            }

            this.set("arrAllowedHeaders", arrHeaders);

            Ember.run.scheduleOnce("afterRender", this, function () {
                objThis.set("_bDidInsertElement", true);
                //We call the service call here to give the child components the chance to initialize and set the initial
                //query objects.
                objThis.doServiceCall(false);
            });
        },
        getQueryParams: function () {
            //This function will get the query params that are in the url and apply them to the datagrid
            var objQueryParams = this.get("objQueryParams");
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            var objProjectionQuery = {};

            var arrVisibleColumns = [];
            if (!a24Core.isEmpty(objQueryParams.vis_cols.sValue)) {
                arrVisibleColumns = objQueryParams.vis_cols.sValue.split(",");
            }

            var arrSortDetails = null;
            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                if (!a24Core.isEmpty(objQueryParams.sortBy.sValue)) {
                    arrSortDetails = objQueryParams.sortBy.sValue.split("::");
                }
            }
            var bFoundSortColumn = false;
            var sSortColumn = null;
            var sSortOrder = null;
            if (!a24Core.isEmpty(arrSortDetails)) {
                sSortColumn = arrSortDetails[0];
                sSortOrder = arrSortDetails[1];
            }

            var objFirstVisCol = null;
            var bFoundVisCol = false;

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;
                var sUniqueColNum = objHeader.iUniqueColNum + "";

                if (a24Core.isEmpty(sUniqueColNum)) {
                    console.error( //eslint-disable-line no-console
                    "Column with property " + sProperty + " does not have a iUniqueColNum defined.\n" + "Each column on the datagrid should have a iUniqueColNum defined when using query params.\n" + "The iUniqueColNum should be different for each column and never unpdated in the future since it " + "will break users saved bookmarks or links created with the iUniqueColNum in it.");
                    return;
                }

                Ember.set(objHeader, "bShowHeader", false);

                if (arrVisibleColumns.includes(sUniqueColNum) && !objHeader.bSilent) {
                    bFoundVisCol = true;
                    Ember.set(objHeader, "bShowHeader", true);
                    objProjectionQuery[this._getBaseProprty(objHeader.sProperty)] = {
                        bProject: true,
                        mValue: 1
                    };
                }

                if (a24Core.isEmpty(objFirstVisCol) && !objHeader.bSilent) {
                    objFirstVisCol = objHeader;
                }

                if (objHeader.bFilterSecondary || objHeader.bFilterAnd || objHeader.bFilterOr || objHeader.sFilterType === "custom") {
                    // if (objHeader.bFilterSecondary) { <-- use this when below commented out code is used
                    //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sTagValueSimple", null);
                        Ember.set(objHeader, "arrTagValue", null);
                        Ember.set(objHeader, "mFilterValue", null);
                    } else {
                        //base64 decode, then JSON decode
                        try {
                            Ember.set(objHeader, "mFilterValue", JSON.parse(atob(objQueryParams[sProperty].sValue)));
                        } catch (objError) {
                            //JSON stringify or btoa fail.

                            Ember.set(objHeader, "sTagValue", null);
                            Ember.set(objHeader, "sTagValueSimple", null);
                            Ember.set(objHeader, "arrTagValue", null);
                            Ember.set(objHeader, "mFilterValue", null);

                            //eslint-disable-next-line no-console
                            console.warn("Could not parse the datagrid advance filter mFilterValue \n", objError);
                        }
                    }

                    // We can add this in the future to try and save space in the url, for now im leaving this out because of
                    // the following scenario(assume we use || for or condition):
                    // Search for "tom" and "bob" will be sName=tom||bob = seach on 2 items
                    // Search for "tom||nom" and "bob" will be sName=tom||nom||bob = search on 3 items instead of only 2
                    // } else if (objHeader.bFilterAnd || objHeader.bFilterOr) {
                    //     // Parsing bFilterAnd and bFilterOr different to save space in the url e.g
                    //     // policies___type_id=Bloep&&noep&&snoep||nomnom&&sneegle, since we can split on that and
                    //     // still be able to build the correct query. It is only when secondary properties come into play
                    //     // where we cant assign all values on one property without base64
                    //
                    //     //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    //
                    //     if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                    //         set(objHeader, "sTagValue", null);
                    //         set(objHeader, "sTagValueSimple", null);
                    //         set(objHeader, "mFilterValue", null);
                    //     } else {
                    //         set(objHeader, "mFilterValue", objQueryParams[sProperty].sValue);
                    //     }
                } else if ("date" === objHeader.sFilterType) {
                    var iDays = null;
                    var iDaysTo = null;
                    var bFixDates = false;
                    if (!a24Core.isEmpty(objQueryParams[sProperty].sValue) && (objQueryParams[sProperty].sValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || objQueryParams[sProperty].sValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0)) {
                        let sUnit = objQueryParams[sProperty].sValue.split("_")[1];
                        if (!a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && !a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue)) {
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objFromDate.setDateFromBrowser(objQueryParams[sProperty + "From"].sValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objToDate.setDateFromBrowser(objQueryParams[sProperty + "To"].sValue, this.get("_objLibConstants.DATE_FORMAT"));

                            objFromDate.setTimeToStartOfDay();
                            objToDate.setTimeToStartOfDay();
                            iDays = Math.round(objFromDate.difference(objToDate, sUnit, true));
                            bFixDates = true;
                        }
                    } else if (!a24Core.isEmpty(objQueryParams[sProperty].sValue) && (objQueryParams[sProperty].sValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || objQueryParams[sProperty].sValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0)) {
                        let sUnit = objQueryParams[sProperty].sValue.split("_")[1];
                        if (!a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && !a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue)) {
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objFromDate.setDateFromBrowser(objQueryParams[sProperty + "From"].sValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objToDate.setDateFromBrowser(objQueryParams[sProperty + "To"].sValue, this.get("_objLibConstants.DATE_FORMAT"));

                            objFromDate.setTimeToStartOfDay();
                            objToDate.setTimeToStartOfDay();
                            iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));
                            bFixDates = true;
                        }
                    } else if ((objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) && !a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && !a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue)) {
                        let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                        objFromDate.setDateFromBrowser(objQueryParams[sProperty + "From"].sValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objToDate.setDateFromBrowser(objQueryParams[sProperty + "To"].sValue, this.get("_objLibConstants.DATE_FORMAT"));

                        objFromDate.setTimeToStartOfDay();
                        objToDate.setTimeToStartOfDay();
                        iDays = Math.round(objToDate.difference(objFromDate, a24Constants.MOMENT_TIME_DAYS, true));
                        bFixDates = true;
                    } else if (objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                        bFixDates = true;
                    } else if (!a24Core.isEmpty(objQueryParams[sProperty].sValue) && objQueryParams[sProperty].sValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                        let arrExtraOptions = objQueryParams[sProperty].sValue.split("_");
                        iDays = arrExtraOptions[2];
                        iDaysTo = arrExtraOptions[3];
                        bFixDates = true;
                    }

                    var objFilterToAndFrom = this.get("_objFilterService").getDateFromAndToValues(objQueryParams[sProperty].sValue, iDays, iDaysTo);
                    var bInvalidValue = a24Core.isEmpty(objQueryParams[sProperty].sValue) || objFilterToAndFrom.bNotInset;

                    //TODO might need to add extra check here for new types and then set the to and from here so that the
                    //url updates with the new dates. Not that it is a real problem since the dates only gets used to calc
                    //the amount of days inbetween them. Just if user does look at the url it might appear wrong even tho
                    //it will actually query with the correct expected dates.

                    if (a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue) && bInvalidValue) {
                        Ember.set(objHeader, "sFilterFromValue", null);
                        Ember.set(objHeader, "sFilterToValue", null);
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sTagValueSimple", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        if (bFixDates) {
                            Ember.set(objHeader, "sFilterFromValue", objFilterToAndFrom.sFromDate);
                            Ember.set(objHeader, "sFilterToValue", objFilterToAndFrom.sToDate);
                        } else {
                            Ember.set(objHeader, "sFilterFromValue", objQueryParams[sProperty + "From"].sValue);
                            Ember.set(objHeader, "sFilterToValue", objQueryParams[sProperty + "To"].sValue);
                        }

                        if (bInvalidValue) {
                            if (objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {
                                Ember.set(objHeader, "sFilterValue", this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend"));
                            } else {
                                Ember.set(objHeader, "sFilterValue", this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend"));
                            }
                        } else {
                            Ember.set(objHeader, "sFilterValue", objQueryParams[sProperty].sValue);
                        }
                    }
                } else {
                    if (a24Core.isEmpty(objQueryParams[sProperty].sValue) || objHeader.bStopFiltering) {
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sTagValueSimple", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        Ember.set(objHeader, "sFilterValue", objQueryParams[sProperty].sValue);
                    }
                }

                if (sProperty === sSortColumn) {
                    bFoundSortColumn = true;
                    Ember.set(objHeader, "sAsc", sSortOrder);
                } else {
                    Ember.set(objHeader, "sAsc", null);
                }
            }

            //We cant hide all the columns, so when the vis_col param is empty we display the first availible column
            if (a24Core.isEmpty(arrVisibleColumns) || !bFoundVisCol) {
                Ember.set(objFirstVisCol, "bShowHeader", true);
                objProjectionQuery[this._getBaseProprty(objFirstVisCol.sProperty)] = {
                    bProject: true,
                    mValue: 1
                };
            }

            if (JSON.stringify(this.get("_objProjectionQuery")) !== JSON.stringify(objProjectionQuery)) {
                this.set("_objProjectionQuery", objProjectionQuery);
            }
            //Add the special cases that are not in the arrHeaderConfig
            if (!a24Core.isEmpty(objQueryParams.page)) {
                this.set("objDatagridConfig.iPage", parseInt(objQueryParams.page.sValue));
                this.set("_sPageParam", objQueryParams.page.sValue);
            }

            if (!bFoundSortColumn || a24Core.isEmpty(arrSortDetails)) {
                this.set("_objTableParamObject", null);
            } else {
                var objSortQuery = {};
                var objQueryItem = {};
                objQueryItem.mValue = sSortOrder;
                objQueryItem.bSortBy = true;
                objSortQuery[sSortColumn] = objQueryItem;
                this.set("_objTableParamObject", objSortQuery);
            }

            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                this.set("_sSortParam", objQueryParams.sortBy.sValue);
            }

            // This component and the card datagrid filter component both seem to change the header configurations
            // We add this next here so that we can ensure that all the changes have completed before updating the url
            // to avoid a race condition between the two components
            Ember.run.next(() => {
                // Call this so that we can ensure the url matches what was updated in the headers
                this.setQueryParams();
            });
        },
        setQueryParams: function () {
            //This function will get the new query param values from the datagrid and then set them back onto the url
            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams"))) {
                return;
            }

            //Remove objQueryParams since we are doing an internal change and do not want the getQueryParams firing
            this.removeObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));

            //Update the objQueryParams so that the values for them in the url can update
            var objNewQueryParams = JSON.parse(JSON.stringify(this.get("objQueryParams")));
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            var sSortBy = null;

            var arrVisibleHeaders = [];

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;

                // adds the property to the visible columns array if show header is true and silent is
                if (objHeader.bShowHeader && !objHeader.bSilent) {
                    arrVisibleHeaders.push(objHeader.iUniqueColNum);
                }

                if (objHeader.bFilterSecondary || objHeader.bFilterAnd || objHeader.bFilterOr || objHeader.sFilterType === "custom") {
                    // if (objHeader.bFilterSecondary) { <-- use this when below commented out code is used

                    //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    if (a24Core.isEmpty(objHeader.mFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        //JSON encode then base64 encode
                        var sFilterValue = "";
                        try {
                            sFilterValue = btoa(JSON.stringify(objHeader.mFilterValue));
                        } catch (objError) {
                            //JSON stringify or btoa fail.
                            //eslint-disable-next-line no-console
                            console.warn("Could not encode the datagrid advance filter mFilterValue \n", objError);
                        }

                        objNewQueryParams[sProperty].sValue = sFilterValue;
                    }

                    // We can add this in the future to try and save space in the url, for now im leaving this out because of
                    // the following scenario(assume we use || for or condition):
                    // Search for "tom" and "bob" will be sName=tom||bob = seach on 2 items
                    // Search for "tom||nom" and "bob" will be sName=tom||nom||bob = search on 3 items instead of only 2
                    // } else if (objHeader.bFilterAnd || objHeader.bFilterOr) {
                    //     // Parsing bFilterAnd and bFilterOr different to save space in the url e.g
                    //     // policies___type_id=Bloep&&noep&&snoep||nomnom&&sneegle, since we can split on that and
                    //     // still be able to build the correct query. It is only when secondary properties come into play
                    //     // where we cant assign all values on one property without base64
                    //
                    //     //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    //
                    //     if (a24Core.isEmpty(objHeader.mFilterValue)) {
                    //         objNewQueryParams[sProperty].sValue = "";
                    //     } else {
                    //         objNewQueryParams[sProperty].sValue = objHeader.mFilterValue;
                    //     }
                } else if ("date" === objHeader.sFilterType) {
                    if (a24Core.isEmpty(objHeader.sFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        objNewQueryParams[sProperty].sValue = objHeader.sFilterValue;
                    }

                    objNewQueryParams[sProperty + "From"].sValue = "";
                    objNewQueryParams[sProperty + "To"].sValue = "";
                    if (!a24Core.isEmpty(objHeader.sFilterValue)) {
                        if (objHeader.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                            let sUnit = objHeader.sFilterValue.split("_")[1];
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objFromDate.setDateFromBrowser(objHeader.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                            let iDays = Math.round(objCurrentDate.difference(objFromDate, sUnit, true));

                            objNewQueryParams[sProperty + "To"].sValue = objCurrentDate.getDateFormat();
                            objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                            objNewQueryParams[sProperty + "From"].sValue = objCurrentDate.getDateFormat();
                        } else if (objHeader.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                            let sUnit = objHeader.sFilterValue.split("_")[1];
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objFromDate.setDateFromBrowser(objHeader.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                            let iDays = Math.round(objFromDate.difference(objCurrentDate, sUnit, true));

                            objNewQueryParams[sProperty + "To"].sValue = objCurrentDate.getDateFormat();
                            objCurrentDate.addTimeToDate(sUnit, iDays);
                            objNewQueryParams[sProperty + "From"].sValue = objCurrentDate.getDateFormat();
                        } else if (objHeader.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                            let sUnit = objHeader.sFilterValue.split("_")[1];
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objFromDate.setDateFromBrowser(objHeader.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objToDate.setDateFromBrowser(objHeader.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objToDate.setTimeToStartOfDay();
                            objFromDate.setTimeToStartOfDay();
                            let iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));

                            objNewQueryParams[sProperty + "To"].sValue = objCurrentDate.getDateFormat();
                            objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                            objNewQueryParams[sProperty + "From"].sValue = objCurrentDate.getDateFormat();
                        } else if (objHeader.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                            let sUnit = objHeader.sFilterValue.split("_")[1];
                            let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            objToDate.setDateFromBrowser(objHeader.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objCurrentDate.setTimeToStartOfDay();
                            objToDate.setTimeToStartOfDay();
                            let iDays = Math.round(objCurrentDate.difference(objToDate, sUnit, true));

                            objNewQueryParams[sProperty + "From"].sValue = objCurrentDate.getDateFormat();
                            objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                            objNewQueryParams[sProperty + "To"].sValue = objCurrentDate.getDateFormat();
                        } else if (objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend") || objHeader.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                            if (!a24Core.isEmpty(objHeader.sFilterFromValue)) {
                                objNewQueryParams[sProperty + "From"].sValue = objHeader.sFilterFromValue;
                            }
                            if (!a24Core.isEmpty(objHeader.sFilterToValue)) {
                                objNewQueryParams[sProperty + "To"].sValue = objHeader.sFilterToValue;
                            }
                        }
                    }
                } else {
                    if (a24Core.isEmpty(objHeader.sFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        objNewQueryParams[sProperty].sValue = objHeader.sFilterValue;
                    }
                }

                //Set the sort if the column has it applied
                if (!a24Core.isEmpty(objHeader.sAsc) && !a24Core.isEmpty(objNewQueryParams.sortBy)) {
                    sSortBy = sProperty + "::" + objHeader.sAsc;
                    objNewQueryParams.sortBy.sValue = sSortBy;
                }
            }

            //This is internal change so remove observer from the _sSortParam while changing it
            this.removeObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));
            if (a24Core.isEmpty(sSortBy)) {
                this.set("_sSortParam", "");
            } else {
                this.set("_sSortParam", sSortBy);
            }
            this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

            // Sets the visiable columns query param
            objNewQueryParams.vis_cols.sValue = arrVisibleHeaders.join(",");

            if (!a24Core.isEmpty(objNewQueryParams.page)) {
                objNewQueryParams.page.sValue = this.get("objDatagridConfig.iPage").toString();
            }

            this.set("objQueryParams", objNewQueryParams);

            this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
        },
        doServiceCall: function (bFirstPage, bSkipResultCountLimit) {
            if (!this.get("_bDidInsertElement")) {
                return;
            }
            this.set("_bDatagridLoading", true);

            //Always go back to page 1 unless service call event came from the pager.
            if (bFirstPage) {
                this.set("objDatagridConfig.iPage", 1);
            } else {
                this.updatePageParamFilter();
            }

            if (!bSkipResultCountLimit) {
                // called with result count limit, this invalidates the many click
                this.set("_bResultCountReturned", false);
            }

            var objController = this;
            var objPOSTBody = null;

            var objSuccessCallback = function (objResponse, sStatus, jqXHR) {

                objController.set("objDatagridConfig.iResultCount", a24RestCallHelper.getXResultCount(jqXHR));
                objController.set("_arrTableData", a24Core.isEmpty(objResponse) ? [] : objResponse);
                objController.set("objDatagridConfig.iResultsReturned", !a24Core.isEmpty(objResponse) ? objResponse.length : 0);

                if (objController.get("_bManyLabelClicked")) {
                    objController.set("_bManyLabelClicked", false);
                }

                if (bSkipResultCountLimit) {
                    objController.set("_bResultCountReturned", true);
                }

                let bDidDoServiceCallHandled = false;
                //This now allows you to either just alter the _arrTableData OR if you want to sort or replace the array
                //with a new one(even from new service call) you can return true and then call the callback to set data
                //later, thus datagrid will stay in loading till then. This technically adds support to datagrid to do more
                //than one call and moesh it all into one response, you would fire the other service calls from the
                //bEmitWillDoServiceCall tho and just wait for them here.
                if (objController.get("objDatagridConfig.bEmitDidDoServiceCall")) {
                    bDidDoServiceCallHandled = objController.sendAction("onDidDoServiceCall", objController.get("_arrTableData"), jqXHR, Ember.copy(objPOSTBody, true), objController.get("objDatagridConfig.objPermanentQuery") ? objController.get("objDatagridConfig.objPermanentQuery") : objController.get("objUrlConstantPart"), objController.get("_objFilterParamObject"), objController.get("_objTableParamObject"), objController.get("objDatagridConfig.iPage"), objController.get("objDatagridConfig.iPerPage"), arrData => {
                        objController.set("_arrTableData", arrData);
                        objController.set("_bDatagridLoading", false);
                    }, bSkipResultCountLimit);
                }
                if (!bDidDoServiceCallHandled) {
                    objController.set("_bDatagridLoading", false);
                }

                if (!a24Core.isEmpty(objController.get("objDatagridConfig.funcResultCountManyClicked")) && objController.get("_bResultCountReturned")) {
                    objController.get("objDatagridConfig.funcResultCountManyClicked")().funcSuccess(objController.get("objDatagridConfig.iResultCount"));
                }

                if (!bFirstPage && a24Core.isEmpty(objResponse) && objController.get("objDatagridConfig.iResultCount") > 0) {
                    objController.set("_bResultCountReturned", true);
                    // display snackbar message
                    objController.get("_objSnackbarService").showSnackbarMessage(objController.get("_objEmberLibStringService").getString("endOfResults"));
                }

                objController.set("_bPagerLoading", false);
            };

            var objFailure = a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                if (objController.get("objDatagridConfig.bEmitDidDoServiceCallFail")) {
                    objController.sendAction("onDidDoServiceCallFail", sCode, sStatus, objErrorThrown, objError);
                } else if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    if (this.get("objDatagridConfig.bHasReloadButton")) {
                        this.set("_bDatagridLoading", false);

                        // display snackbar message
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objEmberLibStringService").getString("datagridReload"), this.get("_objEmberLibStringService").getString("reload"), () => {

                            this.doServiceCall(false);
                        });
                    }
                }
                if (objController.get("_bManyLabelClicked")) {
                    objController.set("_bManyLabelClicked", false);
                }

                if (!a24Core.isEmpty(objController.get("objDatagridConfig.funcResultCountManyClicked"))) {
                    this.get("objDatagridConfig.funcResultCountManyClicked")().funcFailure();
                }
            });

            /**
             * This if makes the datagrid either of GET type or of POST type
             */
            if (this.get("bPostTypeDatagrid") || this.get("bPostTypeDatagridV2")) {
                let bWillDoServiceCallHandled = false;
                var objCustomHeader = {};

                if (a24Core.isEmpty(bSkipResultCountLimit) && !bSkipResultCountLimit && !a24Core.isEmpty(this.get("objDatagridConfig.iResultCountLimit"))) {
                    objCustomHeader["X-Accept-Result-Count-Limit"] = this.get("objDatagridConfig.iResultCountLimit");
                }
                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        //This now allows you to either just alter the query params before the service call happens OR
                        //to return true and then handle the service call/data on your own and then call the
                        //objSuccessCallback or objFailure passed along to the action.
                        bWillDoServiceCallHandled = this.get("onWillDoServiceCall")(this.get("_arrTableData"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objFilterParamObject"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), objSuccessCallback, objFailure, this.get("bAllowHiddenColumnsProjection") ? this.get("_objProjectionQuery") : null, bSkipResultCountLimit);
                    }
                }

                // This is outside the `if (!bWillDoServiceCallHandled) {` since the onDidDoServiceCall uses this.
                // It would make sense that objPOSTBody should be null in the onDidDoServiceCall if you handled the
                // onWillDoServiceCall yourself, but rather leaving it out here for sake of support current code that
                // might want to check the objPOSTBody anyway.
                if (this.get("bPostTypeDatagrid")) {
                    objPOSTBody = a24RestCallHelper.convertHooksToPostBody(this.get("_objFilterParamObject"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), this.get("objDatagridConfig.objPayloadOverride"), this.get("bAllowHiddenColumnsProjection") ? this.get("_objProjectionQuery") : null);
                } else if (this.get("bPostTypeDatagridV2")) {
                    objPOSTBody = a24RestCallHelper.convertHooksToPostBodyV2(this.get("_objFilterParamObject"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), this.get("objDatagridConfig.objPayloadOverride"), this.get("bAllowHiddenColumnsProjection") ? this.get("_objProjectionQuery") : null);
                }

                if (!bWillDoServiceCallHandled) {
                    a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "POST", this.get("sUrlBase"), objSuccessCallback, objFailure, objPOSTBody, null, null, null, null, null, objCustomHeader));
                }
            } else {
                //Build up the complete url using the url param query objects from the filter, pager and table sort
                if (objController.get("bSwaggerServiceCalls")) {
                    a24RestUrlConstruct.setBaseUrlSwagger(this.get("sUrlBase"));
                } else {
                    a24RestUrlConstruct.setBaseUrl(this.get("sUrlBase"));
                }

                let bWillDoServiceCallHandled = false;
                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        //This now allows you to either just alter the query params before the service call happens OR
                        //to return true and then handle the service call/data on your own and then call the
                        //objSuccessCallback or objFailure passed along to the action.
                        bWillDoServiceCallHandled = this.get("onWillDoServiceCall")(this.get("_arrTableData"), this.get("objUrlConstantPart"), this.get("_objFilterParamObject"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), objSuccessCallback, objFailure);
                    }
                }

                var objPageQuery = {
                    page: {
                        mValue: this.get("objDatagridConfig.iPage")
                    },
                    per_page: {
                        mValue: this.get("objDatagridConfig.iPerPage")
                    }
                };

                if (!bWillDoServiceCallHandled) {
                    a24RestUrlConstruct.addQueryParamObject(this.get("objUrlConstantPart"));
                    a24RestUrlConstruct.addQueryParamObject(this.get("_objFilterParamObject"));
                    a24RestUrlConstruct.addQueryParamObject(this.get("_objTableParamObject"));
                    a24RestUrlConstruct.addQueryParamObject(objPageQuery);

                    var sUrl = a24RestUrlConstruct.getConstructedUrl();

                    a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "GET", sUrl, objSuccessCallback, objFailure, null, null, null, !objController.get("bSwaggerServiceCalls")));
                }
            }
        },
        updatePageParamFilter: function () {
            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams")) || a24Core.isEmpty(this.get("objQueryParams.page"))) {
                return;
            }

            //Remove the _sPageParam observer while we change it internally
            this.removeObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
            //Update the page query param in the url (the query-param-object-builder observes this and set it separately,
            //this is a special case since it it not part of/in the filter params/arrHeaderConfig)
            this.set("objQueryParams.page.sValue", this.get("objDatagridConfig.iPage").toString());
            // Add _sPageParam query params observer
            Ember.run.next(this, function () {
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            });
        },
        cardDatagridFilterInitEventAction: function (objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);
            if (this.get("objDatagridConfig.bEmitInitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridInitFilterChange"))) {
                    this.get("cardDatagridInitFilterChange")(this.get("_objFilterParamObject"));
                }
            }
        },
        cardDatagridTableInitSortAction: function (objTableParamObject) {
            this.set("_objTableParamObject", objTableParamObject);
            if (this.get("objDatagridConfig.bEmitInitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableInitSort"))) {
                    this.get("cardDatagridTableInitSort")(objTableParamObject);
                }
            }
        },
        cardDatagridFilterChangeEventAction: function (sProperty, objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridFilterChange"))) {
                    this.get("cardDatagridFilterChange")(sProperty, this.get("_objFilterParamObject"));
                }
            }

            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridFilterParamChangeEventAction: function (objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridFilterChange"))) {
                    this.get("cardDatagridFilterChange")(null, this.get("_objFilterParamObject"));
                }
            }

            this.doServiceCall(true);
            this.updatePageParamFilter();
        },
        cardDatagridTableSortAction: function (sColumn, objTableParamObject) {
            this.set("bShowSaveAction", true);
            this.set("_objTableParamObject", objTableParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableSort"))) {
                    this.get("cardDatagridTableSort")(sColumn, this.get("_objTableParamObject"));
                }
            }

            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridPagerInitEventAction: function (objPageQuery) {
            if (this.get("objDatagridConfig.bEmitPagerInitEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridPagerInitEvent"))) {
                    this.get("cardDatagridPagerInitEvent")(objPageQuery);
                }
            }
        },
        cardDatagridPagerEventAction: function () {
            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitPagerEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridPagerEvent"))) {
                    //Page and per page is on datagrid config and can be altered there from parent.
                    this.get("cardDatagridPagerEvent")();
                }
            }

            this.doServiceCall(false);
        },
        cardDatagridPagerManyClickedAction: function () {
            this.set("_bPagerLoading", true);
            this.set("_bManyLabelClicked", true);
            if (!a24Core.isEmpty(this.get("cardDatagridPagerManyClicked"))) {
                this.get("cardDatagridPagerManyClicked")();
            } else {
                //todo implement the many clicked
                this.doServiceCall(false, true);
            }
        },
        cardDatagridTableNoneSelectedAction: function () {
            this.set("_arrSelectedItems", null);
            this.set("bDisableFilterKeyboardShortcuts", false);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableNoneSelected"))) {
                    this.get("cardDatagridTableNoneSelected")([]);
                }
            }
        },
        funcSelectedItemActionFilter: function (arrItems) {
            return function (objAction) {
                return a24Core.isEmpty(objAction.funcFilterFunc) || !a24Core.isEmpty(objAction.funcFilterFunc) && objAction.funcFilterFunc(arrItems);
            };
        },
        _getBaseProprty: function (sProp) {
            let iTripIndex = sProp.indexOf("___");
            let iDoubleIndex = sProp.indexOf("__");

            if (iTripIndex !== -1 && iDoubleIndex !== -1) {
                if (iTripIndex < iDoubleIndex) {
                    return sProp.split("___")[0];
                } else {
                    return sProp.split("__")[0];
                }
            } else if (iTripIndex !== -1) {
                return sProp.split("___")[0];
            } else if (iDoubleIndex !== -1) {
                return sProp.split("__")[0];
            } else {
                return sProp;
            }
        },
        _onProjectionChange: Ember.observer("_objProjectionQuery", function () {
            if (this.get("bAllowHiddenColumnsProjection")) {

                Ember.run.once(this, "doServiceCall");
            }
        }),
        _onDatagridSingleSelectedActionsChange: Ember.observer("objDatagridConfig.arrSingleItemSelectActions", function () {
            var arrActions = this.get("objDatagridConfig.arrSingleItemSelectActions");
            var arrSelected = this.get("_arrSelectedItems");
            if (!a24Core.isEmpty(arrActions) && !a24Core.isEmpty(arrSelected)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrSelected));
            }
            this.set("_arrActionItems", arrActions);
        }),
        _onDatagridMultiSelectedActionsChange: Ember.observer("objDatagridConfig.arrMultiItemSelectActions", function () {
            var arrActions = this.get("objDatagridConfig.arrMultiItemSelectActions");
            var arrSelected = this.get("_arrSelectedItems");
            if (!a24Core.isEmpty(arrActions) && !a24Core.isEmpty(arrSelected)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrSelected));
            }
            this.set("_arrActionItems", arrActions);
        }),
        cardDatagridTableOneSelectedAction: function (arrItems) {
            this.set("_arrSelectedItems", arrItems);
            this.set("bDisableFilterKeyboardShortcuts", true);
            var arrActions = this.get("objDatagridConfig.arrSingleItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableOneSelected"))) {
                    this.get("cardDatagridTableOneSelected")(arrItems);
                }
            }
        },
        cardDatagridTableMultiSelectedAction: function (arrItems) {
            this.set("_arrSelectedItems", arrItems);
            this.set("bDisableFilterKeyboardShortcuts", true);
            var arrActions = this.get("objDatagridConfig.arrMultiItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableMultiSelected"))) {
                    this.get("cardDatagridTableMultiSelected")(arrItems);
                }
            }
        },
        cardDatagridTableCellAction: function (sProperty, objRowData) {
            if (!a24Core.isEmpty(this.get("cardDatagridTableCell"))) {
                this.get("cardDatagridTableCell")(sProperty, objRowData);
            }
        },
        actions: {
            cardDatagridFilterDropdownOpenEvent: function () {
                this.set("bDisableTableKeyboardShortcuts", true);
            },
            cardDatagridFilterDropdownCloseEvent: function () {
                this.set("bDisableTableKeyboardShortcuts", false);
            },
            cardDatagridFilterInitEvent: function (objFilterParamObject) {
                this.cardDatagridFilterInitEventAction(objFilterParamObject);
            },
            cardDatagridTableInitSort: function (objTableParamObject) {
                this.cardDatagridTableInitSortAction(objTableParamObject);
            },
            cardDatagridFilterChangeEvent: function (sProperty, objFilterParamObject) {
                this.cardDatagridFilterChangeEventAction(sProperty, objFilterParamObject);
            },
            cardDatagridFilterParamChangeEvent: function (objFilterParamObject) {
                this.cardDatagridFilterParamChangeEventAction(objFilterParamObject);
            },
            cardDatagridTableSort: function (sColumn, objTableParamObject) {
                this.cardDatagridTableSortAction(sColumn, objTableParamObject);
            },
            cardDatagridPagerInitEvent: function (objPageQuery) {
                this.cardDatagridPagerInitEventAction(objPageQuery);
            },
            cardDatagridPagerEvent: function () {
                this.cardDatagridPagerEventAction();
            },
            cardDatagridPagerManyClicked: function () {
                this.cardDatagridPagerManyClickedAction();
            },
            cardDatagridTableNoneSelected: function () {
                this.cardDatagridTableNoneSelectedAction();
            },
            cardDatagridTableOneSelected: function (arrItems) {
                this.cardDatagridTableOneSelectedAction(arrItems);
            },
            cardDatagridTableMultiSelected: function (arrItems) {
                this.cardDatagridTableMultiSelectedAction(arrItems);
            },
            cardDatagridTableCell: function (sProperty, objRowData) {
                this.cardDatagridTableCellAction(sProperty, objRowData);
            },
            cardDatagridColumnToggleChangeEvent: function () {
                this.setQueryParams();
            },
            cardDatagridColumnToggleChangeProjectionEvent: function (arrProjectionData) {
                let objProjectionQuery = {};
                for (let i = 0; i < arrProjectionData.length; i++) {
                    if (arrProjectionData[i].show) {
                        objProjectionQuery[this._getBaseProprty(arrProjectionData[i].property)] = {
                            mValue: 1,
                            bProject: true
                        };
                    }
                }

                let bMatches = a24Core.compareObjects(Ember, objProjectionQuery, this.get("_objProjectionQuery"));

                if (!bMatches) {
                    this.set("_objProjectionQuery", objProjectionQuery);
                }

                this.setQueryParams();
            },
            cardDatagridReloadButtonClickEvent: function () {
                this.doServiceCall(false);
            }
        }
    });
});
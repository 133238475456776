define("a24-ember-staffshift-core/components/email-preview-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/email-preview-popup"], function (exports, _didRenderChangesMixin, _emailPreviewPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _emailPreviewPopup.default,
        bShowPopup: null,
        bPopupLoading: null,
        onPopupCloseAction: null,
        objRestActivityEmailComm: null,
        objActivityData: null,

        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objEmailPreviewGeneratorService: Ember.inject.service("email-preview-generator"),
        _arrActions: null,

        _objEmailPreview: null,
        _objSpecialPopupAction: null,
        _objEmailPreviewCache: null,
        init: function () {
            this._super(...arguments);

            this.set("_objEmailPreviewCache", {});
        },
        _onActivityDataChange: Ember.observer("objActivityData", function () {
            let objActivityData = this.get("objActivityData");
            this.attachmentFromActivity(objActivityData.sSubjectId, objActivityData.sActivityId, objActivityData.sLinkedEntityId, objActivityData.sSubjectType);
        }),
        attachmentFromActivity: function (sSubjectId, sActivityId, sLinkedEntityId, sSubjectType) {
            let funcSuccess = objData => {
                this.set("_objEmailPreviewCache." + sActivityId, objData);

                // On success
                this.set("_objEmailPreview", {
                    sSubject: objData.subject,
                    objFrom: objData.sender,
                    objTo: objData.to_recipient,
                    arrCC: objData.cc_recipients,
                    arrBCC: objData.bcc_recipients,
                    objReplyTo: objData.reply_to,
                    sHTML: objData.body_html,
                    sDeliveryStatus: objData.delivery_status,
                    sDeliveryFailureReason: objData.delivery_failure_reason
                });

                // Create an action that will open the email in a new tab
                this.set("_objSpecialPopupAction", {
                    sTitle: this.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: () => {
                        this.get("onPopupCloseAction")();
                    }
                });
                this.set("_arrActions", [{
                    sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                    sIcon: "open_in_new",
                    executeCardAction: () => {
                        let objEmailPreview = this.get("_objEmailPreview");
                        // Generate the email html from details
                        let sHtml = this.get("_objEmailPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromEmailObject(objEmailPreview);
                        this.openHtmlInNewWindow(sHtml);
                    }
                }]);

                // Take the popup out of loading
                this.set("bPopupLoading", false);
            };

            this.set("bPopupLoading", true);

            // Use the stored response if it is set
            let objStoredResponse = this.get("_objEmailPreviewCache." + sActivityId);
            //get(objActivity, "_objStoredResponse");
            if (!a24Core.isEmpty(objStoredResponse)) {
                funcSuccess(objStoredResponse);
                return;
            }

            this.get("objRestActivityEmailComm").getEmailCommMessage(this, "_objActivityEmailCommPromise", sSubjectId, sSubjectType, sActivityId, sLinkedEntityId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.get("onPopupCloseAction")();
                this.set("bPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStringsService").getString("couldNotLoadEmailPreview"));
            }));
        },
        openHtmlInNewWindow: function (sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        actions: {
            onPopupClose: function () {
                this.get("onPopupCloseAction")();
            }
        }
    });
});
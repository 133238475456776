define("a24-ember-lib/components/card-datagrid-table", ["exports", "a24-ember-lib/templates/components/card-datagrid-table", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridTable, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridTable.default,
        // ember variables
        classNames: ["cardDatagridTable"],
        // parameters
        bDisableKeyboardShortcuts: false,
        bSelectColumn: true,
        //40 is the smallest we can go, html tr/td does not allow it to go smaller, determined from font/padding border
        //NOTE: if your content is bigger it will force the row bigger and scroll calcs will be WRONG. iRowHeight should
        //      match your biggest possible row height
        iRowHeight: null,
        iMaxHeight: null, //Min height is 100px, so max height can not be smaller
        iMinWidth: null, //Min width the datagrid is allowed to be, small screens(mobile) will get horizontal scroll
        arrHeaders: null,
        iMinColWidthDefault: null,
        objDatagridConfig: null,
        arrData: null,
        bLoading: false,
        bAllowSorting: true,
        // variables
        sSortAsc: "asc",
        sSortDesc: "desc",
        // private variables
        _funcKeyDown: null,
        _arrSelectedItems: null,
        _bAllSelected: false,
        _sSelectAllConstant: "SELECT_ALL",
        _sNoDataMessage: "",
        _objColumnWidthRecalc: null,
        _sRowHeight: null,
        _sTableInnerContainerId: null,
        _sTableContentContainerId: null,
        _sTableContentTopSpacerId: null,
        _sTableContentBottomSpacerId: null,
        _sMinWidth: null,
        _iMinHeight: 100,
        _iVisibleRecords: 0,
        _arrVisibleRecords: null,
        _objSpacersCalc: null,
        _bRenderLoad: false,
        _iPrevLeft: 0,
        _iCheckColumnWidth: 68,
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _sContextTimezone: null,
        _objRecalcColumnWidths: null,
        _iMinColWidthDefaultInternal: 150,
        _iActiveIndex: -1,
        init: function () {
            this._super(...arguments);

            this.set("_sNoDataMessage", this.get("_objStringsService").getString("noResultsFound"));

            var objThis = this;
            this.set("_sContextTimezone", this.get("_objUserSession.objContextDetails.sTimezone"));
            this.set("_sTableInnerContainerId", Ember.guidFor({}));
            this.set("_sTableContentContainerId", Ember.guidFor({}));
            this.set("_sTableContentTopSpacerId", Ember.guidFor({}));
            this.set("_sTableContentBottomSpacerId", Ember.guidFor({}));

            if (a24Core.isEmpty(this.get("arrHeaders"))) {
                this.set("arrHeaders", []);
            }
            if (a24Core.isEmpty(this.get("arrData"))) {
                this.set("arrData", []);
            }
            if (a24Core.isEmpty(this.get("_arrSelectedItems"))) {
                this.set("_arrSelectedItems", []);
            }

            this.setColumnSortIndicator();

            // Loop over the headers to create the initial query sort object
            var arrHeaders = this.get("arrHeaders");
            var objSortQuery = {};
            for (var i = 0; i < arrHeaders.length; i++) {
                if (!a24Core.isEmpty(arrHeaders[i].sAsc)) {
                    var objQueryItem = {};
                    objQueryItem.mValue = arrHeaders[i].sAsc;
                    objQueryItem.bSortBy = true;
                    objSortQuery[arrHeaders[i].sProperty] = objQueryItem;
                }
            }
            if (!a24Core.isEmpty(this.get("cardDatagridTableInitSort"))) {
                this.get("cardDatagridTableInitSort")(objSortQuery);
            }

            Ember.run.scheduleOnce("afterRender", this, function () {
                objThis._setupContainers();

                var funcKeyDown = function (objEvent) {
                    Ember.run(() => {
                        if (!objThis.get("bLoading") && !objThis.get("bDisableKeyboardShortcuts") && !Ember.$("body").hasClass("modal-open") && objEvent.target.nodeName !== "INPUT" && objEvent.target.nodeName !== "TEXTAREA") {
                            if (objEvent.keyCode === 9) {
                                var iIndex = null;

                                if (objEvent.shiftKey === true) {
                                    iIndex = objThis.get("_iActiveIndex") - 1;
                                } else {
                                    iIndex = objThis.get("_iActiveIndex") + 1;
                                }

                                if (iIndex > -1 && iIndex < objThis.get("_arrVisibleRecords.length")) {
                                    if (!a24Core.isEmpty(objThis.$(".tableCheck > .form-group").eq(objThis.get("_iActiveIndex")))) {
                                        objThis.$(".tableCheck > .form-group").eq(objThis.get("_iActiveIndex")).removeClass("is-active");
                                    }
                                    if (!a24Core.isEmpty(objThis.$(".tableCheck > .form-group").eq(iIndex))) {
                                        objEvent.preventDefault();
                                        objThis.$(".tableCheck > .form-group").eq(iIndex).addClass("is-active");
                                        objThis.set("_iActiveIndex", iIndex);
                                    }
                                }
                            } else if (objEvent.keyCode === 32) {
                                objEvent.preventDefault();
                                if (objThis.get("_iActiveIndex") > -1) {
                                    var objRowData = objThis.get("arrData").objectAt(objThis.get("_iActiveIndex"));
                                    Ember.set(objRowData, "bSelected", !objRowData.bSelected);
                                }
                            } else if (objEvent.keyCode === 13) {
                                objEvent.preventDefault();
                                var arrSelectedItems = objThis.get("_arrSelectedItems");
                                var objSingleItemAction = objThis.get("objDatagridConfig.arrSingleItemSelectActions.firstObject");
                                var objMultiItemAction = objThis.get("objDatagridConfig.arrMultiItemSelectActions.firstObject");

                                if (arrSelectedItems.length === 1 && !a24Core.isEmpty(objSingleItemAction)) {
                                    if (!a24Core.isEmpty(objSingleItemAction.funcFilterFunc)) {
                                        if (objSingleItemAction.funcFilterFunc(arrSelectedItems) === true) {
                                            objSingleItemAction.executeCardAction(arrSelectedItems);
                                        }
                                    } else {
                                        objSingleItemAction.executeCardAction(arrSelectedItems);
                                    }
                                } else if (arrSelectedItems.length > 1 && !a24Core.isEmpty(objMultiItemAction)) {
                                    if (!a24Core.isEmpty(objMultiItemAction.funcFilterFunc)) {
                                        if (objMultiItemAction.funcFilterFunc(arrSelectedItems) === true) {
                                            objMultiItemAction.executeCardAction(arrSelectedItems);
                                        }
                                    } else {
                                        objMultiItemAction.executeCardAction(arrSelectedItems);
                                    }
                                }
                            }
                        }
                    });
                };

                this.set("_funcKeyDown", funcKeyDown);

                Ember.$(window).on("keydown", this.get("_funcKeyDown"));
            });
        },
        _setupContainers: function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }

            //Set the defaults if none were supplied, seems it does not work in the init since it will go back to
            //undefined when binding it to an property on an object when that property does not exist on the object
            if (a24Core.isEmpty(this.get("iRowHeight"))) {
                this.set("iRowHeight", 40);
            }
            if (a24Core.isEmpty(this.get("iMaxHeight"))) {
                this.set("iMaxHeight", 100);
            }

            if (a24Core.isEmpty(this.get("iMinWidth"))) {
                this.set("iMinWidth", 700);
            }

            var objController = this;

            // This is used to cater for mobile but now that each column has a min width it is not longer needed
            // this.setMinWidth();

            //Set min height on the outer container
            this.$().css("min-height", this.get("_iMinHeight") + "px");

            var objTableInnerContainer = this.$("#" + this.get("_sTableInnerContainerId"));
            //Set min height on the inner container
            objTableInnerContainer.css("min-height", this.get("_iMinHeight") + "px");

            if (!this.get("bDontUseVirtualization")) {
                //Recalc the spacer heights each 200 milli seconds of scrolling
                objTableInnerContainer.scroll(function () {
                    //only do the render if we have vertical scolling
                    var iScrollLeft = objTableInnerContainer.scrollLeft();

                    //If the last left scroll matches the current we know the user scrolled vertically
                    if (iScrollLeft === objController.get("_iPrevLeft")) {
                        objController.set("_bRenderLoad", true);
                        Ember.run.cancel(objController.get("_objSpacersCalc"));
                        objController.set("_objSpacersCalc", Ember.run.later(function () {
                            objController.setSpacersHeight();
                            objController.set("_bRenderLoad", false);
                        }, 200));
                    } else {
                        objController.set("_iPrevLeft", iScrollLeft);
                    }
                });
            }

            //Recalc the column widths each 100 milli seconds as the window resizes
            this.set("_objRecalcColumnWidths", function () {
                Ember.run.cancel(objController.get("_objColumnWidthRecalc"));
                objController.set("_objColumnWidthRecalc", Ember.run.later(function () {
                    objController.setColumnWidths();
                }, 100));
            });
            $(window).resize(this.get("_objRecalcColumnWidths"));

            this.setRowHeight();
            this.setMaxHeight();
            this.setVisibleItems();
        },
        willDestroyElement: function () {
            this._super(...arguments);

            //remove the resize event listener from the window
            $(window).unbind("resize", this.get("_objRecalcColumnWidths"));
            Ember.run.cancel(this.get("_objColumnWidthRecalc"));
            Ember.run.cancel(this.get("_objSpacersCalc"));
            Ember.$(window).off("keydown", this.get("_funcKeyDown"));
        },
        setVisibleItems: Ember.observer("arrData", function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }

            //this function's observer only fires when setting arrData to NEW array e.g this.set("arrData", arrResponse)
            //it will not fire when changing individual items OR when adding or removing items from the array

            //Set scroll to top, since this will only fire when data changes and NOT when items in data changes
            //to update each items view from outside you have to replace that item in the array
            this.$("#" + this.get("_sTableInnerContainerId")).scrollTop(0);

            //Work out the max possible items that can be visible at a given time based on te max height that was set.
            var iRowHeight = this.get("iRowHeight");
            //minus one row to allow space for headers
            var iMaxPossibleContentHeight = this.get("iMaxHeight") - iRowHeight;

            //Round up to make sure we have cover entire view port and plus 2, extra one for top and bottom
            var iVisibleRecords = Math.ceil(iMaxPossibleContentHeight / iRowHeight) + 2;
            var arrData = this.get("arrData");
            if (this.get("bDontUseVirtualization")) {
                let iRowCount = arrData.length;
                // var iRowCount = this.$(`#${this.get("_sTableContentContainerId")} > table > tbody > tr`).length;
                iVisibleRecords = iRowCount + 2;
            }

            this.set("_iVisibleRecords", iVisibleRecords);

            //Add the amount of items that can be visible or add them all if there are less that the max visible items
            var arrVisibleRecords = [];
            if (arrData.length > iVisibleRecords) {
                for (var i = 0; i < iVisibleRecords; i++) {
                    arrVisibleRecords.push(arrData[i]);
                }
            } else {
                arrVisibleRecords = arrData;
            }
            this.set("_arrVisibleRecords", arrVisibleRecords);

            //Set the top spacer height
            this.$("#" + this.get("_sTableContentTopSpacerId")).height(0);

            //Set the bottom spacer height
            var iBottomSpacerHeight = (arrData.length - iVisibleRecords) * iRowHeight;
            this.$("#" + this.get("_sTableContentBottomSpacerId")).height(iBottomSpacerHeight);

            // Force a recalculation of the columns once the data has been rendered in case of any odd pixel shifts
            // caused by the new data loaded in the datagrid
            Ember.run.scheduleOnce("afterRender", this, function () {
                this.setColumnWidths();
            });
        }),
        setSpacersHeight: function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }

            var arrData = this.get("arrData");
            var iVisibleRecords = this.get("_iVisibleRecords");

            //If the data is more than the visible records we work out the new heights of the spacer items and also the
            //items that should be in view else we set the spacers to 0 and set the visible items equal to the arrData.
            if (arrData.length > iVisibleRecords) {
                var objTableInnerContainer = this.$("#" + this.get("_sTableInnerContainerId"));
                var iRowHeight = this.get("iRowHeight");
                var iCurrentTop = objTableInnerContainer.scrollTop();

                var objTableContentTopSpacer = this.$("#" + this.get("_sTableContentTopSpacerId"));
                var objTableContentBottomSpacer = this.$("#" + this.get("_sTableContentBottomSpacerId"));

                //We floor here to get the whole amount of rows that are on top and also minus one row since we have one
                //grace row at the top. This gives us the amount of items that can fit into the top spacer item
                var iTopItemsAmount = Math.floor(iCurrentTop / iRowHeight) - 1;

                if (iTopItemsAmount < 0) {
                    //If the iTopItemsAmount is smaller that 0 we know we are at the top and set the top spacer to 0px
                    iTopItemsAmount = 0;
                } else if (iTopItemsAmount + iVisibleRecords > arrData.length) {
                    //If the top items + allowed visible items are bigger that the actual amount of items we know
                    //we are at the bottom of the container and that the top spacer should be actual amount of items
                    //minus visible items
                    iTopItemsAmount = arrData.length - iVisibleRecords;
                }

                var iTopSpacerHeight = iTopItemsAmount * iRowHeight;
                var iBottomSpacerHeight = (arrData.length - iTopItemsAmount - iVisibleRecords) * iRowHeight;

                objTableContentTopSpacer.height(iTopSpacerHeight);
                objTableContentBottomSpacer.height(iBottomSpacerHeight);

                //Visible record would be the amount from top and to the amount from top plus items that should be visible
                this.set("_arrVisibleRecords", arrData.slice(iTopItemsAmount, iTopItemsAmount + iVisibleRecords));
            } else {
                this.$("#" + this.get("_sTableContentTopSpacerId")).height(0);
                this.$("#" + this.get("_sTableContentBottomSpacerId")).height(0);

                this.set("_arrVisibleRecords", arrData);
            }
        },
        setRowHeight: function () {
            if (this.get("iRowHeight") < 40) {
                this.set("iRowHeight", 40);
            }
            this.set("_sRowHeight", new Ember.String.htmlSafe("height: " + this.get("iRowHeight") + "px;"));
        },
        setMaxHeight: function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }
            if (this.get("iMaxHeight") < this.get("_iMinHeight")) {
                this.set("iMaxHeight", this.get("_iMinHeight"));
            }

            //Minus one row to allow space for the headers
            this.$("#" + this.get("_sTableContentContainerId")).css("max-height", this.get("iMaxHeight") - this.get("iRowHeight") + "px");
        },
        setMinWidth: function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }
            this.set("_sMinWidth", new Ember.String.htmlSafe("min-width: " + this.get("iMinWidth") + "px;"));

            this.$("#" + this.get("_sTableContentContainerId")).css("min-width", this.get("iMinWidth") + "px");
            //We need the DOM to update with the new min width before recalcing the column widths
            var objController = this;
            Ember.run.schedule("actions", function () {
                objController.setColumnWidths();
            });
        },
        _getParentWidthWithColumnMinWidthShortage: function (arrHeaders, iParentWidth, iTotalPercentage, iPrevShortage) {
            if (a24Core.isEmpty(iPrevShortage)) {
                iPrevShortage = 0;
            }
            let iMinWidthShortage = 0;
            for (let i = 0; i < arrHeaders.length; i++) {
                //We round down here. Since this is html table the last column will take up whatever pixels were left
                // We -2 since we have borders on the table
                // Set column width for columns which are not silent
                if (!arrHeaders[i].bSilent) {
                    let iColumnWidth = Math.floor(iParentWidth / iTotalPercentage * arrHeaders[i].iWidth) - 2;

                    var iMinWidth = 0;
                    if (a24Core.isEmpty(arrHeaders[i].iMinWidth)) {
                        if (a24Core.isEmpty(this.get("iMinColWidthDefault"))) {
                            iMinWidth = this.get("_iMinColWidthDefaultInternal");
                        } else {
                            iMinWidth = this.get("iMinColWidthDefault");
                        }
                    } else {
                        iMinWidth = arrHeaders[i].iMinWidth;
                    }
                    if (iColumnWidth < iMinWidth) {
                        iMinWidthShortage = iMinWidthShortage + (iMinWidth - iColumnWidth);
                    }
                }
            }

            var iNewParentWidth = iParentWidth - (iMinWidthShortage - iPrevShortage);
            if (iNewParentWidth < 0) {
                iNewParentWidth = 0;
            }

            if (iNewParentWidth === iParentWidth) {
                return iNewParentWidth;
            } else {
                /**
                 * We need to do the calc again if any of the columns reached their min width because that
                 * means the total space available has become less than expected. E.g if the parent width
                 * was 1000px and one of the column should have been 100px but its min width is 200px, it
                 * means that it will be using 100px more than expected, thus only leaving 900px for the
                 * rest of the columns to use and now since the available width went from 1000px to 900px
                 * it means that some of the other columns might have also reached min width and take up
                 * more than expected. Thus the recursive call until the iNewParentWidth matches the
                 * prev calculated one
                */
                return this._getParentWidthWithColumnMinWidthShortage(arrHeaders, iNewParentWidth, iTotalPercentage, iMinWidthShortage);
            }
        },
        setColumnWidths: function () {
            if (a24Core.isEmpty(this.$("#" + this.get("_sTableInnerContainerId")))) {
                //This means the elements are not in the DOM yet and thus we should not do anything yet
                return;
            }

            //Get the parent width
            var iParentWidth = this.$("#" + this.get("_sTableContentContainerId")).width();

            if (this.get("bSelectColumn")) {
                iParentWidth = iParentWidth - this.get("_iCheckColumnWidth") - 2; //minus the checkbox column width
            }

            //Work out how much each column needs to take up in PX
            var arrHeaders = this.get("arrHeaders");

            var iTotalPercentage = 0;
            for (var j = 0; j < arrHeaders.length; j++) {
                if (arrHeaders[j].bShowHeader && !arrHeaders[j].bSilent) {
                    iTotalPercentage += arrHeaders[j].iWidth;
                }
            }
            if (iTotalPercentage > 100) {
                iTotalPercentage = 100;
            }

            iParentWidth = this._getParentWidthWithColumnMinWidthShortage(arrHeaders, iParentWidth, iTotalPercentage);

            // Starts at 1 due to the column for the select boxes else it starts on 0
            let iVisableCount = this.get("bSelectColumn") ? 1 : 0;
            for (var i = 0; i < arrHeaders.length; i++) {
                //We round down here. Since this is html table the last column will take up whatever pixels were left
                // We -2 since we have borders on the table
                // Set column width for columns which are not silent
                if (!arrHeaders[i].bSilent) {
                    var iColumnWidth = Math.floor(iParentWidth / iTotalPercentage * arrHeaders[i].iWidth) - 2;
                    var iMinWidth = 0;

                    if (a24Core.isEmpty(arrHeaders[i].iMinWidth)) {
                        if (a24Core.isEmpty(this.get("iMinColWidthDefault"))) {
                            iMinWidth = this.get("_iMinColWidthDefaultInternal");
                        } else {
                            iMinWidth = this.get("iMinColWidthDefault");
                        }
                    } else {
                        iMinWidth = arrHeaders[i].iMinWidth;
                    }
                    if (iColumnWidth < iMinWidth) {
                        iColumnWidth = iMinWidth;
                    }
                    if (this.get("bDontUseVirtualization") && arrHeaders[i].bWrapText) {
                        let funcRunOnce = (iIndex, iCount) => {
                            let objTableContent = this.$(`#${this.get("_sTableContentContainerId")} > table > tbody > tr:eq(1) > td:eq(${iCount})`).not(".cardDatagridTableSpacer");

                            let iTableContentWidth = iColumnWidth;
                            if (!a24Core.isEmpty(objTableContent)) {
                                iTableContentWidth = objTableContent.width();
                            }

                            this.set("arrHeaders." + iIndex + ".sCalcWidth", new Ember.String.htmlSafe("width: " + iTableContentWidth + "px;"));
                            this.set("arrHeaders." + iIndex + ".sCalcMaxWidth", new Ember.String.htmlSafe("max-width: " + iTableContentWidth + "px;"));
                        };
                        let funcNext = (iIndex, iCount) => {
                            Ember.run.scheduleOnce("afterRender", this, funcRunOnce, iIndex, iCount);
                        };
                        Ember.run.next(funcNext, i, iVisableCount);
                    } else {
                        this.set("arrHeaders." + i + ".sCalcWidth", new Ember.String.htmlSafe("width: " + iColumnWidth + "px;"));
                        this.set("arrHeaders." + i + ".sCalcMaxWidth", new Ember.String.htmlSafe("max-width: " + iColumnWidth + "px;"));
                    }
                    iVisableCount++;
                }
            }
        },
        setWidthsOnShowHeader: Ember.observer("arrHeaders.@each.bShowHeader", function () {
            Ember.run.once(this, "setColumnWidths");
        }),
        repopulateSelectedItems: Ember.observer("arrData.@each.bSelected", function () {
            Ember.run.once(this, this.repopulateSelectedItemsLogic);
        }),
        onLoadingChange: Ember.observer("bLoading", function () {
            if (this.get("bLoading")) {
                this.set("_iActiveIndex", -1);
            }
        }),
        repopulateSelectedItemsLogic: function () {
            // Repopulate selected items
            // Start with an empty array
            var arrSelectedItems = [];
            this.get("arrData").forEach(function (objDataItem) {
                // If items selected, add item to the array
                if (Ember.get(objDataItem, "bSelected")) {
                    arrSelectedItems.push(objDataItem);
                }
            });

            // set the selected items back on the array
            this.set("_arrSelectedItems", arrSelectedItems);
            // if the amount of selected items are the same as the amount of total items
            if (arrSelectedItems.length === this.get("arrData").length) {
                if (this.get("arrData").length === 0) {
                    // Unselect "select all" checkbox if no items are in the datagrid
                    this.set("_bAllSelected", false);
                } else {
                    // Select "select all" checkbox if all items are checked
                    this.set("_bAllSelected", true);
                }
            } else {
                // Unselect "select all" checkbox if the selected items are less than the data items
                this.set("_bAllSelected", false);
            }

            // If the datagrid is not in loading state and it is a selection column
            if (!this.get("bLoading") && this.get("bSelectColumn")) {
                // Send the appropriate event
                var iCountSelected = this.get("_arrSelectedItems").length;
                if (iCountSelected === 0) {
                    // No items selected
                    if (!a24Core.isEmpty(this.get("cardDatagridTableNoneSelected"))) {
                        this.get("cardDatagridTableNoneSelected")();
                    }
                } else if (iCountSelected === 1) {
                    // One item selected
                    if (!a24Core.isEmpty(this.get("cardDatagridTableOneSelected"))) {
                        this.get("cardDatagridTableOneSelected")(arrSelectedItems);
                    }
                } else {
                    // Multi items selected
                    if (!a24Core.isEmpty(this.get("cardDatagridTableMultiSelected"))) {
                        this.get("cardDatagridTableMultiSelected")(arrSelectedItems);
                    }
                }
            }
        },
        setColumnSortIndicator: function () {
            var objController = this;
            objController.get("arrHeaders").forEach(function (objHeaderItem) {
                //if the sAsc is set we know we can set a sort on the column else clear the sort bools
                if (!a24Core.isEmpty(Ember.get(objHeaderItem, "sAsc"))) {
                    if (Ember.get(objHeaderItem, "sAsc") === objController.get("sSortAsc")) {
                        Ember.set(objHeaderItem, "bAsc", true);
                        Ember.set(objHeaderItem, "bDesc", false);
                    } else {
                        Ember.set(objHeaderItem, "bAsc", false);
                        Ember.set(objHeaderItem, "bDesc", true);
                    }
                } else {
                    // otherwise we clear the sort on the header
                    Ember.set(objHeaderItem, "bAsc", false);
                    Ember.set(objHeaderItem, "bDesc", false);
                }
            });
        },
        sortObserver: Ember.observer("arrHeaders.@each.sAsc", function () {
            // redo the sort on the columns
            Ember.run.once(this, this.setColumnSortIndicator);
        }),
        isHeaderClickable: function (sHeaderTitle) {
            var arrHeaders = this.get("arrHeaders");
            for (var i = 0; i < arrHeaders.length; i++) {
                var objHeader = arrHeaders[i];
                if (!a24Core.isEmpty(objHeader.sProperty) && objHeader.sProperty === sHeaderTitle) {
                    return objHeader.bIsClickable;
                }
            }
            return false;
        },
        actions: {
            columnHeaderClicked: function (sColumnReference, sAscendingOrder) {
                // If not in loading state, do the column header click
                var bAllowSort = !this.get("bLoading") && this.get("bAllowSorting");

                // Check if the clicked column allows sorting
                this.get("arrHeaders").forEach(function (objHeaderItem) {
                    if (Ember.get(objHeaderItem, "sProperty") === sColumnReference) {
                        if (Ember.get(objHeaderItem, "bStopColumnSort")) {
                            // This column may not sort
                            bAllowSort = false;
                        }
                    }
                });

                // Check if the sort is allowed
                if (bAllowSort) {
                    var sSort = this.get("sSortAsc");
                    // If the sort is not empty
                    if (!a24Core.isEmpty(sAscendingOrder)) {
                        // flip if asc
                        if (sAscendingOrder === this.get("sSortAsc")) {
                            sSort = this.get("sSortDesc");
                        }
                        // If desc, it will still be set as asc
                    }

                    this.get("arrHeaders").forEach(function (objHeaderItem) {
                        if (Ember.get(objHeaderItem, "sProperty") === sColumnReference) {
                            // If the column property matches the column reference, set the sort
                            Ember.set(objHeaderItem, "sAsc", sSort);
                        } else {
                            // otherwise we clear the sort on a column
                            Ember.set(objHeaderItem, "sAsc", null);
                        }
                    });

                    this.get("arrData").forEach(function (objDataItem) {
                        Ember.set(objDataItem, "bSelected", false);
                    });

                    //Build up query param object that can be used in url call
                    var objSortQuery = {};
                    var objQueryItem = {};
                    objQueryItem.mValue = sSort;
                    objQueryItem.bSortBy = true;
                    objSortQuery[sColumnReference] = objQueryItem;

                    if (!a24Core.isEmpty(this.get("cardDatagridTableSort"))) {
                        return this.get("cardDatagridTableSort")(sColumnReference, objSortQuery);
                    }
                }
            },
            onCheckBoxClicked: function (iIndex, mValue) {
                // If not in loading state, do check box click events
                if (!this.get("bLoading")) {
                    // If a checkbox is clicked
                    if (mValue === this.get("_sSelectAllConstant")) {
                        // If the select all constant is used
                        if (this.get("arrData").length !== 0) {

                            // If there is data
                            var arrData = this.get("arrData");
                            var arrSelectedItems = this.get("_arrSelectedItems");
                            // This selection is based on whether all items are currently selected
                            var bSelection = arrData.length !== arrSelectedItems.length;

                            arrData.setEach("bSelected", bSelection);
                            this.set("_iActiveIndex", -1);
                        }
                    } else {
                        this.$(".tableCheck > .form-group").eq(this.get("_iActiveIndex")).removeClass("is-active");
                        this.set("_iActiveIndex", iIndex);
                    }
                }
            },
            cellClicked: function (sProperty, objRowData) {
                if (this.isHeaderClickable(sProperty)) {
                    //First unselect all selected items
                    this.get("arrData").setEach("bSelected", false);

                    // If the cell was clicked, tick the related check box as well and make the row selected.
                    Ember.set(objRowData, "bSelected", true);
                }

                // If not in loading state
                if (!this.get("bLoading")) {
                    // Dispatch event to parent
                    if (!a24Core.isEmpty(this.get("cardDatagridTableCell"))) {
                        return this.get("cardDatagridTableCell")(sProperty, objRowData);
                    }
                }
            }
        }
    });
});
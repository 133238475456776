define("a24-ember-staffshift-core/services/conversation-preview-generator", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objSSCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),

        /**
         * Will generate the entire page for the conversations
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return An html string
         */
        generateFullHtmlWithHTMLWrappingFromConversation: function (objConversation) {
            let sHtml = `
            <html>
                <head>
                    <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/font.css">
                    <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/style.css">
                </head>
                <body>
                    ${this.generateFullHtmlFromConversation(objConversation)}
                </body>
            </html>
        `;

            return sHtml;
        },

        /**
         * Will generate the HTML for the stylesheet
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  8 Jan 2020
         *
         * @return An html string
         */
        generateFullHtmlForStyleSheet: function () {
            let sHtml = `
            <link rel="stylesheet" type="text/css" href="/assets/a24-ember-staffshift-core/conversation-preview/style.css">
        `;

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        /**
         * Will generate the HMTL for the conversations
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return An html safe object
         */
        generateFullHtmlFromConversation: function (objConversation) {
            let arrMessages = [];
            let bConversationNoData = false;
            if (objConversation.status !== "open") {
                if (!a24Core.isEmpty(objConversation.message_listing)) {
                    arrMessages = objConversation.message_listing.map(objMessage => {
                        let sMemberType = "";
                        let objParticipant = objConversation.participant_listing.findBy("participant_id", objMessage.participant_id);
                        if (!a24Core.isEmpty(objParticipant) && !a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.member_type)) {
                            sMemberType = objParticipant.attributes.member_type;
                        }
                        return this._generateHTMLForMessage(objMessage, sMemberType, objParticipant, objConversation);
                    });
                } else {
                    bConversationNoData = true;
                }
            } else {
                return Ember.String.htmlSafe(this.get("_objSSCoreStrings").getString("conversationNoPreview"));
            }

            let sHeaderHtml = this._generateHTMLForConversationHeader(objConversation);

            let sMessageHtml = "";
            if (bConversationNoData) {
                sMessageHtml = Ember.String.htmlSafe(this.get("_objSSCoreStrings").getString("noMessages"));
            } else {
                sMessageHtml = `<div class="chat-messages-wrapper${a24Core.isEmpty(sHeaderHtml) ? " full-height" : ""}">
                    <div class="chat-messages">
                        <ul class="messages-list">
                            ${arrMessages.join("")}
                        </ul>
                    </div>
                </div>`;
            }

            let sHtml = `
            <div class="a24-conversation-preview">
                ${sHeaderHtml}
                ${sMessageHtml}
            </div>
        `;

            // Return a safe version of the html
            return Ember.String.htmlSafe(sHtml);
        },

        /**
         * Generate a single DOM Node for a conversation header
         *
         * @param object objConversation - The conversation object
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  12 Dec 2019
         *
         * @return A DOM Node for conversation header
         */
        _generateHTMLForConversationHeader: function (objConversation) {
            let objCreatedDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
            objCreatedDate.setDateFromRest(objConversation.created_at);

            var arrParticipants = objConversation.participant_listing.reduce((arrMapping, objParticipant) => {
                if (objParticipant.attributes.member_type === "chatterbox") {
                    if (!a24Core.isEmpty(objConversation.chatterbox_profiles) && (objParticipant.attributes.medium === "sms" || objParticipant.attributes.medium === "whatsapp")) {
                        arrMapping.push({
                            sDisplayName: objConversation.chatterbox_profiles[0].entity_name,
                            sUserId: objConversation.chatterbox_profiles[0].user_id ? objConversation.chatterbox_profiles[0].user_id : objConversation.chatterbox_profiles[0]._id
                        });
                    } else if (!a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.authorDisplayName)) {
                        arrMapping.push({
                            sDisplayName: objParticipant.attributes.authorDisplayName,
                            sUserId: objParticipant.user_id
                        });
                    }
                } else {
                    var objMessage = objConversation.message_listing.findBy("participant_id", objParticipant.participant_id);
                    if (!a24Core.isEmpty(objMessage)) {
                        if (objParticipant.attributes.member_type === "bot") {
                            let objBot = objConversation.bots.findBy("_id", objParticipant.identity);
                            arrMapping.push({
                                sDisplayName: !a24Core.isEmpty(objBot) ? objBot.name : "Shai",
                                sUserId: objConversation.identity
                            });
                        } else if (!a24Core.isEmpty(objParticipant.attributes) && !a24Core.isEmpty(objParticipant.attributes.authorDisplayName)) {
                            arrMapping.push({
                                sDisplayName: objParticipant.attributes.authorDisplayName,
                                sUserId: objParticipant.user_id
                            });
                        }
                    }
                }
                return arrMapping;
            }, []);

            // We need to remove duplicates thus this filter
            var objSeen = {};
            arrParticipants = arrParticipants.filter(objParticipant => {
                // Have we seen this user before?
                if (objSeen.hasOwnProperty(objParticipant.sUserId)) {
                    // Don't keep this entry
                    return false;
                }
                // Remember that we've seen it
                objSeen[objParticipant.sUserId] = objParticipant;
                // Keep this one
                return true;
            });

            let arrParticipantNames = arrParticipants.map(objParticipant => {
                return objParticipant.sDisplayName;
            });

            let sClosedDateRow = "";

            if (objConversation.status === this.get("_objSSCoreEnums.conversationStatus.CLOSED.backend") && !a24Core.isEmpty(objConversation.updated_at)) {
                let objClosedDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objClosedDate.setDateFromRest(objConversation.updated_at);

                sClosedDateRow = this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("closedDate") + ":", objClosedDate.getDateTimeFormat());
            }

            let arrRows = [this._generateHeaderCellHtml(this.get("_objFormInputsStringsService").getString("startDate") + ":", objCreatedDate.getDateTimeFormat()), sClosedDateRow, this._generateHeaderCellHtml(this.get("_objStringsService").getString("status") + ":", this.get("_objSSCoreEnums").getFrontendValue("conversationStatus", objConversation.status))];

            if (!a24Core.isEmpty(objConversation.team) && !a24Core.isEmpty(objConversation.team.team_name)) {
                arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("team") + ":", objConversation.team.team_name));
            }

            arrRows.push(this._generateHeaderCellHtml(this.get("_objSSCoreStrings").getString("participants") + ":", arrParticipantNames.join(", ")));

            return `
            <div class="a24-conversation-heading-block">
                <table class="a24-conversation-details-table">${arrRows.join("")}
                </table>
            </div>
        `;
        },

        /**
         * Generates a single line for the conversation heading details
         *
         * @param string sLabel - The label to display
         * @param string sValue - The value associated with the label
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  17 Dec 2019
         *
         * @return An html string
         */
        _generateHeaderCellHtml(sLabel, sValue) {
            return `
            <tr>
                <td class="a24-conversation-details-label">${sLabel}</td>
                <td class="a24-conversation-details-value">${sValue}</td>
            </tr>`;
        },

        /**
         * Generate a single DOM Node for avater
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  12 Dec 2019
         *
         * @return A DOM Node for avatar
         */
        _generateHTMLForAvatar: function (displayName, sMemberType) {
            return `
            <div class="avater a24-conversation-preview-tooltip">
                <div class="avater-placeholder">
                    <i class="material-icons">${sMemberType === "bot" ? "android" : "person"}</i>
                </div>${displayName ? `<span class="tooltiptext">${displayName}</span>` : ""}
            </div>
        `;
        },

        /**
         * Generate a single DOM Node for a conversation message
         *
         * @param object objMessage - A conversation message object
         * {
         *     "participant_id": "5de7dce6bc0ea0cce8869349",
         *     "body": "helop\n",
         *     "type": "text",
         *     "created_at": "2019-12-06T09:25:47.000Z",
         *     "attributes": {
         *        "memberType": "chatterbox"
         *      }
         * }
         * @param string sType - The conversation type
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  9 Dec 2019
         *
         * @return A DOM Node for chat message
         */
        _generateHTMLForMessage(objMessage, sType, objParticipant, objConversation) {
            let sTimeAgo = momentHelper.getTimeFromNow(objMessage.created_at, a24Constants.DATE_FORMAT_ISO_FORMAT);
            let sDisplayName = null;
            if (!a24Core.isEmpty(objParticipant) && !a24Core.isEmpty(objParticipant.attributes)) {
                sDisplayName = objParticipant.attributes.authorDisplayName;
            }

            if (!a24Core.isEmpty(objConversation.chatterbox_profiles) && objParticipant.attributes.member_type === "chatterbox" && (objParticipant.attributes.medium === "sms" || objParticipant.attributes.medium === "whatsapp")) {
                sDisplayName = objConversation.chatterbox_profiles[0].entity_name;
            }

            if (objParticipant.attributes.member_type === "bot") {
                let objBot = objConversation.bots.findBy("_id", objParticipant.identity);
                sDisplayName = !a24Core.isEmpty(objBot) ? objBot.name : "Shai";
            }

            let sNode = `
            <li class="chat-message ${sType}">
                <div class="bubble">${objMessage.body}</div>
                ${this._generateHTMLForAvatar(sDisplayName, objParticipant.attributes.member_type)}
                <span class="subtext">${sTimeAgo}</span>
            </li>
        `;

            return sNode;
        }
    });
});
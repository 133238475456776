define("a24-ember-lib/components/tag-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/tag-component"], function (exports, _didRenderChangesMixin, _tagComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tagComponent.default,
        classNames: ["display-inline-block"],
        sTitle: "",
        mValue: null,
        sColor: "",
        sActionIcon: "",
        sActionIconColor: "",
        bHasClear: false,
        sClearColor: "",

        actions: {
            onTagClicked: function (sTitle, mValue) {
                if (!a24Core.isEmpty(this.get("onTagClicked"))) {
                    return this.get("onTagClicked")(sTitle, mValue);
                }
            },

            onClearTag: function (sTitle, mValue) {
                if (!a24Core.isEmpty(this.get("onClearTag"))) {
                    return this.get("onClearTag")(sTitle, mValue);
                }
            }
        }
    });
});
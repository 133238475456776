define("a24-ember-staffshift-core/components/chatter-right-header", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-right-header"], function (exports, _didRenderChangesMixin, _chatterRightHeader) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chatterRightHeader.default,
        classNames: ["a24-chatter-right-header", "position-relative"],
        objData: null,

        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),

        _sFirstLetter: "",
        _sAvatarIcon: "",

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        didReceiveAttrs() {
            this._super(...arguments);
            this._setFirstLetterAndAvatar();
        },

        _sName: Ember.computed("objData.{ss.user,chatInbox.friendlyName}", function () {
            if (!a24Core.isEmpty(this.get("objData.ss.user"))) {
                return this.get("objData.ss.user.first_name") + " " + this.get("objData.ss.user.last_name");
            } else {
                return this.get("objData.chatInbox.friendlyName");
            }
        }).readOnly(),

        _sIcon: Ember.computed("objData.chatInbox.medium", function () {
            if (this.get("objData.chatInbox.medium") === "sms") {
                return "phone";
            } else if (this.get("objData.chatInbox.medium") === "whatsapp") {
                return null;
            } else {
                return "person";
            }
        }).readOnly(),

        _sIconFa: Ember.computed("objData.chatInbox.medium", function () {
            if (this.get("objData.chatInbox.medium") === "whatsapp") {
                return "fa-whatsapp";
            }
        }).readOnly(),

        _sDotColor: Ember.computed("objData.chatInbox.online", function () {
            if (typeof this.get("objData.chatInbox.online") === "undefined") {
                return "display-none";
            } else {
                return this.get("objData.chatInbox.online") ? "success-color" : "grey";
            }
        }).readOnly(),

        _sAvatar: Ember.computed("objData.ss.user.avatar", function () {
            return a24.getUserContextAvatarUrl(this.get("_ENV.sTriageBaseUrl"), this.get("_objConstantsSSCore"), this.get("objData.ss.user.avatar"));
        }).readOnly(),

        _sStatus: Ember.computed("objData.ember_candidate.current_status.status", function () {
            if (a24Core.isEmpty(this.get("objData.ember_candidate"))) {
                return null;
            } else if (a24Core.isEmpty(this.get("objData.ember_candidate.current_status"))) {
                return "...";
            } else if (a24Core.isEmpty(this.get("objData.ember_candidate.current_status.status"))) {
                return "-";
            } else {
                return this.get("objData.ember_candidate.current_status.status");
            }
        }).readOnly(),

        _setFirstLetterAndAvatar() {
            this.set("_sFirstLetter", "");
            this.set("_sAvatarIcon", "");
            if (!a24Core.isEmpty(this.get("objData.ss.user.first_name"))) {
                this.set("_sFirstLetter", this.get("objData.ss.user.first_name")[0].toUpperCase());
            } else if (!a24Core.isEmpty(this.get("objData.chatInbox.friendlyName"))) {
                if (!this.get("objData.hasUser")) {
                    if (this.get("objData.chatInbox.medium") === "sms") {
                        this.set("_sAvatarIcon", "phone");
                        this.set("_sAvatarIconFa", null);
                    } else if (this.get("objData.chatInbox.medium") === "whatsapp") {
                        this.set("_sAvatarIcon", null);
                        this.set("_sAvatarIconFa", "fa-whatsapp");
                    } else if (this.get("objData.chatInbox.friendlyName")[0] === "+") {
                        this.set("_sAvatarIcon", "phone");
                        this.set("_sAvatarIconFa", null);
                    } else {
                        this.set("_sFirstLetter", this.get("objData.chatInbox.friendlyName")[0].toUpperCase());
                    }
                } else {
                    this.set("_sFirstLetter", this.get("objData.chatInbox.friendlyName")[0].toUpperCase());
                }
            }
        }
    });
});
define("a24-ember-staffshift-core/services/react-ember-navigation", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _getCurrentDomain: function () {
            return window.location.origin;
        },
        _openInNewTab: function (sUrl) {
            window.open(sUrl, "_blank");
        },

        /**
         * Handles a navigation message
         *
         * @param objData - the data object containing message information
         */
        handleNavigationMessage: function (objData) {
            if (objData.type === "navigate_within_context") {
                var sRouteWithinContext = objData.data.route;
                var objParams = null;
                if (!a24Core.isEmpty(objData.data.params)) {
                    objParams = objData.data.params;
                }
                var objQueryParams = null;
                if (!a24Core.isEmpty(objData.data.queryParams)) {
                    objQueryParams = objData.data.queryParams;
                }
                var bReturnUrl = false;
                var bKeepHistory = true;
                if (!a24Core.isEmpty(objData.data.returnUrl) && objData.data.returnUrl) {
                    bReturnUrl = true;
                    var sUrlWithin = this.get("_objNavigation").navigateWithinContext(sRouteWithinContext, objParams, objQueryParams, bReturnUrl, bKeepHistory);

                    if (sUrlWithin.charAt(0) === '#') {
                        sUrlWithin = this._getCurrentDomain() + "/" + sUrlWithin;
                    }

                    var objReturn = {
                        type: objData.type + "_response",
                        data: {
                            url: sUrlWithin
                        },
                        requestId: objData.requestId
                    };
                    return objReturn;
                }
                if (!a24Core.isEmpty(objData.data.openInNewTab) && objData.data.openInNewTab) {
                    bReturnUrl = true;
                    var sUrlWithinContext = this.get("_objNavigation").navigateWithinContext(sRouteWithinContext, objParams, objQueryParams, bReturnUrl, bKeepHistory);
                    this._openInNewTab(sUrlWithinContext);
                } else {
                    if (!a24Core.isEmpty(objData.data.keepHistory)) {
                        bKeepHistory = objData.data.keepHistory;
                    }
                    this.get("_objNavigation").navigateWithinContext(sRouteWithinContext, objParams, objQueryParams, bReturnUrl, bKeepHistory);
                }
            } else if (objData.type === "navigate_outside_context") {
                var sRouteOutsideContext = objData.data.route;
                var objParamsOutsideContext = null;
                if (!a24Core.isEmpty(objData.data.params)) {
                    objParamsOutsideContext = objData.data.params;
                }
                var objQueryParamsOutsideContext = null;
                if (!a24Core.isEmpty(objData.data.queryParams)) {
                    objQueryParamsOutsideContext = objData.data.queryParams;
                }
                var bEmberMainDomain = false;
                if (!a24Core.isEmpty(objData.data.emberMainDomain) && objData.data.emberMainDomain) {
                    bEmberMainDomain = objData.data.emberMainDomain;
                }

                var bReturnUrlOutsideContext = false;
                if (!a24Core.isEmpty(objData.data.returnUrl) && objData.data.returnUrl) {
                    bReturnUrlOutsideContext = true;
                    var sUrlOutside = this.get("_objNavigation").navigateOutsideContext(sRouteOutsideContext, objParamsOutsideContext, objQueryParamsOutsideContext, bReturnUrlOutsideContext, bEmberMainDomain);

                    if (sUrlOutside.charAt(0) === '#') {
                        sUrlOutside = this._getCurrentDomain() + "/" + sUrlOutside;
                    }
                    var objReturnOutsideContext = {
                        type: objData.type + "_response",
                        data: {
                            url: sUrlOutside
                        },
                        requestId: objData.requestId
                    };

                    return objReturnOutsideContext;
                }
                if (!a24Core.isEmpty(objData.data.openInNewTab) && objData.data.openInNewTab) {
                    bReturnUrlOutsideContext = true;
                    var sUrlOutsideContext = this.get("_objNavigation").navigateOutsideContext(sRouteOutsideContext, objParamsOutsideContext, objQueryParamsOutsideContext, bReturnUrlOutsideContext, bEmberMainDomain);
                    this._openInNewTab(sUrlOutsideContext);
                } else {
                    this.get("_objNavigation").navigateOutsideContext(sRouteOutsideContext, objParamsOutsideContext, objQueryParamsOutsideContext, bReturnUrlOutsideContext, bEmberMainDomain);
                }
            } else if (objData.type === "navigate_gwt_login") {
                var bKeepHistoryGwtLogin = false;
                if (!a24Core.isEmpty(objData.data.keepHistory) && objData.data.keepHistory) {
                    bKeepHistoryGwtLogin = objData.data.keepHistory;
                }
                var bRegisterPage = false;
                if (!a24Core.isEmpty(objData.data.registerPage) && objData.data.registerPage) {
                    bRegisterPage = true;
                }

                //Cant support return url or open in new tab since that will bypass the bNavigatingToGWT which is used
                //to unload intercom and firebase where needed

                this.get("_objNavigation").navigateToGWTLogin(bRegisterPage, bKeepHistoryGwtLogin);
            } else if (objData.type === "navigate_gwt_context") {
                var sContextId = objData.data.contextId;
                var bKeepHistoryGwt = false;
                if (!a24Core.isEmpty(objData.data.keepHistory) && objData.data.keepHistory) {
                    bKeepHistoryGwt = objData.data.keepHistory;
                }

                //Cant support return url or open in new tab since that will bypass the bNavigatingToGWT which is used
                //to unload intercom and firebase where needed

                this.get("_objNavigation").navigateToGWTContext(sContextId, bKeepHistoryGwt);
            } else if (objData.type === "navigate_gwt_page") {
                var sUrl = objData.data.route;
                var bKeepHistoryGwtPage = false;
                if (!a24Core.isEmpty(objData.data.keepHistory) && objData.data.keepHistory) {
                    bKeepHistoryGwtPage = objData.data.keepHistory;
                }

                if (!a24Core.isEmpty(objData.data.returnUrl) && objData.data.returnUrl) {
                    var sGwtUrl = this.get("_objNavigation").navigateToGWTPage(sUrl, bKeepHistoryGwtPage, true);

                    return {
                        type: objData.type + "_response",
                        data: {
                            url: sGwtUrl
                        },
                        requestId: objData.requestId
                    };
                }

                //Cant support return url or open in new tab since that will bypass the bNavigatingToGWT which is used
                //to unload intercom and firebase where needed

                this.get("_objNavigation").navigateToGWTPage(sUrl, bKeepHistoryGwtPage);
            } else if (objData.type === "navigate_back") {
                this.get("_objNavigation").navigateBack();
            }
        }
    });
});
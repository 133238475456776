define("a24-ember-lib/services/post-code-validator", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),

        validatePostCode: function (sPostCode, sCountryCode) {
            if (a24Core.isEmpty(sCountryCode)) {
                sCountryCode = "GB";
            }

            switch (sCountryCode.toLowerCase()) {
                case "uk":
                case "gb":
                    return this.validateUkPostCode(sPostCode);
                case "us":
                    return this.validateUsPostCode(sPostCode);
                default:
                    return true;
            }
        },

        validateUsPostCode: function (sPostCode) {
            var objRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

            if (objRegex.test(sPostCode)) {
                return true;
            } else {
                return this.get("_objEmberLibStringService").getString("validationUsPostCode");
            }
        },

        validateUkPostCode: function (sPostCode) {
            //Change All Characters to Upper
            sPostCode = String(sPostCode).trim().toUpperCase();

            //First Checks for Special Characters
            if (!this.checkSpecialCharacters(sPostCode)) {
                //Split PostCode into Part 1 and Part 2
                var arrPostCodeParts = sPostCode.split(/[ ]+/); // You can make this better if you like
                var sPostCode2 = "";
                var sPostCode1 = "";

                if (arrPostCodeParts.length > 1) {
                    sPostCode2 = arrPostCodeParts[1];
                    sPostCode1 = arrPostCodeParts[0];
                } else {
                    sPostCode2 = sPostCode.substring(sPostCode.length - 3, sPostCode.length);
                    sPostCode1 = sPostCode.substring(0, sPostCode.length - 3);
                }

                if (sPostCode1.length > 4) {
                    return this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeTooManyCharacters");
                }

                var sValidPostCode1 = this.validateFirstPartPostcode(sPostCode1);
                var sValidPostCode2 = this.validateSecondPartPostcode(sPostCode2);
                //Check Error and validation for first part of post code
                if (sValidPostCode1 === sPostCode1) {
                    //Check Error and validation for P2
                    if (sValidPostCode2 === sPostCode2) {
                        //Return true if both Parts of PostCode contains no Errors
                        return true;
                    } else {
                        //Return Error message for P2 cause P1 passed
                        return sValidPostCode2;
                    }
                } else {
                    //Return Error message for P1
                    return sValidPostCode1;
                }
            } else {
                //Display Error message for Special Characters
                return this.get("_objEmberLibStringService").getString("validationPostCodeHasSpecialCharacters");
            }
        },

        checkSpecialCharacters: function (sPostCode) {
            //Regex Expression for Special characters
            var objRegex = /[!@#$%^&*(),.?":{}|<>]/g;

            //Testing Expression with PostCode to Check for Special Characters
            if (objRegex.test(sPostCode)) {
                return true;
            } else {
                return false;
            }
        },

        validateFirstPartPostcode: function (sPostCode1) {
            var sErrorMessage = "";

            var objRegex1 = /^[A-Z][0-9][A-Z]$/;
            if (objRegex1.test(sPostCode1)) {
                // Regex Format below
                // /^[ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW]$/
                if (RegExp(/^[ABCDEFGHIJKLMNOPRSTUWYZ]/).test(sPostCode1)) {
                    if (RegExp(/[0-9]{1}/).test(sPostCode1)) {
                        if (RegExp(/[ABCDEFGHJKSTUW]$/).test(sPostCode1)) {
                            return sPostCode1;
                        } else {
                            sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeLastLetterA9A");
                        }
                    } else {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustAlwaysContainNumber");
                    }
                } else {
                    sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeFirstLetter");
                }
                return sErrorMessage;
            }

            var objRegex2 = /^[A-Z][0-9]{1,2}$/;
            if (objRegex2.test(sPostCode1)) {
                // Regex Format below
                // /^[ABCDEFGHIJKLMNOPRSTUWYZ][0-9]{1,2}$/
                if (RegExp(/^[ABCDEFGHIJKLMNOPRSTUWYZ]/).test(sPostCode1)) {
                    if (RegExp(/[0-9]{1,2}$/).test(sPostCode1)) {
                        return sPostCode1;
                    } else {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustEndWithNumber");
                    }
                } else {
                    sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeFirstLetter");
                }

                return sErrorMessage;
            }

            var objRegex3 = /^[A-Z][A-Z][0-9]{1,2}$/;
            if (objRegex3.test(sPostCode1)) {
                // Regex Format below
                // /^[ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9]{1,2}$/
                if (RegExp(/^[ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY]/).test(sPostCode1)) {
                    if (RegExp(/[0-9]{1,2}$/).test(sPostCode1)) {
                        return sPostCode1;
                    } else {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustEndWithNumber");
                    }
                } else {
                    if (RegExp(/^[QVX]/).test(sPostCode1)) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeFirstLetter");
                    }
                    if (RegExp(/^.[IJZ]/).test(sPostCode1)) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeSecondLetter");
                    }
                }

                return sErrorMessage;
            }

            var objRegex4 = /^[A-Z][A-Z][0-9][A-Z]$/;
            if (objRegex4.test(sPostCode1)) {
                // Regex Format below
                // /^[ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY]$/
                if (RegExp(/^[ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY]/).test(sPostCode1)) {
                    if (RegExp(/[0-9]{1,2}/).test(sPostCode1)) {
                        if (RegExp(/[ABEHMNPRVWXY]$/).test(sPostCode1)) {
                            return sPostCode1;
                        } else {
                            sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeLastLetterAA9A");
                        }
                    } else {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustAlwaysContainNumber");
                    }
                } else {
                    if (RegExp(/^[QVX]/).test(sPostCode1)) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeFirstLetter");
                    }
                    if (RegExp(/^.[IJZ]/).test(sPostCode1)) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeSecondLetter");
                    }
                }

                return sErrorMessage;
            }

            var objPostcodeStandardA9 = /^[A-Z][0-9]$/;
            var objPostcodeStandardA99 = /^[A-Z][0-9][0-9]$/;
            var objPostcodeStandardA9A = /^[A-Z][0-9][A-Z]$/;
            var objPostcodeStandardAA9 = /^[A-Z][A-Z][0-9]$/;
            var objPostcodeStandardAA99 = /^[A-Z][A-Z][0-9][0-9]$/;
            var objPostcodeStandardAA9A = /^[A-Z][A-Z][0-9][A-Z]$/;

            var objNumberCheck = /[0-9]{1,2}/;
            var objAplhaCheck = /^[A-Z]{1,2}/;

            if (!objAplhaCheck.test(sPostCode1)) {
                sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustAlwaysStartWithLetter");
            } else if (!objNumberCheck.test(sPostCode1)) {
                sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeMustAlwaysContainNumber");
            } else {
                var rNumberRegex = RegExp(/[0-9]/g);
                var iCount = 0;

                // This is used instead of the matchAll function since browser support is more for current browser versions
                while (rNumberRegex.exec(sPostCode1) !== null) {
                    iCount++;
                }

                if (iCount > 2) {
                    sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCodeCannotHaveMoreThanTwoNumbers");
                } else {
                    if (sPostCode1.length === 2 && !objPostcodeStandardA9.test(sPostCode1)) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCode2CharStucture");
                    } else if (sPostCode1.length === 3 && (!objPostcodeStandardA99.test(sPostCode1) || !objPostcodeStandardA9A.test(sPostCode1) || !objPostcodeStandardAA9.test(sPostCode1))) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCode3CharStucture");
                    } else if (sPostCode1.length === 4 && (!objPostcodeStandardAA99.test(sPostCode1) || !objPostcodeStandardAA9A.test(sPostCode1))) {
                        sErrorMessage = this.get("_objEmberLibStringService").getString("validationFirstPartPostCode4CharStucture");
                    } else {
                        sErrorMessage = sPostCode1;
                    }
                }
            }

            return sErrorMessage;
        },

        validateSecondPartPostcode: function (sPostCode2) {
            //Split PostCode
            var sPart1 = sPostCode2.substring(0, 1);
            var sPart2 = sPostCode2.substring(1, 3);
            var sErrorMessages = "";
            //Regex for 1st Character to be a number
            var objNumberCheckStart = /^[0-9]{1}/i;
            var objNumberCheck = /[0-9]/g;
            //Format for Part 2 to be two alpha characters
            var objAplhaCheck = /^[ABD-HJLNP-UW-Z]{2}$/i;

            var objPostcodeStandard9AA = /^[0-9][A-Z][A-Z]$/;

            if (sPostCode2.length === 3) {
                //Check if PostCode start with a number
                if (objNumberCheckStart.test(sPart1)) {
                    //Check PostCode contains all needed characters
                    if (objAplhaCheck.test(sPart2)) {
                        sErrorMessages = sPostCode2;
                    } else {
                        if (objNumberCheck.test(sPart2)) {
                            sErrorMessages = this.get("_objEmberLibStringService").getString("validationSecondPartPostCodeCannotHaveMoreThanTwoNumbers");
                        } else {
                            sErrorMessages = this.get("_objEmberLibStringService").getString("validationSecondPartPostCodeHasInvalidCharacters");
                        }
                    }
                } else {
                    sErrorMessages = this.get("_objEmberLibStringService").getString("validationSecondPartPostCodeMustStartWithNumber");
                }
            } else {
                if (!objPostcodeStandard9AA.test(sPostCode2)) {
                    sErrorMessages = this.get("_objEmberLibStringService").getString("validationSecondPartPostIsNotValidFormat");
                }
            }
            return sErrorMessages;
        }
    });
});
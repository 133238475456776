define("a24-ember-lib/components/input-form-address", ["exports", "a24-ember-lib/templates/components/input-form-address", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormAddress, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormAddress.default,
        classNames: ["a24ErrorInputParent", "row"],
        sInputType: "address",
        arrCountry: null,
        arrCounty: null,
        bDisplayCounty: false,
        bDisplayPeriod: false,
        bDisplayCurrent: false,
        objAddressDetails: null,
        sTimeZone: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _arrFromDateMonth: null,
        _arrFromDateYear: null,
        _arrToDateMonth: null,
        _arrToDateYear: null,
        _objAddressDetailsUnbound: null,
        _objCountrySelectedValue: null,
        _objCountySelectedValue: null,
        _objToDateYearSelected: null,
        _objToDateMonthSelected: null,
        _objFromDateYearSelected: null,
        _objFromDateMonthSelected: null,
        _iMinYear: 1930,
        _iMaxYear: 2040,
        _bDisplayToPeriod: false,
        _bInternalProcess: false,
        _arrMonthShort: null,
        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("sTimeZone"))) {
                this.set("sTimeZone", this.get("_objLibConstants.MOMENT_TIMEZONE_UTC"));
            }

            this.set("_arrMonthShort", [this.get("_objStringsService").getString("monthJanShort"), this.get("_objStringsService").getString("monthFebShort"), this.get("_objStringsService").getString("monthMarShort"), this.get("_objStringsService").getString("monthAprShort"), this.get("_objStringsService").getString("monthMayShort"), this.get("_objStringsService").getString("monthJunShort"), this.get("_objStringsService").getString("monthJulShort"), this.get("_objStringsService").getString("monthAugShort"), this.get("_objStringsService").getString("monthSepShort"), this.get("_objStringsService").getString("monthOctShort"), this.get("_objStringsService").getString("monthNovShort"), this.get("_objStringsService").getString("monthDecShort")]);

            if (this.get("arrCountry") === null) {
                this.set("arrCountry", []);
            }
            if (this.get("arrCounty") === null) {
                this.set("arrCounty", []);
            }

            // Generate data for "From Date" month and year dropdowns
            this.set("_arrFromDateYear", a24Core.generateRangeOptions(this.get("_iMaxYear"), this.get("_iMinYear")));
            this.set("_arrFromDateMonth", this.generateMonthOptions());

            // Generate data for "To Date" month and year dropdowns
            this.set("_arrToDateYear", a24Core.generateRangeOptions(this.get("_iMaxYear"), this.get("_iMinYear")));
            this.set("_arrToDateMonth", this.generateMonthOptions());

            if (!a24Core.isEmpty(this.get("objAddressDetails"))) {
                let objAddressDetails = this.get("objAddressDetails");
                // Set the selected "Date From"
                if (!a24Core.isEmpty(objAddressDetails.sFromDate)) {
                    let objFromDate = this.splitDateToObject(objAddressDetails.sFromDate);
                    this.set("_objFromDateMonthSelected", objFromDate.sMonth);
                    this.set("_objFromDateYearSelected", objFromDate.sYear);
                }

                // Set the selected "Date To"
                if (!a24Core.isEmpty(objAddressDetails.sToDate)) {
                    let objToDate = this.splitDateToObject(objAddressDetails.sToDate);
                    this.set("_objToDateMonthSelected", objToDate.sMonth);
                    this.set("_objToDateYearSelected", objToDate.sYear);
                }
            }

            // Update mValue and fields with data passed from parent
            this.updateDataFromParent(false);
            // Setup validation for fields that require it
            this.setupValidation();
        },
        checkExtraTypes: function (objChild) {
            //Override the checkExtraTypes from the input form mixin to allow us to handle the address type

            if (objChild.sInputType === "address") {
                var objValue = this.getPlainFormValueObject();
                if (!this.get("bDisplayCounty")) {
                    delete objValue.sCounty;
                }

                if (this.get("bDisplayPeriod")) {
                    objValue["sFromDate"] = null;
                    if (!a24Core.isEmpty(objValue.sDateFromMonth) && !a24Core.isEmpty(objValue.sDateFromYear)) {
                        objValue["sFromDate"] = this.formatRangeDate(objValue.sDateFromYear, objValue.sDateFromMonth);
                    }

                    if (this.get("_bShouldDisplayToDate")) {
                        objValue["sToDate"] = null;
                        if (!a24Core.isEmpty(objValue.sDateToMonth) && !a24Core.isEmpty(objValue.sDateToYear)) {
                            objValue["sToDate"] = this.formatRangeDate(objValue.sDateToYear, objValue.sDateToMonth);
                        }
                    }
                }

                delete objValue.sDateFromYear;
                delete objValue.sDateFromMonth;
                delete objValue.sDateToYear;
                delete objValue.sDateToMonth;

                if (!this.get("bDisplayCurrent")) {
                    delete objValue.bCurrent;
                }

                return {
                    bHandled: true,
                    mReturned: objValue
                };
            }
        },
        // Sets up default validation on fields that require validation
        setupValidation: function () {
            // Will check if the selected year/month date is in the future
            var funcValidateFutureDate = (objMixin, sYearField, sMonthField, bEmptyMessage) => {
                // Get the current date but the day is set to the last day of the month
                var objCurrentEndOfMonth = a24DateManager.createDate(this.get("sTimeZone"));
                objCurrentEndOfMonth.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));

                // Get the specified date but the day is set to the first day of the month
                var objFromDate = a24DateManager.createDate(this.get("sTimeZone"));
                objFromDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_UNIT_YEAR"), parseInt(this.get("objChildren." + sYearField + ".mValue.sValue")));
                objFromDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_UNIT_MONTH"), parseInt(this.get("objChildren." + sMonthField + ".mValue.sValue")));
                objFromDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));

                if (objFromDate.isAfter(objCurrentEndOfMonth)) {
                    if (bEmptyMessage === true) {
                        return "&nbsp;";
                    } else {
                        return this.get("_objStringsService").getTokenString("validationDateInFuture", objMixin.get("sFieldName"));
                    }
                }
            };

            // Will ensure that the To Date is not before the From Date
            var funcValidateToDateBeforeFrom = (objMixin, bEmptyMessage) => {

                // The From Date but the day is set to the first day of the month
                var objFromDate = a24DateManager.createDate(this.get("sTimeZone"));
                objFromDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_UNIT_YEAR"), parseInt(this.get("objChildren.sDateFromYear.mValue.sValue")));
                objFromDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), parseInt(this.get("objChildren.sDateFromMonth.mValue.sValue")));
                objFromDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));

                // The TO Date but the day is set to the last day of the month
                var objToDate = a24DateManager.createDate(this.get("sTimeZone"));
                objToDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_UNIT_YEAR"), parseInt(this.get("objChildren.sDateToYear.mValue.sValue")));
                objToDate.setTimeOnDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), parseInt(this.get("objChildren.sDateToMonth.mValue.sValue")));
                objToDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));

                if (objToDate.isBefore(objFromDate)) {
                    if (bEmptyMessage === true) {
                        return "&nbsp;";
                    } else {
                        return this.get("_objStringsService").getTokenString("validationDateBefore", this.get("objChildren.sDateToMonth.sFieldName"), this.get("objChildren.sDateFromMonth.sFieldName"));
                    }
                }
            };

            // Set validation on all fields that require validation
            this.set("objChildConfig", {
                "objChildren.sAddressLine1": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.sCityTown": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.sCountryCode": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.sDateFromMonth": objMixin => {
                    // We need to do custom required validation here as this field is dependent on sDateFromYear
                    objMixin.get("arrValidation").pushObject(() => {
                        if (a24Core.isEmpty(objMixin.get("mValue")) || a24Core.isEmpty(this.get("objChildren.sDateFromYear.mValue"))) {
                            return this.get("_objStringsService").getTokenString("validationRequired", objMixin.get("sFieldName"));
                        }
                    });

                    // Check if the From Date in the future
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateFutureDate(objMixin, "sDateFromYear", "sDateFromMonth", false);
                    });
                },
                "objChildren.sDateFromYear": objMixin => {
                    // We need to do custom required validation here as this field is dependent on sDateFromMonth
                    objMixin.get("arrValidation").pushObject(() => {
                        if (a24Core.isEmpty(objMixin.get("mValue")) || a24Core.isEmpty(this.get("objChildren.sDateFromMonth.mValue"))) {
                            // Return an space to highlight the field
                            return "&nbsp;";
                        }
                    });

                    // Check if the From Date in the future
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateFutureDate(objMixin, "sDateFromYear", "sDateFromMonth", true);
                    });
                },
                "objChildren.sDateToMonth": objMixin => {
                    // We need to do custom required validation here as this field is dependent on sDateToYear
                    objMixin.get("arrValidation").pushObject(() => {
                        if (a24Core.isEmpty(objMixin.get("mValue")) || a24Core.isEmpty(this.get("objChildren.sDateToYear.mValue"))) {
                            return this.get("_objStringsService").getTokenString("validationRequired", objMixin.get("sFieldName"));
                        }
                    });

                    // Check if the To Date in the future
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateFutureDate(objMixin, "sDateToYear", "sDateToMonth", false);
                    });

                    // Check To Date is before From Date
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateToDateBeforeFrom(objMixin, false);
                    });
                },
                "objChildren.sDateToYear": objMixin => {
                    // We need to do custom required validation here as this field is dependent on sDateToMonth
                    objMixin.get("arrValidation").pushObject(() => {
                        if (a24Core.isEmpty(objMixin.get("mValue")) || a24Core.isEmpty(this.get("objChildren.sDateToMonth.mValue"))) {
                            return "&nbsp;";
                        }
                    });

                    // Check if the To Date in the future
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateFutureDate(objMixin, "sDateToYear", "sDateToMonth", true);
                    });

                    // Check To Date is before From Date
                    objMixin.get("arrValidation").pushObject(() => {
                        return funcValidateToDateBeforeFrom(objMixin, true);
                    });
                }
            });
        },
        /**
         * Whenever a child's value changes this will be called.
         *
         * To prevent multitude of events from firing we check if the changes
         * that where made came from the parent. If this is the case we do not call updateData
         */
        onInputValueChanged: function (arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.sCountryCode") {
                if (!a24Core.isEmpty(this.get(sLookup + ".mValue"))) {
                    this.get("objChildren.sPostCode").get("arrValidation").clear();
                    this.get("objChildren.sPostCode").set("funcValidationRequired", null);

                    if (this.get(sLookup + ".mValue.sValue") === "UK" || this.get(sLookup + ".mValue.sValue") === "GB") {
                        a24Validation.addPostCode(this.get("objChildren.sPostCode"));
                    }

                    this._formatPostCode();
                }
            } else if (sLookup === "objChildren.sPostCode") {
                this._formatPostCode();
            }

            if (!a24Core.isEmpty(arrPathToInputMixin) && !this.get("_bInternalProcess")) {
                Ember.run.once(this, this.updateData);
            }
        },
        _formatPostCode: function () {
            var objCountryCode = this.get("objChildren")["sCountryCode"];
            var objPostCode = this.get("objChildren")["sPostCode"];

            if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue) && !a24Core.isEmpty(objPostCode.mValue) && (objCountryCode.mValue.sValue === "UK" || objCountryCode.mValue.sValue === "GB")) {
                var sInputValue = objPostCode.mValue.trim();
                if (!/\s/.test(sInputValue)) {
                    var sValue1 = sInputValue.slice(0, sInputValue.length - 3);
                    var sValue2 = sInputValue.slice(sInputValue.length - 3, sInputValue.length);
                    objPostCode.setValueFromOutside(sValue1.toUpperCase() + " " + sValue2.toUpperCase());
                } else if (sInputValue.split(" ").length > 1) {
                    objPostCode.setValueFromOutside(sInputValue.replace(/  +/g, " ").toUpperCase());
                }
            }
        },
        onDisplayFieldsChanged: Ember.observer("bDisplayCounty", "bDisplayPeriod", "bDisplayCurrent", function () {
            Ember.run.once(this, this.updateData);
        }),
        // This will apply all child values to the mValue
        updateData: function () {

            var objValue = this.getPlainFormValueObject();

            if (a24Core.isEmpty(objValue.bCurrent)) {
                this.set("_bCurrentAddress", false);
            } else {
                this.set("_bCurrentAddress", objValue.bCurrent);
            }

            this._buildInputValueChangedPath([]);
        },
        checkForCheckbox: Ember.observer("objAddressDetails.bCurrent", function () {
            var objAddressDetailsUnbound = this.get("_objAddressDetailsUnbound");
            if (objAddressDetailsUnbound.bCurrent === true) {
                this.set("_objAddressDetailsUnbound.bCurrent", false);
                this.set("_bCurrentAddress", false);
            } else {
                this.set("_objAddressDetailsUnbound.bCurrent", true);
                this.set("_bCurrentAddress", true);
            }
        }),
        // Checks if any of the parents data changed
        //Seems that we can only observe the objAddressDetails here and not every property on it
        //This is because in production code it will work as expected and only fire one updateDataFromParent
        //no matter how many properties change at a time, that is because of the run once. HOWEVER, that is not the
        //case in test cases, there it will basically ignore the run once and fire the updateDataFromParent multiple
        //time, see below link
        //https://guides.emberjs.com/v2.10.0/applications/run-loop/#toc_how-is-run-loop-behaviour-different-when-testing
        //And no, we are not doing the run once in the observer wrong, see ember run once example in doc below
        //https://guides.emberjs.com/v2.10.0/object-model/observers/#toc_observers-and-asynchrony
        //We can not have tests that do not test the code accurately, so for this component to change details from
        //outside you would have to replace the objAddressDetails completely
        //"objAddressDetails.sAddressLine1",
        //"objAddressDetails.sAddressLine2",
        //"objAddressDetails.sCityTown",
        //"objAddressDetails.sPostCode",
        //"objAddressDetails.sCountryCode",
        //"objAddressDetails.sCounty",
        //"objAddressDetails.sFromDate",
        //"objAddressDetails.sToDate",
        //"objAddressDetails.bCurrent"
        updateDataFromParentObserves: Ember.observer("objAddressDetails", function () {
            Ember.run.once(this, this.updateDataFromParent, true);
        }),
        // Applies the parents values to all input fields and set it to the mValue
        updateDataFromParent: function (bFireEvent) {

            this.set("_bInternalProcess", true);
            var objValue = {};

            //Call this from init and set mValue from objAddressDetails
            objValue["bCurrent"] = false;
            if (!a24Core.isEmpty(this.get("objAddressDetails.bCurrent"))) {
                this.set("_bCurrentAddress", this.get("objAddressDetails.bCurrent"));
                objValue["bCurrent"] = this.get("objAddressDetails.bCurrent");
            } else {
                this.set("_bCurrentAddress", false);
            }

            objValue["sAddressLine1"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sAddressLine1"))) {
                objValue["sAddressLine1"] = this.get("objAddressDetails.sAddressLine1");
            }

            objValue["sAddressLine2"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sAddressLine2"))) {
                objValue["sAddressLine2"] = this.get("objAddressDetails.sAddressLine2");
            }

            objValue["sCityTown"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sCityTown"))) {
                objValue["sCityTown"] = this.get("objAddressDetails.sCityTown");
            }

            objValue["sPostCode"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sPostCode"))) {
                objValue["sPostCode"] = this.get("objAddressDetails.sPostCode");
            }

            objValue["sCountryCode"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sCountryCode"))) {
                objValue["sCountryCode"] = this.get("objAddressDetails.sCountryCode");
                this.set("_objCountrySelectedValue", { sValue: this.get("objAddressDetails.sCountryCode") });
            } else {
                this.set("_objCountrySelectedValue", { sValue: null });
            }

            objValue["sCounty"] = null;
            if (!a24Core.isEmpty(this.get("objAddressDetails.sCounty"))) {
                objValue["sCounty"] = this.get("objAddressDetails.sCounty");
                this.set("_objCountySelectedValue", { sValue: this.get("objAddressDetails.sCounty") });
            } else {
                this.set("_objCountySelectedValue", { sValue: null });
            }

            objValue["sDateFromYear"] = null;
            objValue["sDateFromMonth"] = null;
            objValue["sDateToYear"] = null;
            objValue["sDateToMonth"] = null;

            if (!a24Core.isEmpty(this.get("objAddressDetails.sFromDate"))) {
                let objFromDate = this.splitDateToObject(this.get("objAddressDetails.sFromDate"));
                this.set("_objFromDateMonthSelected", objFromDate.sMonth);
                this.set("_objFromDateYearSelected", objFromDate.sYear);

                objValue["sDateFromYear"] = objFromDate.sYear.sValue;
                objValue["sDateFromMonth"] = objFromDate.sMonth.sValue;
            } else {
                this.set("_objFromDateMonthSelected", { sValue: null });
                this.set("_objFromDateYearSelected", { sValue: null });
            }

            if (!a24Core.isEmpty(this.get("objAddressDetails.sToDate"))) {
                let objToDate = this.splitDateToObject(this.get("objAddressDetails.sToDate"));
                this.set("_objToDateMonthSelected", objToDate.sMonth);
                this.set("_objToDateYearSelected", objToDate.sYear);

                objValue["sDateToYear"] = objToDate.sYear.sValue;
                objValue["sDateToMonth"] = objToDate.sMonth.sValue;
            } else {
                this.set("_objToDateMonthSelected", { sValue: null });
                this.set("_objToDateYearSelected", { sValue: null });
            }

            this.clearForm();

            // Unbind the parents objAddressDetails
            this.set("_objAddressDetailsUnbound", Ember.copy(objValue, true));

            // Only call method if parent data changed
            if (bFireEvent) {
                this._buildInputValueChangedPath([]);
            }

            // Set value back to false once the parent update process has completed
            Ember.run.next(() => {
                this.set("_bInternalProcess", false);
            });
        },
        // Property used to determine if the To Date should be displayed or not
        _bShouldDisplayToDate: Ember.computed("bDisplayPeriod", "_bCurrentAddress", function () {
            return this.get("bDisplayPeriod") && !this.get("_bCurrentAddress");
        }),
        formatRangeDate: function (sYear, sMonth) {
            sMonth = (parseInt(sMonth) + 1).toString();
            if (parseInt(sMonth) < 10) {
                sMonth = "0" + sMonth;
            }
            return sYear + "-" + sMonth;
        },
        splitDateToObject: function (sDate) {
            var arrDate = sDate.split("-");
            return {
                sYear: { sValue: arrDate[0] },
                sMonth: { sValue: (parseInt(arrDate[1]) - 1).toString() }
            };
        },
        generateMonthOptions: function () {
            var arrResult = [];
            var arrMonthShort = this.get("_arrMonthShort");
            for (var i = 0; i < 12; i++) {
                arrResult.push({ sValue: i.toString(), sLabel: arrMonthShort[i] });
            }

            return arrResult;
        }
    });
});
define("a24-ember-form-inputs/components/input-form-email", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-form-inputs/templates/components/input-form-email", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormEmail, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormEmail.default,
        classNames: ["a24ErrorInputParent", "md-form"],
        sValue: null,
        sInputType: "email",
        arrDelayedValidation: null, //public so that you can add to this in the form setup aswell

        objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _bInitDone: false,
        _objAjax: $.ajax,
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objFormInputsConstants: Ember.inject.service("a24-constants-form-inputs"),
        _iTimeout: 25,
        _bAbortCall: false,

        // This is a empty function so that the empty check would pass
        _funcScriptMock: function () {},
        init: function () {
            this._super(...arguments);

            if (!a24Core.isEmpty(this.get("_funcScriptMock"))) {
                this.set("_funcScriptMock", () => {
                    Ember.run.later(() => {
                        this.get("onInputFormEmailInitDone")(this);
                    }, 100);
                });
            }

            if (a24Core.isEmpty(this.get("onInputFormEmailInitDone"))) {
                //eslint-disable-next-line no-console
                console.error("You have to handle and wait for the onInputFormEmailInitDone to fire before interacting with " + "this input");
            }

            var objThis = this;
            var objTextInput = null;
            Ember.run.scheduleOnce("afterRender", this, function () {

                objTextInput = $("#" + objThis.get("_sInputId"));

                if (!a24Core.isEmpty(objThis.get("sValue"))) {

                    // Set the mvalue first
                    this.setMValueWithInternalFlag(this.get("sValue"), true);

                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(this.get("sValue"));
                    objTextInput.change();
                }

                objTextInput.on("keyup", objEvent => {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });

                if (!a24Core.isEmpty(objThis.get("iMaxCharacters"))) {
                    objTextInput.attr("length", objThis.get("iMaxCharacters"));
                    objTextInput.characterCounter();
                }

                this.resetFloatingLabel(objTextInput);

                objTextInput.on("blur", function () {
                    objThis.resetFloatingLabel(objTextInput);
                });
            });

            if (a24Core.isEmpty(this.get("arrDelayedValidation"))) {
                this.set("arrDelayedValidation", []);
            }

            //We are taking a shortcut to only support Delayed Validation on this input for now. The idea would be to
            //update the `input-form-element.js` to also do the arrDelayedValidation when we call `doValidation(true)`
            //The `doFullValidation` and the `doValidation` would be updated to take in a callback function.
            //Then from the parent side instead of doing just `if (!this.doFullValidation()){...}` you would do
            // this.doFullValidation((bHasErrors) => {
            //     if (!bHasErrors) {
            //         ...
            //     }
            // })
            //The arrDelayedValidation would work the same as the arrValidation where each function just returns a string
            //if there was an error of nothing when validation passed, only difference is the arrDelayedValidation will do
            //that with a callback instead of instantly returning. The `doValidation` would take care of keeping track
            //of all the callbacks and also setting the error message on the input

            //So the shotcut for this input is that we setup the arrDelayedValidation correctly to work with the approach
            //above, but for now the parent component will need to manually execute the arrDelayedValidation and set error
            //message e.g
            // if (!this.doFullValidation()) { //all normal validation passes
            //     this.get("objChildren.sEmailFieldProp.arrDelayedValidation.0")((sErrorMessage) => {
            //         //NOTE this is sErrorMessage because we call it directly on the input, if it went through
            //         //doFullValidation with a callback it whould have been bHasErrors
            //         if (sErrorMessage) {
            //             this.setResponseError(["sEmailFieldProp"]], sErrorMessage, true, true);
            //         } else {
            //             //do save service call
            //         }
            //     });
            // }

            this.get("arrDelayedValidation").pushObject(objCallback => {
                //If scripts failed to load we treat this as a PASS, because we do not want to block the user progress
                //when neverbounce is having issues

                if (a24Core.isEmpty(window._nb)) {
                    objCallback();
                    return;
                }
                // This is here since neverbounce does not return us a promise that we can tie into to check if they giving
                // 500 errors on their widget
                objThis.set("_bAbortCall", false);
                var objRunLater = Ember.run.later(() => {
                    objThis.set("_bAbortCall", true);
                    objCallback();
                },
                // This is hardcoded to 25 seconds since this is the same as the neverbounce default timeout
                // NOTE: if we increase timeout for neverbounce we need to increase this
                objThis.get("_iTimeout") * 1000);

                window._nb.api.getValidatePublic(this.get("mValue"), objResult => {
                    // If the timeout is up then we will not execute the callback.
                    if (objThis.get("_bAbortCall")) {
                        return;
                    }
                    // We need to cancel the run later else it will trigger even if neverbounce succeeds/fails
                    Ember.run.cancel(objRunLater);
                    if (a24Core.isEmpty(objThis)) {
                        return;
                    }
                    Ember.run(() => {
                        if (!a24Core.isEmpty(objResult) && !a24Core.isEmpty(objResult.response) && !a24Core.isEmpty(objResult.response.allow_entry)) {
                            if (objResult.response.allow_entry) {
                                // Success
                                objCallback();
                            } else {
                                //Set error message on input saying email is not valid/allowed
                                objCallback(objThis.get("_objFormInputsStringsService").getString("invalidEmail"));
                            }
                        } else {
                            //For now this will count as validation PASS, because we do not want to block the user progress
                            //when neverbounce does not return anything
                            objCallback();
                        }
                    });
                }, () => {
                    // If the timeout is up then we will not execute the callback.
                    if (objThis.get("_bAbortCall")) {
                        return;
                    }
                    // We need to cancel the run later else it will trigger even if neverbounce succeeds/fails
                    Ember.run.cancel(objRunLater);
                    if (a24Core.isEmpty(objThis)) {
                        return;
                    }
                    Ember.run(() => {
                        //For now this will count as validation PASS, because we do not want to block the user progress
                        //when neverbounce is having issues
                        objCallback();
                    });
                });
            });

            objThis.get("arrValidation").pushObject(function () {
                if (!a24Core.isEmpty(objTextInput.val())) {
                    // This is our regex we used before for our emails
                    var sEmailRegex = objThis.get("_objFormInputsConstants.EMAIL_REGEX");
                    // This is the regex for emails from neverbounce that they suggest we do before calling the api. But its very strict
                    // /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                    var objRegEmail = new RegExp(sEmailRegex);

                    if (!objRegEmail.test(objTextInput.val())) {
                        return objThis.get("_objFormInputsStringsService").getTokenString("validationEmail", objThis.get("sFieldName"));
                    }
                }
            });

            if (!a24Core.isEmpty(this.get("objEnv").sNeverBounceApiKey)) {
                window._NBSettings = {
                    apiKey: this.get("objEnv").sNeverBounceApiKey,
                    // This is their default timeout. Only added this for when we want to decrease/increase the timeout
                    // Also we can change other stuff aswell. Check here https://developers.neverbounce.com/docs/widget-getting-started
                    timeout: this.get("_iTimeout")
                };
            }

            if (objThis.get("objEnv").environment === "test" && !a24Core.isEmpty(objThis.get("_funcScriptMock"))) {
                // Test setup
                objThis.get("_funcScriptMock")();
            } else {
                objThis.get("_objAjax")({
                    // This has to be https to work since their js will only be retrieved with it
                    url: "https://cdn.neverbounce.com/widget/dist/NeverBounce.js",
                    dataType: "script",
                    cache: true
                }).done(function () {
                    if (a24Core.isEmpty(objThis)) {
                        return;
                    }
                    Ember.run(() => {
                        if (!a24Core.isEmpty(objThis.get("onInputFormEmailInitDone"))) {
                            // Send the current input instance on
                            objThis.get("onInputFormEmailInitDone")(objThis);
                        }
                    });
                }).fail(function () {
                    if (a24Core.isEmpty(objThis)) {
                        return;
                    }
                    Ember.run(() => {
                        //eslint-disable-next-line no-console
                        console.warn("Failed to load script needed for the input form email input");
                        objThis.get("onInputFormEmailInitDone")(objThis);
                    });
                }).always(function () {
                    if (a24Core.isEmpty(objThis)) {
                        return;
                    }
                    Ember.run(() => {
                        objThis.set("_bInitDone", true);
                    });
                });
            }
        },
        clear: function () {
            this.setValueFromOutside("");
        },
        setValueFromOutside: function (sValue) {
            if (!this.get("_bInitDone")) {
                //eslint-disable-next-line no-console
                console.error("You have to wait for the onInputFormEmailInitDone to fire before interacting with this input");
            }

            this.setMValueWithInternalFlag(sValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.val(sValue);
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        observeValueChange: Ember.observer("sValue", function () {
            this.setValueFromOutside(this.get("sValue"));
        }),
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        actions: {
            onInputChange: function (sValue) {
                // any internal changes that fire this function will have updated
                // mvalue already, meaning the duplicate update will not fire any
                // additional events
                this.setMValueWithInternalFlag(sValue, false);
            }
        }
    });
});
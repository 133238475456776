define("a24-ember-staffshift-core/components/notification-sent-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/notification-sent-popup"], function (exports, _didRenderChangesMixin, _notificationSentPopup) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _notificationSentPopup.default,
        bShowPopup: null,
        bPopupLoading: null,
        onPopupCloseAction: null,
        objActivityData: null,
        objRestActivityNotification: null,

        _bShowNotifcationPreviewPopup: null,
        _bNotificationPreviewPopupLoading: null,
        _objNotificationPreviewSpecialPopupAction: null,
        _objSpecialPopupAction: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objCurrentNotificationSentCache: null,
        _bIsEmail: null,
        _objNotificationPreview: null,
        _arrNotificationPreviewPopupActions: null,
        _objEmailPreviewGeneratorService: Ember.inject.service("email-preview-generator"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _arrNotificationsSentResponse: null,
        init: function () {
            this._super(...arguments);
            // Create an action that will open the conversation preview in a new tab
            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this.get("onPopupCloseAction")();
                }
            });

            // Create an action that will close the preview popup
            this.set("_objNotificationPreviewSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this.set("_bShowNotifcationPreviewPopup", false);
                }
            });
        },
        _onActivityDataChange: Ember.observer("objActivityData", function () {
            let objActivityData = this.get("objActivityData");
            this.set("sSubjectId", objActivityData.sSubjectId);
            this.set("sSubjectType", objActivityData.sSubjectType);
            this.notifcationFromActivity(objActivityData.sSubjectId, objActivityData.sActivityId, objActivityData.sLinkedEntityId, objActivityData.sSubjectType);
        }),
        notifcationFromActivity: function (sSubjectId, sActivityId, sLinkedEntityId, sSubjectType) {
            var objThis = this;

            // show notifications sent and delivery status
            this.set("bPopupLoading", true);
            this.set("_bOptOut", false);
            this.get("objRestActivityNotification").getActivityNotification(this, "_objActivityNotificationPreviewPromise", sSubjectId, sSubjectType, sActivityId, sLinkedEntityId, objResponse => {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.notifications_sent)) {
                    // reset cache
                    this.set("_objCurrentNotificationSentCache", {});
                    // save notifications sent and delivery status
                    var arrNotificationsSent = [];
                    for (let i = 0; i < objResponse.notifications_sent.length; i++) {
                        let objData = {};
                        let sMedium = objResponse.notifications_sent[i].medium;
                        let sMediumType = sMedium;
                        let objMediumTypeEnum = this.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", sMedium, null, true);
                        let sStatus = this.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objResponse.notifications_sent[i].status);
                        let sIcon = "texture";
                        let sIconFa = null;
                        let sIconSvg = null;
                        if (!a24Core.isEmpty(objMediumTypeEnum)) {
                            sMediumType = objMediumTypeEnum.sLabel;
                            sIcon = objMediumTypeEnum.icon;
                            sIconFa = objMediumTypeEnum.icon_fa;
                            sIconSvg = objMediumTypeEnum.icon_svg;
                        }
                        if (a24Core.isEmpty(sStatus)) {
                            sStatus = objResponse.notifications_sent[i].status;
                        }

                        let sNotificationSentId = objResponse.notifications_sent[i].id;

                        objData.activity_id = sActivityId;
                        objData.medium = sMediumType;
                        objData.backend_medium = sMedium;
                        objData.status = sStatus;
                        objData.icon = sIcon;
                        objData.icon_fa = sIconFa;
                        objData.icon_svg = sIconSvg;
                        objData.entity_id = sLinkedEntityId;
                        objData.notifications_sent_id = sNotificationSentId;
                        objData.arrActions = [];
                        objData.arrActions.push({
                            sTitle: objThis.get("_objSSCoreStrings").getString("viewNotificationPreview"),
                            sIcon: "visibility",
                            executeCardAction: () => {
                                objThis.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
                            }
                        });
                        arrNotificationsSent.push(objData);
                    }

                    this.set("_arrNotificationsSentResponse", arrNotificationsSent);

                    this.set("bPopupLoading", false);
                } else {
                    if (objResponse.status === this.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                        this.set("_bOptOut", true);
                        this.set("bPopupLoading", false);
                        this.set("_arrNotificationsSentResponse", []);
                    } else {
                        if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                            this.get("onPopupCloseAction")();
                        }
                        this.set("bPopupLoading", false);

                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStrings").getString("couldNotLoadNotificationSentInformation"));
                    }
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
                this.set("bPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStrings").getString("couldNotLoadNotificationSentInformation"));
            }));
        },

        activityNotificationFromMedium: function (sActivityId, sLinkedEntityId, sMedium, sNotificationSentId) {
            let funcSuccess = objData => {
                this.set("_objCurrentNotificationSentCache." + sNotificationSentId, objData);
                // On success
                if (sMedium === this.get("_objStaffshiftCoreEnums").getBackendValue("mediumType", this.get("_objStringsService").getString("email"))) {
                    this.set("_bIsEmail", true);

                    var sSubject = "";
                    var objSender = null;
                    var objRecipient = null;
                    var objReplyTo = null;
                    var sBody = "";
                    var sStatus = "";
                    var sStatusReason = "";
                    if (!a24Core.isEmpty(objData.data)) {
                        if (!a24Core.isEmpty(objData.data.subject)) {
                            sSubject = objData.data.subject;
                        }
                        if (!a24Core.isEmpty(objData.data.sender)) {
                            objSender = objData.data.sender;
                        }
                        if (!a24Core.isEmpty(objData.data.to_recipient)) {
                            objRecipient = objData.data.to_recipient;
                        }
                        if (!a24Core.isEmpty(objData.data.reply_to)) {
                            objReplyTo = objData.data.reply_to;
                        }
                        if (!a24Core.isEmpty(objData.data.body_html)) {
                            sBody = objData.data.body_html;
                        }
                        if (!a24Core.isEmpty(objData.status_reason)) {
                            sStatusReason = objData.status_reason;
                        }
                    }
                    if (!a24Core.isEmpty(objData.status)) {
                        if (objData.status === this.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                            sBody = this.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreviewOptOut");
                        }
                        sStatus = this.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objData.status);
                    }

                    this.set("_objNotificationPreview", {
                        sSubject: sSubject,
                        objFrom: objSender,
                        objTo: objRecipient,
                        objReplyTo: objReplyTo,
                        sHTML: sBody,
                        sDeliveryStatus: sStatus
                    });

                    if (!a24Core.isEmpty(sStatusReason)) {
                        this.set("_objNotificationPreview.sDeliveryFailureReason", sStatusReason);
                    }

                    this.set("_arrNotificationPreviewPopupActions", [{
                        sTitle: this.get("_objSSCoreStrings").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: () => {
                            let objNotificationPreview = this.get("_objNotificationPreview");
                            // Generate the email html from details
                            let sHtml = this.get("_objEmailPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromEmailObject(objNotificationPreview);
                            this.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                } else {
                    this.set("_bIsEmail", false);
                    this.set("_arrNotificationPreviewPopupActions", null);
                    this.set("_objNotificationPreview", objData);
                }

                // Take the popup out of loading
                this.set("_bNotificationPreviewPopupLoading", false);
            };

            this.set("_bShowNotifcationPreviewPopup", true);
            this.set("_bNotificationPreviewPopupLoading", true);

            // Use the stored response if it is set
            let objStoredNotifciationResponse = this.get("_objCurrentNotificationSentCache." + sNotificationSentId);

            if (!a24Core.isEmpty(objStoredNotifciationResponse)) {
                funcSuccess(objStoredNotifciationResponse);
                return;
            }

            this._getActivityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess);
        },

        _getActivityNotificationFromMedium: function (sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess) {

            this.get("objRestActivityNotification").getActivityNotificationFromMedium(this, "_objActivityNotificationMediumPreviewPromise", this.get("sSubjectId"), this.get("sSubjectType"), sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], () => /*sCode, sStatus, objErrorThrown, objError*/{
                this.set("_bShowNotifcationPreviewPopup", false);
                this.set("_bNotificationPreviewPopupLoading", false);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSCoreStrings").getString("couldNotLoadNotificationPreview"));
            }));
        },
        openHtmlInNewWindow: function (sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        actions: {
            onPopupClose: function () {
                if (!a24Core.isEmpty(this.get("onPopupCloseAction"))) {
                    this.get("onPopupCloseAction")();
                }
            },
            onNotificationPreviewPopupClose: function () {
                this.set("_bShowNotifcationPreviewPopup", false);
            },
            onNotificationSentClick: function (objData) {
                let sActivityId = objData.activity_id;
                let sLinkedEntityId = objData.entity_id;
                let sMedium = objData.backend_medium;
                let sNotificationSentId = objData.notifications_sent_id;

                this.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
            }
        }
    });
});
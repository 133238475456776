define("a24-ember-lib/components/chart-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/chart-component"], function (exports, _didRenderChangesMixin, _chartComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chartComponent.default,
        iRowHeight: 50,
        classNames: ["full-width", "position-relative"],
        sType: null,
        objData: null,
        objOptions: null,
        arrPlugins: null,
        _sCanvasId: null,
        _objChart: null,

        init: function () {
            this._super(...arguments);
            this.set("_sCanvasId", Ember.guidFor({}));
        },

        didInsertElement: function () {
            this._super(...arguments);
            this._setHeight();

            var objContext = this.$("#" + this.get("_sCanvasId"));
            var objChart = new Chart(objContext, {
                type: this.get("sType"),
                data: this.get("objData"),
                options: this.get("objOptions"),
                plugins: this.get("arrPlugins")
            });
            this.set("_objChart", objChart);
        },

        willDestroyElement: function () {
            this._super(...arguments);
            this.get("_objChart").destroy();
        },

        didUpdateAttrs: function () {
            this._super(...arguments);
            this._setHeight();
            this.set("_objChart.config.data", this.get("objData"));
            this.set("_objChart.config.options", this.get("objOptions"));
            this.get("_objChart").update();
        },

        _setHeight: function () {
            var objData = this.get("objData");
            var arrDatasetLength = objData.datasets.map(objDataset => {
                return objDataset.data.length;
            });
            var iCount = Math.max(...arrDatasetLength);
            var iHeight = iCount * this.get("iRowHeight");
            if (!a24Core.isEmpty(this.get("objOptions.title")) && this.get("objOptions.title.display") === true) {
                iHeight += this.get("iRowHeight");
            }
            if (a24Core.isEmpty(this.get("objOptions.legend")) || this.get("objOptions.legend.display") === true) {
                iHeight += this.get("iRowHeight");
            }
            this.$().css("height", iHeight + "px");
        }
    });
});
define("a24-ember-staffshift-core/components/chatter-box", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/chatter-box"], function (exports, _didRenderChangesMixin, _chatterBox) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _chatterBox.default,
        classNames: ["a24-chatter-box"],

        //this needs to stay clean to match origin in post message events, so rather copy this and add params where needed
        //and do not alter this property
        sChatboxUrl: null,

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _bLoading: true,
        _bHideChat: Ember.computed("_objChatterboxService._bHideChat", function () {
            return this.get("_objChatterboxService")._bHideChat;
        }),
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objChatterboxService: Ember.inject.service("chatter-box-service"),
        _funcUnregister: null,
        _iDefaultWidth: 400,
        _iDefaultHeight: 600,
        _iDefaultPadding: 24,
        _bFrameReady: false,
        _sFinalPageName: null,
        _objRouteParams: null,
        _bNewVisit: false,
        _objPostMessageThrottle: null,
        _iThrottleLimit: 2000,

        init: function () {
            this._super(...arguments);
            //Get navigation so that we can observe the url on it
            this.get("_objNavigation");
            this.get("_objTitleBarService");
            this.get("_objBreadCrumbService");
            Ember.run.once(this, "_setFriendlyPageName");

            this.get("_objChatterboxService");

            if ("Notification" in window) {
                if (Notification.permission !== "granted") {
                    Notification.requestPermission();
                }
            }

            if (a24Core.isEmpty(this.get("sChatboxUrl"))) {
                this.set("sChatboxUrl", this.get("_ENV.sChatboxUrl"));
            }

            if (this.get("_ENV").environment !== "test") {
                document.domain = "staffshift.com";
            }

            if (!a24Core.isEmpty(this.get("sChatboxUrl"))) {
                // Schedule for after render
                Ember.run.scheduleOnce("afterRender", () => {
                    this.$(".a24-chatter-box-container").css("height", this.get("_iDefaultHeight"));
                    this.$(".a24-chatter-box-container").css("width", this.get("_iDefaultWidth"));
                    this.$(".a24-chatter-box-container").css("right", this.get("_iDefaultPadding"));
                    this.$(".a24-chatter-box-container").css("bottom", this.get("_iDefaultPadding"));
                    this._funcSizeChanges();
                });

                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(objMessage => {
                    this._handleMessages(JSON.parse(objMessage.data));
                }, this.get("sChatboxUrl")));
            }
        },

        _postData: function (objData) {
            let objDataCopy = Ember.copy(objData, true);
            this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("sChatboxUrl"), this.$("iframe")[0].contentWindow);
        },

        _notifyEventListener: function (event) {
            if (!a24Core.isEmpty(this.$("iframe"))) {
                this._postData({
                    type: "notify_event_listener",
                    data: {
                        event: event
                    }
                });
            }
        },

        _handleMessages: function (objData) {
            if (objData.type === "app_ready") {
                this.set("_bFrameReady", true);
                this.set("_bLoading", false);
                this._funcUrlChangeObserver();
                this._funcPostDataObserver();
            } else if (objData.type === "close_chatbox") {
                this.get("_objChatterboxService").hideChatBox();
            } else if (objData.type === "open_chatbox") {
                this.get("_objChatterboxService").showChatBox();
            } else if (objData.type === "desktop_notification") {
                this.get("_objChatterIframeComService").openDesktopNotification(this.get("sChatboxUrl"), this.$("iframe")[0].contentWindow, objData.data);
            } else if (objData.type === "unread_messages") {
                this.get("_objChatterboxService").setNotificationCount(objData.data.count);
            } else if (objData.type === "check_parent_has_focus") {
                this._postData({
                    type: objData.type + "_response",
                    data: {
                        hasFocus: window.document.hasFocus() || document.hasFocus()
                    },
                    requestId: objData.requestId
                });
            } else if (objData.type === "add_event_listener") {
                if (objData.data.location === "window") {
                    window.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.addEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            } else if (objData.type === "remove_event_listener") {
                if (objData.data.location === "window") {
                    window.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                } else if (objData.data.location === "document") {
                    document.removeEventListener(objData.data.event, event => {
                        this._notifyEventListener(event);
                    });
                }
            }
        },

        willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            //no need to do logout post message or something like that, chat app takes care of that.
            this._super(...arguments);
        },

        _setFriendlyPageName: function () {
            var objFriendly = this.get("_objChatterboxService").generateFriendlyPageName();

            if (!a24Core.isEmpty(objFriendly)) {
                this.set("_sFinalPageName", objFriendly.sFinalPageName);
                this.set("_objRouteParams", objFriendly.objRouteParams);

                Ember.run.once(this, "_postSessionDataThrottle");
            }
        },

        _postSessionDataThrottle: function () {
            if (!a24Core.isEmpty(this.get("_objPostMessageThrottle"))) {
                Ember.run.cancel(this.get("_objPostMessageThrottle"));
            }

            this.set("_objPostMessageThrottle", Ember.run.later(() => {
                if (!a24Core.isEmpty(this) && this.get("_bFrameReady")) {
                    this.get("_objChatterIframeComService").postSessionData(this.get("_bNewVisit"), this.get("_sFinalPageName"), this.get("_objRouteParams"), this.get("sChatboxUrl"), this.$("iframe")[0].contentWindow);

                    this.set("_bNewVisit", false);
                }
            }, this.get("_iThrottleLimit")));
        },

        _getWindowHeight: function () {
            return Ember.$(window).height();
        },

        _getWindowWidth: function () {
            return Ember.$(window).width();
        },

        _funcSizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            //NOTE the iframe can post event to change width and height, then just update the _iDefaultWidth and
            //_iDefaultHeight to those values and call this function again.

            if (this._getWindowWidth() < this.get("_iDefaultWidth") + this.get("_iDefaultPadding") * 2) {
                this.$("iframe").css("height", this._getWindowHeight());
                this.$("iframe").css("width", this._getWindowWidth());
                this.$(".a24-chatter-box-container").css("height", this._getWindowHeight());
                this.$(".a24-chatter-box-container").css("width", this._getWindowWidth());
                this.$(".a24-chatter-box-container").css("right", 0);
                this.$(".a24-chatter-box-container").css("bottom", 0);
            } else if (this._getWindowHeight() < this.get("_iDefaultHeight") + this.get("_iDefaultPadding") * 2) {
                this.$("iframe").css("height", this._getWindowHeight() - this.get("_iDefaultPadding") * 2);
                this.$("iframe").css("width", this.get("_iDefaultWidth"));
                this.$(".a24-chatter-box-container").css("height", this._getWindowHeight() - this.get("_iDefaultPadding") * 2);
                this.$(".a24-chatter-box-container").css("width", this.get("_iDefaultWidth"));
                this.$(".a24-chatter-box-container").css("right", this.get("_iDefaultPadding"));
                this.$(".a24-chatter-box-container").css("bottom", this.get("_iDefaultPadding"));
            } else {
                this.$("iframe").css("height", this.get("_iDefaultHeight"));
                this.$("iframe").css("width", this.get("_iDefaultWidth"));
                this.$(".a24-chatter-box-container").css("height", this.get("_iDefaultHeight"));
                this.$(".a24-chatter-box-container").css("width", this.get("_iDefaultWidth"));
                this.$(".a24-chatter-box-container").css("right", this.get("_iDefaultPadding"));
                this.$(".a24-chatter-box-container").css("bottom", this.get("_iDefaultPadding"));
            }

            //Here will will update session data since the viewport sizes changed
            Ember.run.once(this, "_postSessionDataThrottle");
        }),

        _funcPageNameObserver: Ember.observer("_objTitleBarService.sTitle", "_objBreadCrumbService.arrBreadCrumbItems.@each", "_objTitleBarService.arrTabs.@each.bActive", function () {
            Ember.run.once(this, "_setFriendlyPageName");
        }),

        _funcHideChatObserver: Ember.observer("_objChatterboxService._bHideChat", function () {
            if (this.get("_objChatterboxService._bHideChat") === false) {
                this.get("_objChatterIframeComService").postMessage(JSON.stringify({ type: "opened_chatbox" }), this.get("sChatboxUrl"), this.$("iframe")[0].contentWindow);
            } else {
                this.$("iframe").blur();
                this.get("_objChatterIframeComService").postMessage(JSON.stringify({ type: "closed_chatbox" }), this.get("sChatboxUrl"), this.$("iframe")[0].contentWindow);
            }
        }),

        _funcUrlChangeObserver: Ember.observer("_objNavigation.sCurrentUrl", function () {
            this.set("_bNewVisit", true);
            Ember.run.once(this, "_postSessionDataThrottle");
        }),

        _funcPostDataObserver: Ember.observer("_objChatterboxService._objPostData", function () {
            if (this.get("_bFrameReady") && !a24Core.isEmpty(this.get("_objChatterboxService._objPostData"))) {
                let objPostData = this.get("_objChatterboxService._objPostData");
                this.set("_objChatterboxService._objPostData", null);
                // The next here is just to make sure that when we get the app ready event from the chatterbox that we allow
                // the chatterbox to first run a useEffect if needed that will register to receive the post data.
                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        if (!a24Core.isEmpty(this)) {
                            this._postData(objPostData);
                        }
                    });
                });
            }
        }),

        actions: {
            showHide: function (objEvent) {
                if (objEvent.ctrlKey || objEvent.metaKey) {
                    this.get("_objChatterboxService").toggleChatBox();
                }
            }
        }
    });
});
define("a24-ember-staffshift-core/components/page-welcome", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/page-welcome"], function (exports, _didRenderChangesMixin, _pageWelcome) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageWelcome.default,
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objChatterBoxService: Ember.inject.service("chatter-box-service"),
        init: function () {
            this._super(...arguments);
            var objThis = this;
            this.get("_objFabButtonService").showFabButton(() => {
                objThis.get("_objChatterBoxService").toggleChatBox();
            }, "question_answer", this.get("_objStaffshiftCoreStringsService").getString("needAnyHelp"));
        }
    });
});
define("a24-ember-form-inputs/services/input-form-address-suggest-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _sGoogleMapsApiKey: Ember.computed.readOnly("_objEnv.sGoogleMapsApiKey"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _objAjax: $.ajax,
        _objRun: null,
        _bScriptBusy: false,
        _bScriptLoaded: false,
        _arrSuccessCallbacks: null,
        _arrFailCallbacks: null,

        loadScript: function (funcSuccessCallback, funcFailCallback) {
            var objThis = this;
            objThis.setAddressSuggestCallbacks(funcSuccessCallback, funcFailCallback);

            if (!this.get("_bScriptBusy") && !this.get("_bScriptLoaded")) {
                this.set("_bScriptBusy", true);
                this.set("_objPromise", objThis.get("_objAjax")({
                    // This has to be https to work since their js will only be retrieved with it
                    url: "//maps.googleapis.com/maps/api/js?key=" + this.get("_sGoogleMapsApiKey") + "&libraries=places",
                    dataType: "script",
                    cache: true
                    // We can not use this since the call does not abort when timeout is called.
                    // Since it might load twice while waiting for the timeout
                    //timeout: objThis.get("_iTimeout") * 1000
                }).done(function () {
                    objThis.executeSuccessCallbacks();
                    objThis.set("_bScriptBusy", false);
                    objThis.set("_bScriptLoaded", true);
                }).fail(function () {
                    objThis.executeFailCallbacks();
                    objThis.set("_bScriptBusy", false);
                    objThis.set("_bScriptLoaded", false);
                }));
            } else if (objThis.get("_bScriptLoaded")) {
                Ember.run.next(() => {
                    objThis.executeSuccessCallbacks();
                });
            }
        },
        setAddressSuggestCallbacks: function (funcSuccessCallback, funcFailCallback) {
            var arrSuccessCallbacks = [];
            if (!a24Core.isEmpty(this.get("_arrSuccessCallbacks"))) {
                arrSuccessCallbacks = this.get("_arrSuccessCallbacks");
            }
            arrSuccessCallbacks.push(funcSuccessCallback);

            var arrFailCallbacks = [];
            if (!a24Core.isEmpty(this.get("_arrFailCallbacks"))) {
                arrFailCallbacks = this.get("_arrFailCallbacks");
            }
            arrFailCallbacks.push(funcFailCallback);

            this.set("_arrSuccessCallbacks", arrSuccessCallbacks);
            this.set("_arrFailCallbacks", arrFailCallbacks);
        },
        executeSuccessCallbacks: function () {
            var arrSuccessCallbacks = this.get("_arrSuccessCallbacks");
            for (var i = 0; i < arrSuccessCallbacks.length; i++) {
                arrSuccessCallbacks[i]();
            }
            this.set("_arrSuccessCallbacks", []);
            this.set("_arrFailCallbacks", []);
        },
        executeFailCallbacks: function () {
            var arrFailCallbacks = this.get("_arrFailCallbacks");
            for (var i = 0; i < arrFailCallbacks.length; i++) {
                arrFailCallbacks[i]();
            }
            this.set("_arrFailCallbacks", []);
            this.set("_arrSuccessCallbacks", []);
        }
    });
});